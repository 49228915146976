import { useQuery } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { themeService } from 'myaccount/api/Theme';

const GetTheme = 'getTheme';

interface UseSellerThemeArgs {
  sellerId?: string;
}

const useSellerTheme = ({ sellerId }: UseSellerThemeArgs) => {
  const { data: theme, isLoading } = useQuery(
    [
      GetTheme,
      {
        sellerId,
      },
    ],
    async () => {
      if (!sellerId) {
        return undefined;
      }

      const responseSeller = await themeService.getSellerTheme({ sellerId });

      if ('failure' in responseSeller) {
        const responseClient = await themeService.getClientTheme();

        if ('failure' in responseClient) {
          const responseDefault = await themeService.getDefaultTheme();

          if ('failure' in responseDefault) {
            logError(responseDefault.errors);

            return undefined;
          }

          return responseDefault;
        }

        return responseClient;
      }

      return responseSeller;
    },
    {
      staleTime: Infinity,
    },
  );

  return {
    theme,
    isLoading,
  };
};

export default useSellerTheme;
