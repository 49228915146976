import React, { FunctionComponent, ReactElement, Ref } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { MessageType } from '@savgroup-front-common/types';

import { Button } from '../../atoms/button';
import { Heading } from '../../atoms/Heading';
import {
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../formatters';

import { $ConfirmFooter, $ConfirmHeader } from './ConfirmModal.styles';

interface ConfirmModalContentProps {
  isLoading: boolean;
  isDisabled: boolean;
  isDanger: boolean;
  title: ReactElement | MessageType;
  didactic: ReactElement | MessageType;
  cancelMessage: MessageType;
  confirmMessage: MessageType;
  onCancel: () => void;
  onConfirm: () => void;
  forwardedRef?: Ref<HTMLDivElement>;
}

const ConfirmModalContent: FunctionComponent<
  React.PropsWithChildren<ConfirmModalContentProps>
> = ({
  title,
  didactic,
  cancelMessage,
  confirmMessage,
  isLoading,
  onCancel,
  onConfirm,
  isDanger,
  isDisabled,
  children,
  forwardedRef,
}) => {
  return (
    <div ref={forwardedRef}>
      {title && (
        <$ConfirmHeader>
          <Heading level={4}>{SafeFormattedMessage(title)}</Heading>
        </$ConfirmHeader>
      )}
      {didactic && (
        <$ConfirmHeader>{SafeFormattedMessage(didactic)}</$ConfirmHeader>
      )}
      {children}

      <$ConfirmFooter>
        <Button
          type={BUTTON_TYPES.BUTTON}
          primary
          hollow
          dataTestId="confirmModal_no"
          onClick={() => onCancel()}
        >
          <SafeFormattedMessageWithoutSpread message={cancelMessage} />
        </Button>
        <Button
          danger={isDanger}
          primary={!isDanger}
          disabled={isDisabled}
          type={BUTTON_TYPES.BUTTON}
          dataTestId="confirmModal_yes"
          onClick={() => onConfirm()}
          isLoading={isLoading}
        >
          <SafeFormattedMessageWithoutSpread message={confirmMessage} />
        </Button>
      </$ConfirmFooter>
    </div>
  );
};

export default ConfirmModalContent;
