import React, { FC, useContext } from 'react';

import { InitContext } from '../../../../app/NewLayout/InitProvider/InitProvider.context';
import { $HomeProductsListWrapper } from '../HomePageProfessional.styles';

import HomePageFileItem from './HomePageFileItem/HomePageFileItem';

const HomePageFiles: FC = () => {
  const { openedFiles, closedFiles } = useContext(InitContext);

  return (
    <$HomeProductsListWrapper>
      {openedFiles?.map((file) => (
        <HomePageFileItem file={file} key={file.fileId} />
      ))}
      {closedFiles?.map((file) => (
        <HomePageFileItem file={file} key={file.fileId} />
      ))}
    </$HomeProductsListWrapper>
  );
};

export default HomePageFiles;
