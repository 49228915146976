import { useMemo } from 'react';

import { SERVICE_PORTAL_HOME_ROUTES } from 'myaccount/view/app/routes/Routes';

import { TABS_HOME } from '../HomePage.constants';
import HomePageFiles from '../HomePageFiles/HomePageFiles';
import HomePageProducts from '../HomePageProducts/HomePageProducts';
import messages from '../messages';

const useHomeTabs = () => {
  const tabsConfig = useMemo(() => {
    return [
      {
        name: TABS_HOME.PRODUCTS,
        path: SERVICE_PORTAL_HOME_ROUTES.PRODUCTS,
        Component: HomePageProducts,
        message: messages.myProductsLabel,
        url: SERVICE_PORTAL_HOME_ROUTES.PRODUCTS,
        icon: 'SmallShopIcon',
      },
      {
        name: TABS_HOME.REPAIRS,
        path: SERVICE_PORTAL_HOME_ROUTES.REPAIRS,
        Component: HomePageFiles,
        message: messages.myRepairsLabel,
        url: SERVICE_PORTAL_HOME_ROUTES.REPAIRS,
        icon: 'FolderIcon',
      },
    ];
  }, []);

  return {
    tabsConfig,
  };
};

export default useHomeTabs;
