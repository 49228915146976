import { darken } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import { rem } from '../../helpers/theme';

const progress = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

const inlineStyle = css`
  display: inline-block;
  line-height: 1;
`;

interface $SkeletonItemProps {
  $marginBottom?: string;
  $marginRight?: string;
  $width?: string;
  $height?: string;
  $isCircle?: boolean;
  $highContrast?: boolean;
  $isInline?: boolean;
}

export const $SkeletonItem = styled.span<$SkeletonItemProps>`
  display: block;
  width: ${({ $width }) => $width};
  max-width: 100%;
  height: ${({ $height, $isInline }) => ($isInline ? '70%' : $height)};
  border-radius: ${({ $isCircle }) => ($isCircle ? '50%' : '0.2rem')};
  margin: 0;
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  margin-right: ${({ $marginRight }) => $marginRight};
  padding: 0;

  background: ${({ $highContrast }) => {
    if ($highContrast) {
      return `linear-gradient(90deg, ${darken(0.05, '#f2f2f2')} 25%, ${darken(
        0.05,
        '#e6e6e6',
      )} 37%, ${darken(0.05, '#f2f2f2')} 63%)`;
    }

    return `linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%)`;
  }};
  background-size: 400% 100%;
  animation: ${progress} 1.4s ease infinite;
  ${({ $isInline }) => ($isInline ? inlineStyle : null)}
`;

export const $SkeletonButtonStyled = styled($SkeletonItem)<{ width?: string }>`
  height: ${rem(40)};
  min-width: ${({ width }) => width || '15rem'};
  margin-top: 0.25rem;
`;

export const $SkeletonActionRow = styled.div`
  display: flex;
  justify-content: end;
  & > * {
    margin-left: 0.5rem;
  }
`;
