import get from 'lodash/get';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { Selectors as ClaimSelectors } from '@savgroup-front-common/core/src/domains/claims';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors';

import { ROUTES } from '../../view/app/routes/Routes';

export const currentClaimGroupIdSelector = createSelector(
  [pathname],
  (path) => {
    const options = matchPath(ROUTES.CLAIM_GROUP_DETAILS, path);
    const claimGroupId = get(options, 'params.claimGroupId');

    return claimGroupId;
  },
);

export const currentGroupHandlingInfo = createSelector(
  [currentClaimGroupIdSelector, ClaimSelectors.groupHandling],
  (claimGroupId, groupHandling) => groupHandling.get(claimGroupId),
);

export const selectGroupHandlingInfo = createSelector(
  [currentGroupHandlingInfo],
  (groupHandlingInfo) =>
    groupHandlingInfo ? groupHandlingInfo.toJS()?.value : undefined,
);
