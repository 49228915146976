import React, { FC, useState } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import { ProductImage } from '@savgroup-front-common/core/src/atoms/ProductImage';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { ChevronRightIcon } from '@savgroup-front-common/core/src/protons/icons';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';

import { ResumeCard } from '../ResumeCard';

import messages from './messages';
import { useResumeCardButton } from './ResumeCardButton.hooks';
import {
  $CardContentThumbnail,
  $ResumeDescriptionProduct,
  $ResumeInfoProductSerialNumber,
  $ResumeInfoProductTag,
  $ResumeInfoProductTitle,
  $ResumeModalOpenButton,
  $ResumeModalOpenButtonContainer,
  $ResumeProduct,
  $ResumeProductImageContainer,
  $ResumeProductInfoContainer,
} from './ResumeCardButton.styles';

interface ResumeCardButtonProps {
  claimGroupConfirmation?: ClaimGroupConfirmation;
  hasPrice?: boolean;
  hasDepositPrice?: boolean;
  hasDeliveryPrice?: boolean;
}

const ResumeCardButton: FC<ResumeCardButtonProps> = ({
  claimGroupConfirmation,
  hasDeliveryPrice,
  hasDepositPrice,
  hasPrice,
}) => {
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const { modelDetail, serialNumber } = useResumeCardButton({
    claimGroupConfirmation,
  });
  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  return (
    <>
      <$ResumeDescriptionProduct>
        <$ResumeProduct>
          <$ResumeProductImageContainer>
            <$CardContentThumbnail>
              <ProductImage pictureUrl={modelDetail?.pictureUrl} />
            </$CardContentThumbnail>
          </$ResumeProductImageContainer>
          <$ResumeProductInfoContainer>
            <$ResumeInfoProductTitle>
              {modelDetail?.name}
            </$ResumeInfoProductTitle>
            {serialNumber && (
              <$ResumeInfoProductSerialNumber>
                <SafeFormattedMessageWithoutSpread
                  message={messages.serialNumber}
                  values={{
                    serialNumber,
                  }}
                />
              </$ResumeInfoProductSerialNumber>
            )}
            <$ResumeInfoProductTag />
          </$ResumeProductInfoContainer>
        </$ResumeProduct>
        <$ResumeModalOpenButtonContainer>
          <$ResumeModalOpenButton
            type={BUTTON_TYPES.BUTTON}
            onClick={handleOpenModal}
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.resumeButton}
            />
            <ChevronRightIcon size="15px" />
          </$ResumeModalOpenButton>
        </$ResumeModalOpenButtonContainer>
      </$ResumeDescriptionProduct>

      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard
          claimGroupConfirmation={claimGroupConfirmation}
          hasPrice={hasPrice}
          hasDepositPrice={hasDepositPrice}
          hasDeliveryPrice={hasDeliveryPrice}
        />
      </Modal>
    </>
  );
};

export default ResumeCardButton;
