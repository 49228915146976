import { StrichSDK } from '@pixelverse/strichjs-sdk';
import React, { FC, useEffect, useState } from 'react';

import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';

import { IrshStepValues } from '../IrshPages.types';

import { CartManager } from './CartManager';

const ProductsToReturnPage: FC<RoutedStepProps<IrshStepValues>> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const [isStrichLoading, setIsStrichLoading] = useState(true);

  useEffect(() => {
    StrichSDK.initialize(import.meta.env.VITE_STRICH_LICENCE_KEY).then(() => {
      logVerbose('STRICH SDK initialized');
      setIsStrichLoading(false);
    });
  }, []);

  if (isStrichLoading) {
    return <BaseLoader />;
  }

  return (
    <CartManager onNextStep={onNextStep} onPreviousStep={onPreviousStep} />
  );
};

export default React.memo(ProductsToReturnPage);
