import { Reducer, useCallback, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  initializeRoutedStepsOrchestrator,
  RoutedStepsOrchestratorAction,
  routedStepsOrchestratorReducer,
  RoutedStepsOrchestratorState,
  StepsOrchestratorInitArgs,
} from '../RoutedStepsOrchestrator.reducer';
import {
  STEPS_ORCHESTRATOR_ACTION_TYPES as ACTION_TYPES,
  OnSubmit,
  RoutedStep,
  RoutedStepsOrchestratorConfig,
  STEPS_ORCHESTRATOR_ACTION_TYPES,
} from '../RoutedStepsOrchestrator.types';

interface UseStepsOrchestratorArgs<Values, Context> {
  config: RoutedStepsOrchestratorConfig<Values, Context>;
  onSubmit: OnSubmit<Values, Context>;
  initialContext: Partial<Context>;
  initialValues: Partial<Values>;
  baseRoute: string;
  onPrevious?: (values?: Partial<Values>, context?: Partial<Context>) => void;
}

const useRoutedStepsOrchestrator = <
  Values = Record<string, unknown>,
  Context = Record<string, unknown>,
>({
  config,
  onSubmit,
  initialContext,
  initialValues,
  baseRoute,
  onPrevious,
}: UseStepsOrchestratorArgs<Values, Context>) => {
  const navigate = useNavigate();

  const [state, dispatch] = useReducer<
    Reducer<
      RoutedStepsOrchestratorState<Values, Context>,
      RoutedStepsOrchestratorAction<Values, Context>
    >,
    StepsOrchestratorInitArgs<Values, Context>
  >(
    routedStepsOrchestratorReducer,
    {
      config,
      initialContext,
      initialValues,
    },
    initializeRoutedStepsOrchestrator,
  );

  const { context, values } = state;

  const handleNextStep = useCallback(
    ({
      newValues,
      newContext,
      step,
    }: {
      newValues: Partial<Values>;
      newContext: Partial<Context>;
      step: RoutedStep<Values, Context>;
    }) => {
      if (!step.onNextStep) {
        onSubmit({
          values: { ...values, ...(newValues ?? {}) } as Values,
          context: { ...context, ...(newContext ?? {}) } as Context,
        });

        return;
      }

      const newStepRoute = step.onNextStep({
        values: { ...values, ...(newValues ?? {}) },
        context: { ...context, ...(newContext ?? {}) },
      });

      dispatch({
        type: STEPS_ORCHESTRATOR_ACTION_TYPES.NEXT_STEP,
        payload: {
          context: newContext,
          values: newValues,
        },
      });

      if (newStepRoute) {
        navigate(newStepRoute);
      }

      return undefined;
    },
    [context, navigate, onSubmit, values],
  );

  const handlePreviousStep = useCallback(
    ({
      newValues,
      newContext,
      step,
    }: {
      newValues: Partial<Values>;
      newContext: Partial<Context>;
      step: RoutedStep<Values, Context>;
    }) => {
      if (!step.onPreviousStep) {
        if (onPrevious) {
          onPrevious(
            { ...values, ...newValues },
            { ...context, ...newContext },
          );
        }

        dispatch({
          type: ACTION_TYPES.RESET,
          payload: {
            initialContext: initialContext,
            initialValues: initialValues,
          },
        });

        return undefined;
      }

      const newStepRoute = step.onPreviousStep({
        values: { ...values, ...(newValues ?? {}) },
        context: { ...context, ...(newContext ?? {}) },
      });

      dispatch({
        type: STEPS_ORCHESTRATOR_ACTION_TYPES.PREVIOUS_STEP,
        payload: {
          context: newContext,
          values: newValues,
        },
      });

      if (newStepRoute) {
        navigate(`${baseRoute ?? '/'}${newStepRoute}`.replace('//', '/'));
      }

      return undefined;
    },
    [
      baseRoute,
      context,
      navigate,
      initialContext,
      initialValues,
      onPrevious,
      values,
    ],
  );

  const handleContextUpdate = useCallback(
    ({ newContext }: { newContext: Partial<Context> }) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_CONTEXT,
        payload: { newContext },
      });
    },
    [],
  );
  const handleValuesUpdate = useCallback(
    ({ newValues }: { newValues: Partial<Values> }) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_VALUES,
        payload: { newValues },
      });
    },
    [],
  );

  const handleValuesReset = useCallback(
    ({
      initialContext,
      initialValues,
    }: {
      initialContext: Partial<Context>;
      initialValues: Partial<Values>;
    }) => {
      dispatch({
        type: ACTION_TYPES.RESET,
        payload: {
          initialContext: initialContext,
          initialValues: initialValues,
        },
      });
    },
    [],
  );

  return {
    state,
    handlePreviousStep,
    handleNextStep,
    handleContextUpdate,
    handleValuesUpdate,
    handleValuesReset,
  };
};

export default useRoutedStepsOrchestrator;
