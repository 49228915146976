import React, { FunctionComponent, ReactElement } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../../formatters';
import { FieldMessage } from '../../common';
import { getFinalFieldState } from '../../common/helpers/getFinalFieldState';
import { FieldMessages } from '../../common/helpers/getFinalFieldState.types';
import { Label } from '../../common/Label/Label';

import { $RadioButtonGroup } from './RadioButtonGroup.styles';

interface RadioGroupProps {
  name: string;
  label?: string | MessageType;
  postLabel?: string | ReactElement;
  isRequired?: boolean;
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isError?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  dataTestId?: string;
}

const RadioButtonGroup: FunctionComponent<
  React.PropsWithChildren<RadioGroupProps>
> = ({
  name,
  label,
  postLabel,
  isRequired,
  errors = {},
  warnings = {},
  successes = {},
  isError = false,
  isSuccess = false,
  isWarning = false,
  children,
  dataTestId,
}) => {
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  return (
    <$RadioButtonGroup>
      {label && (
        <Label
          htmlFor={name}
          isRequired={isRequired}
          status={status}
          postLabel={postLabel}
        >
          {SafeFormattedMessage(label)}
        </Label>
      )}
      {children}
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
    </$RadioButtonGroup>
  );
};

export default RadioButtonGroup;
