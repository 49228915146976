import React, { FC, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MessageDescriptor } from 'react-intl';
import { useTheme } from 'styled-components';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatAmountWithCurrency } from '@savgroup-front-common/core/src/formatters/intl';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { MessageType } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import { RadioCardWithCheckboxColumn } from '../RadioCardWithCheckboxColumn';

import {
  $Item,
  $ItemContentTextWrapper,
  $ItemContentWrapper,
  $ItemDescription,
  $ItemDistance,
  $ItemHighlighting,
  $ItemHighlightingIcon,
  $ItemLogo,
  $ItemPrice,
  $ItemTitle,
} from './ChooseItemCard.styles';
import { CHOOSE_ITEM_CARD_TYPE } from './ChooseItemCard.type';
import ChooseItemCardAddress from './ChooseItemCardAddress';
import messages from './messages';

interface ChooseItemCardProps {
  name: string;
  value: string;
  handleOnChange?: (...value: any) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  title?: string | number | boolean | MessageType | MessageDescriptor;
  highlighting?: string | number | boolean | MessageType | MessageDescriptor;
  description?: string | number | boolean | MessageType | MessageDescriptor;
  price?: number;
  icon?: ICONS_TYPE;
  logo?: ReactElement;
  distance?: string | number | boolean | MessageType | MessageDescriptor;
  showAddress?: boolean;
  actionButton?: ReactElement;
  hasDisplayFreeAmount?: boolean;
  chooseItemCardType: CHOOSE_ITEM_CARD_TYPE;
  dataTestId?: string;
}

const ChooseItemCard: FC<React.PropsWithChildren<ChooseItemCardProps>> = ({
  name,
  value,
  handleOnChange = () => undefined,
  onClick = () => undefined,
  title,
  highlighting,
  description,
  distance,
  price,
  icon = undefined,
  logo,
  showAddress = false,
  hasDisplayFreeAmount = true,
  chooseItemCardType,
  children,
  dataTestId,
}) => {
  const theme = useTheme();
  const formContext = useFormContext();
  const { control, getValues } = formContext;
  const address = getValues('addressSelected');

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <$Item>
          <RadioCardWithCheckboxColumn
            dataTestId={dataTestId}
            name={field.name}
            image={
              icon ? (
                <MyAccountIcon
                  icon={icon}
                  color={
                    field.value === value ? theme.colors.primary : '#8A94A6'
                  }
                />
              ) : undefined
            }
            imageHovered={
              icon && <MyAccountIcon icon={icon} color={theme.colors.primary} />
            }
            title=""
            value={value}
            onClick={onClick}
            onChange={(e) => {
              if (!showAddress) {
                handleOnChange(e.target.value);
              }

              return field.onChange(e.target.value);
            }}
            isChecked={field.value === value}
            subLine={
              showAddress ? (
                <ChooseItemCardAddress
                  address={address}
                  value={value}
                  handleOnChange={handleOnChange}
                />
              ) : undefined
            }
            isHidden
          >
            <$ItemContentWrapper>
              <$ItemContentTextWrapper>
                {title && (
                  <$ItemTitle>
                    <SafeFormattedMessageWithoutSpread message={title} />
                  </$ItemTitle>
                )}
                {highlighting && (
                  <$ItemHighlighting>
                    <$ItemHighlightingIcon>
                      <MyAccountIcon
                        icon={ICONS_TYPE.BASELINE_ECO}
                        color={theme.colors.success}
                      />
                    </$ItemHighlightingIcon>
                    <SafeFormattedMessageWithoutSpread message={highlighting} />
                  </$ItemHighlighting>
                )}
                {description && (
                  <$ItemDescription>
                    <SafeFormattedMessageWithoutSpread message={description} />
                  </$ItemDescription>
                )}
                {chooseItemCardType ===
                  CHOOSE_ITEM_CARD_TYPE.PICKUP_POINT_CARD &&
                  !!price && (
                    <$ItemPrice $hasPrice $hasPickupPointType>
                      {formatAmountWithCurrency(
                        price,
                        CURRENCIES.EUR,
                      )?.toString()}
                    </$ItemPrice>
                  )}
                {distance && (
                  <$ItemDistance>
                    <SafeFormattedMessageWithoutSpread message={distance} />
                  </$ItemDistance>
                )}
                {children}
              </$ItemContentTextWrapper>
              {chooseItemCardType === CHOOSE_ITEM_CARD_TYPE.HANDLING_CARD &&
                !!price && (
                  <$ItemPrice $hasPrice $hasPickupPointType={false}>
                    <SafeFormattedMessageWithoutSpread
                      message={messages.startingFrom}
                      values={{
                        price: formatAmountWithCurrency(
                          price,
                          CURRENCIES.EUR,
                        )?.toString(),
                      }}
                    />
                  </$ItemPrice>
                )}
              {chooseItemCardType === CHOOSE_ITEM_CARD_TYPE.NORMAL_CARD &&
                !!price && (
                  <$ItemPrice $hasPrice $hasPickupPointType={false}>
                    {formatAmountWithCurrency(
                      price,
                      CURRENCIES.EUR,
                    )?.toString()}
                  </$ItemPrice>
                )}
              {!price && hasDisplayFreeAmount && (
                <$ItemPrice $hasPrice={false} $hasPickupPointType={false}>
                  <SafeFormattedMessageWithoutSpread message={messages.free} />
                </$ItemPrice>
              )}
              {logo && <$ItemLogo>{logo}</$ItemLogo>}
            </$ItemContentWrapper>
          </RadioCardWithCheckboxColumn>
        </$Item>
      )}
    />
  );
};

export default ChooseItemCard;
