import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { RadioCardWithCheckboxColumn } from 'myaccount/view/components/RadioCardWithCheckboxColumn';

import { StartOrContinueDiagnosticTreeQueryReturnValues } from '../../../../../../api/Claim/startOrContinueDiagnosticTreeQuery';
import { ClaimGroupDiagnosticTreeValue } from '../../NewClaimGroupDiagnosticTreePageContent.types';

import { $Question, $RadioButtonWrapper } from './StepAnswers.styles';

interface StepAnswersProps {
  currentDiagnosticTreeStep: StartOrContinueDiagnosticTreeQueryReturnValues;
  onSubmit: (value: ClaimGroupDiagnosticTreeValue) => void;
}

const StepAnswers: React.FC<StepAnswersProps> = ({
  currentDiagnosticTreeStep,
  onSubmit,
}) => {
  const formContext = useFormContext();

  const { control } = formContext;

  return (
    <FormGroup>
      <$Question>{currentDiagnosticTreeStep?.currentNode?.question}</$Question>
      {currentDiagnosticTreeStep?.currentNode?.responses?.map((response) => {
        return (
          <React.Fragment key={response.uniqueName}>
            <Controller
              control={control}
              name="response"
              render={({ field }) => {
                return (
                  <$RadioButtonWrapper>
                    <RadioCardWithCheckboxColumn
                      title={response.text}
                      name={response.text}
                      value={response.uniqueName}
                      isChecked={
                        field?.value?.uniqueName === response?.uniqueName
                      }
                      onChange={() => field.onChange(response)}
                      onClick={() => onSubmit({ response })}
                      isHidden
                    />
                  </$RadioButtonWrapper>
                );
              }}
            />
          </React.Fragment>
        );
      })}
    </FormGroup>
  );
};

export default StepAnswers;
