import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { ClaimService } from '../../../../api';

export const USE_GET_CLAIMS_GROUP_SUMMARY = 'USE_GET_CLAIMS_GROUP_SUMMARY';

const useGetClaimGroupSummary = ({
  claimGroupId,
  suspense,
}: {
  claimGroupId?: string;
  suspense?: boolean;
}) => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: claims, isLoading } = useQuery(
    [USE_GET_CLAIMS_GROUP_SUMMARY, { claimGroupId }],
    async () => {
      removeAllNotifications();

      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getClaimContextsByGroupIdQuery({
        claimGroupId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      suspense,
    },
  );

  return {
    claims,
    claimsIsLoading: isLoading,
  };
};

export default useGetClaimGroupSummary;
