import styled from 'styled-components';

export const $PageWrapper = styled('div')`
  overflow: hidden;
  position: relative;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
`;
