import React from 'react';
import { useTheme } from 'styled-components';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';

import { StartOrContinueDiagnosticTreeQueryReturnValues } from '../../../../../../api/Claim/startOrContinueDiagnosticTreeQuery';
import messages from '../../messages';

import { $ColContainer, $StepNumber } from './StepNumber.styles';

interface StepNumberProps {
  currentDiagnosticTreeStep: StartOrContinueDiagnosticTreeQueryReturnValues;
}

const StepNumber: React.FC<StepNumberProps> = ({
  currentDiagnosticTreeStep,
}) => {
  const theme = useTheme();

  return (
    <$ColContainer sm={3}>
      {currentDiagnosticTreeStep.history.map((historyItem, index) => {
        return (
          <$StepNumber key={historyItem?.node.uniqueName}>
            <SafeFormattedMessageWithoutSpread
              message={messages.stepNumber}
              values={{
                value: index + 1,
              }}
            />
            <MyAccountIcon
              icon={ICONS_TYPE.CHECK_CIRCLE_OUTLINE_ICON}
              color={theme.colors.success}
            />
          </$StepNumber>
        );
      })}

      <$StepNumber>
        <SafeFormattedMessageWithoutSpread
          message={messages.stepNumber}
          values={{ value: currentDiagnosticTreeStep.history.length + 1 }}
        />
      </$StepNumber>
    </$ColContainer>
  );
};

export default StepNumber;
