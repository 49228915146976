import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import { DefaultStyledProps } from '@savgroup-front-common/types';

import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import { rem } from '../../helpers';
import {
  getBordersBasedOnProps,
  getInteractionsBasedOnProps,
  getTextColorBasedOnProps,
} from '../../theme/helpers';

import { colorBasedOnProps, colorHoverBasedOnProps } from './Checkbox.helpers';

interface CheckboxProps extends DefaultStyledProps {
  danger: boolean;
  isIndeterminate?: boolean;
  disabled: boolean;
  $hasDescription?: boolean;
  $isNewUiEnabled?: boolean;
  $withoutMargin?: boolean;
  $isHidden?: boolean;
  $withoutPaddingLeft?: boolean;
}

export const $StyledCheckbox = styled.span<
  CheckboxProps & { $isFlexAlign?: boolean }
>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};
  position: absolute;
  top: ${({ $hasDescription }) => ($hasDescription ? '10px' : 0)};
  left: 0;
  width: ${rem(20)};
  height: ${rem(20)};
  border-radius: ${(props) => getBordersBasedOnProps(props).radius};
  border-width: 1px;
  border-color: ${colorBasedOnProps};
  border-style: solid;
  transform-origin: 50% 50%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition-property: background-color, border;
  transition-duration: ${(props) =>
    getInteractionsBasedOnProps(props).basicTiming};

  ${decelerateTimingFunctionTransition};

  ${({ $isFlexAlign }) =>
    $isFlexAlign
      ? css`
          position: relative;
          margin-right: 1rem;
          top: unset;
        `
      : null}
`;
export const $CheckContainer = styled.span`
  visibility: hidden;
  transition-duration: ${(props) =>
    getInteractionsBasedOnProps(props).basicTiming};
  ${decelerateTimingFunctionTransition};
  transform: scale(0);

  & svg {
    color: ${({ theme }) => theme.systemColors.defaultTextColor};
  }
`;
export const $MinusContainer = styled.span`
  visibility: hidden;
`;

export const $CheckboxInput = styled.input<CheckboxProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: ${rem(20)};
  height: ${rem(20)};
  opacity: 0;
  cursor: pointer;

  display: ${({ $isHidden }) => ($isHidden ? 'none' : undefined)};

  &:hover,
  &:focus {
    + ${$StyledCheckbox} {
      border-color: ${(props) => lighten(0.07, colorHoverBasedOnProps(props))};
    }
  }

  &:disabled {
    cursor: not-allowed;
    + ${$StyledCheckbox} {
      background-color: ${({ theme }) => theme.colors.disabled};
      border-color: ${({ theme }) => theme.colors.disabled};
    }
  }

  &:active {
    + ${$StyledCheckbox} {
      transform: scale(
        ${(props) => getInteractionsBasedOnProps(props).activeScale}
      );
    }
  }

  &:checked {
    + ${$StyledCheckbox} {
      border-color: ${({ theme }) => theme.colors.default};
    }
    + ${$StyledCheckbox} ${$CheckContainer} {
      visibility: visible;

      transform: scale(1);
    }
  }
  &:not(:checked):disabled + ${$StyledCheckbox} ${$MinusContainer} {
    visibility: visible;
  }

  & {
    + ${$StyledCheckbox} {
      background-color: ${({ isIndeterminate, ...rest }) =>
        isIndeterminate ? colorHoverBasedOnProps(rest) : null};
      border-color: ${({ isIndeterminate, ...rest }) =>
        isIndeterminate ? colorHoverBasedOnProps(rest) : null};
    }

    + ${$StyledCheckbox} ${$CheckContainer} {
      visibility: ${({ isIndeterminate }) =>
        isIndeterminate ? 'visible' : null};

      transform: ${({ isIndeterminate }) =>
        isIndeterminate ? 'scale(1)' : null};
    }
  }
`;

const container = css<CheckboxProps>`
  min-height: ${rem(20)};
  position: relative;
  line-height: ${rem(20)};
  color: ${getTextColorBasedOnProps};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

interface CheckboxLabelProps extends CheckboxProps {
  $checked?: boolean;
  $shouldHighlightWhenChecked: boolean;
}

export const $CheckboxLabel = styled.label<
  CheckboxLabelProps & { $isFlexAlign?: boolean }
>`
  ${container};
  display: block;
  padding-left: ${({ $isFlexAlign }) => ($isFlexAlign ? null : rem(30))};
  font-weight: ${({ theme, $checked, $shouldHighlightWhenChecked }) =>
    $shouldHighlightWhenChecked && $checked
      ? theme.fonts.weight.mediumBold
      : null};

  ${({ $isFlexAlign }) =>
    $isFlexAlign
      ? css`
          display: flex;
          align-items: center;
          position: relative;
        `
      : null}
`;

export const $CheckboxContainer = styled.div<CheckboxProps>`
  ${container};
  padding-left: ${({ $withoutPaddingLeft }) =>
    $withoutPaddingLeft ? null : rem(20)};
  margin: ${({ $withoutMargin }) => ($withoutMargin ? null : `${rem(12)} 0`)};
`;

export const $CheckboxDescription = styled.div<{ $isChecked?: boolean }>`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
`;
