import { Portals } from '../../types';

import { useGetClientInformation, useGetSellerConfiguration } from './hooks';

const useInitApp = () => {
  const { clientInformation, isLoading: isClientInformationLoading } =
    useGetClientInformation();

  const sellerId = clientInformation?.sellerId || clientInformation?.tenantId;

  const { sellerConfiguration, isLoading: isGetSellerConfiguration } =
    useGetSellerConfiguration({
      sellerId,
    });

  const isLoading = isClientInformationLoading || isGetSellerConfiguration;

  return {
    isLoading,
    isNewDesign: sellerConfiguration?.isSelfcareNewDesign || false,
    isBtoBPortal: clientInformation?.enablePortals?.includes(Portals.BTOB),
    isAllowNewFiles: sellerConfiguration?.allowNewFiles,
  };
};

export default useInitApp;
