import React, { PropsWithChildren, Suspense } from 'react';
import { matchPath, Route, Routes, useLocation } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { logDebug } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { BaseLoader } from '../BaseLoader';

import { EmptyWrapper } from './EmptyWrapper';
import useRoutedStepsOrchestrator from './hooks/useRoutedStepsOrchestrator';
import StepOrchestratorProvider from './RoutedStepsOrchestrator.context';
import {
  $Grid,
  $StepsOrchestratorContainer,
} from './RoutedStepsOrchestrator.styles';
import {
  OnSubmit,
  RoutedStepsOrchestratorConfig,
} from './RoutedStepsOrchestrator.types';

interface StepsOrchestratorProps<Values, Context> {
  initialContext?: Partial<Context>;
  config: RoutedStepsOrchestratorConfig<Values, Context>;
  onSubmit: OnSubmit<Values, Context>;
  initialValues?: Partial<Values>;
  valuesFromParent?: Record<string, unknown>;
  parentPadding?: number;
  baseRoute?: string;
  onPrevious?: (values?: Partial<Values>, context?: Partial<Context>) => void;
}

export const RoutedStepsOrchestrator = <
  Values,
  Context = Record<string, unknown>,
>({
  initialContext = {} as Partial<Context>,
  initialValues = {} as Partial<Values>,
  config,
  onSubmit,
  valuesFromParent = {},
  baseRoute = '/',
  onPrevious = () => undefined,
}: PropsWithChildren<StepsOrchestratorProps<Values, Context>>) => {
  const {
    handlePreviousStep,
    handleNextStep,
    state,
    handleContextUpdate,
    handleValuesUpdate,
    handleValuesReset,
  } = useRoutedStepsOrchestrator<Values, Context>({
    config,
    onSubmit,
    initialContext,
    initialValues,
    baseRoute,
    onPrevious,
  });
  const location = useLocation();

  const isCurrentRouteInnerRoutes = config.some((step) =>
    matchPath(`${baseRoute}/${step.route}`, location.pathname),
  );

  useDeepCompareEffect(() => {
    if (!isCurrentRouteInnerRoutes) {
      logDebug(
        'Getting outside RoutedStepsOrchestrator - Resetting values',
        location.pathname,
      );

      handleValuesReset({
        initialContext,
        initialValues,
      });
    }
  }, [
    location.pathname,
    handleValuesReset,
    isCurrentRouteInnerRoutes,
    initialContext,
    initialValues,
  ]);

  logDebug('RoutedStepsOrchestrator values:', state.values);

  const { context, values } = state;

  return (
    <StepOrchestratorProvider<Values, Context>
      values={{
        config,
        context: context as Context,
        values: values as Values,
        valuesFromParent,
        onContextUpdate: handleContextUpdate,
        onValuesUpdate: handleValuesUpdate,
        onValuesReset: () =>
          handleValuesReset({
            initialContext,
            initialValues,
          }),
      }}
    >
      <Routes>
        {config.map((step) => {
          const Component = step.Component;
          const Wrappper = step.Wrapper ?? EmptyWrapper;

          return (
            <>
              <Route
                key={step.route}
                path={step.route}
                element={
                  <$Grid>
                    <Suspense fallback={<BaseLoader />}>
                      <$StepsOrchestratorContainer>
                        <Wrappper>
                          <Component
                            onNextStep={({
                              newValues = {},
                              newContext = {},
                            }) => {
                              handleNextStep({ newValues, newContext, step });
                            }}
                            onPreviousStep={({
                              newValues = {},
                              newContext = {},
                            }) => {
                              handlePreviousStep({
                                newValues,
                                newContext,
                                step,
                              });
                            }}
                          />
                        </Wrappper>
                      </$StepsOrchestratorContainer>
                    </Suspense>
                  </$Grid>
                }
              />
            </>
          );
        })}
      </Routes>
    </StepOrchestratorProvider>
  );
};
