import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTitle } from 'react-use';

import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { AppErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { AuthConfiguration } from 'myaccount/configuration';

import FileDetailPage from '../../newDesignPages/FileFollowupsPage/FileFollowupsPage';
import HomePageProfessional from '../../pages/professionalPages/HomePage/HomePageProfessional';
import { IrshPages } from '../../pages/professionalPages/irshPages/IrshPages';
import { ProfessionalPagesProvider } from '../../pages/professionalPages/ProfessionalPages.context';
import DebugLayout from '../DebugLayout';
import { PROFESSIONAL_FINAL_ROUTES } from '../routes/ProfessionalRoutes';

import Header from './header/Header';
import messages from './messages';
import { $PageWrapper } from './NewLayout.styles';

interface ProfessionalProps {
  sellerId: string;
}

const ProfessionalLayout: React.FC<ProfessionalProps> = ({ sellerId }) => {
  useTitle(safeFormattedIntlString(messages.b2BPortalTitle));

  const clientId = AuthConfiguration.clientId;

  return (
    <ProfessionalPagesProvider>
      <AppErrorBoundary>
        <$PageWrapper>
          <NeedHelp clientId={clientId} />
          <Header sellerId={sellerId} />

          <Routes>
            <Route path="*" element={<Navigate to={'/professional'} />} />

            <Route path="/professional" element={<DebugLayout />}>
              <Route
                index
                element={
                  <Navigate to={PROFESSIONAL_FINAL_ROUTES.HOME_REPAIRS} />
                }
              />

              <Route path="home">
                <Route
                  index
                  element={
                    <Navigate to={PROFESSIONAL_FINAL_ROUTES.HOME_REPAIRS} />
                  }
                />
                <Route path="*" element={<HomePageProfessional />} />
              </Route>

              <Route path="*" element={<IrshPages />} />

              <Route
                path={PROFESSIONAL_FINAL_ROUTES.FILE_FOLLOWUPS}
                element={<FileDetailPage />}
              />
            </Route>
          </Routes>
        </$PageWrapper>
      </AppErrorBoundary>
    </ProfessionalPagesProvider>
  );
};

export default ProfessionalLayout;
