import React, { FC, useCallback, useContext, useState } from 'react';

import { COUNTRY_CODES } from '@savgroup-front-common/constants';
import { CARRIERS, Timeslot } from '@savgroup-front-common/types';

import AppointmentCalendar from '../../components/AppointmentCalendar/AppointmentCalendar';
import { useGetHomePickupSchedule } from '../../hooks';
import useGetFileHandling from '../../hooks/useGetFileHandling';
import { BannersProviderContext } from '../../molecules/NotificationsProvider';
import { BaseLoader } from '../BaseLoader';

import ConfirmCancelModal from './ConfirmCancelModal';
import { useCalculateStartAndEndDate } from './hooks/useCalculateStartAndEndDate';
import useCreateHomePickupCommand from './hooks/useCreateHomePickup';

interface AppointmentCardContentProps {
  fileId: string;
  carrier: CARRIERS;
  countryCode: COUNTRY_CODES;
  onPreviousStepClick?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
}

export const AppointmentCardContent: FC<AppointmentCardContentProps> = ({
  fileId,
  carrier,
  countryCode,
  onPreviousStepClick,
  onCancel,
  onSubmit,
}) => {
  const { removeAllNotifications } = useContext(BannersProviderContext);
  const [pageNumber, setPageNumber] = useState(1);

  const { startTime, endTime } = useCalculateStartAndEndDate({ pageNumber });

  const { fileHandling } = useGetFileHandling({ fileId });

  const { detailedSchedule: schedules } = useGetHomePickupSchedule({
    carrier,
    startTimeInLocalRecipientTimezone: startTime,
    endTimeInLocalRecipientTimezone: endTime,
    countryCode,
    postalCode: fileHandling?.deposit?.address.postalCode || '',
    fileId,
  });

  const { handleCreateHomePickup, isLoading: isSubmitLoading } =
    useCreateHomePickupCommand({
      fileId,
      onSuccess: onSubmit,
    });

  const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState(false);

  const setConfirmModalOpen = (isOpen: boolean) => {
    setIsConfirmCancelOpen(isOpen);
  };

  const handleCloseModal = useCallback(() => setConfirmModalOpen(false), []);

  if (!schedules) {
    return <BaseLoader />;
  }

  return (
    <>
      <AppointmentCalendar
        schedules={schedules}
        isSubmitLoading={isSubmitLoading}
        onValidateSchedule={async ({
          selectedTimeslot,
        }: {
          selectedTimeslot?: Timeslot | undefined;
        }): Promise<void> => {
          if (selectedTimeslot) {
            handleCreateHomePickup({
              appointmentTime: {
                allDaySchedule: selectedTimeslot?.allDaySchedule,
                endLocalTime: selectedTimeslot?.end,
                startLocalTime: selectedTimeslot?.start,
                carrier,
                countryCode,
                date: selectedTimeslot?.start,
                internalId: selectedTimeslot?.internalId,
              },
            });
          }
        }}
        startTime={startTime}
        onPageNextClick={() => {
          removeAllNotifications();
          setPageNumber(pageNumber + 1);
        }}
        onPagePreviousClick={() => {
          removeAllNotifications();
          setPageNumber(pageNumber - 1);
        }}
        isFirstPage={pageNumber === 1}
        onPreviousStepClick={onPreviousStepClick}
      />

      {onCancel && (
        <ConfirmCancelModal
          isConfirmCancelOpen={isConfirmCancelOpen}
          onCloseModal={handleCloseModal}
          onSubmit={onCancel}
        />
      )}
    </>
  );
};

AppointmentCardContent.displayName = 'AppointmentCardContent';
