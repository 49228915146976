import React from 'react';
import { FormProvider } from 'react-hook-form';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { AdditionalInformation } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';

import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { IrshStepValues } from '../IrshPages.types';
import messages from '../NewClaimGroupProductInfoPage/messages';

import useClaimGroupDocumentRelatedToProduct from './NewClaimGroupDocumentPageRelatedToProduct.hooks';
import ProductHeader from './ProductHeader/ProductHeader';

export const NewClaimGroupDocumentPageRelatedToProduct: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onNextStep, onPreviousStep }) => {
  const {
    claims,
    products,
    neededInformationDocumentOnlyRelatedToProduct,
    onSubmit,
    formContext,
    isLoadingSubmit,
  } = useClaimGroupDocumentRelatedToProduct({
    onNextStep,
  });

  const {
    formState: { isValid },
  } = formContext;

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <NewStepLayout
          stepName={STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT}
        >
          {claims?.map((claim) => {
            const neededInformationOnlyRelatedToProductFiltered =
              neededInformationDocumentOnlyRelatedToProduct?.filter(
                (item) =>
                  item.currentClaimId === claim.claimId &&
                  item.claimIds.includes(claim.claimId),
              );

            return (
              <div key={claim.claimId}>
                {products && products[claim.ownerProductId] && (
                  <ProductHeader product={products[claim.ownerProductId]} />
                )}
                <AdditionalInformation
                  neededInformation={
                    neededInformationOnlyRelatedToProductFiltered
                  }
                  formContext={formContext}
                  prefixFormName={`${claim.claimId}.reasonAdditionalInformationDocumentRelatedToProduct`}
                  ignoreUniquenessByProduct
                  fullSizeItem
                  isScrollIntoView
                  isNewDesign
                  shouldAllowQrCode={false}
                />
              </div>
            );
          })}
        </NewStepLayout>

        <PageFooterContainer>
          <Button
            type={BUTTON_TYPES.BUTTON}
            secondary
            naked
            onClick={() => {
              onPreviousStep({});
            }}
            icon={<ArrowNakedLeftIcon />}
            position={SUPPORTED_ICON_POSITIONS.LEFT}
            dataTestId="previousButton"
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.previousButton}
            />
          </Button>

          <Button
            primary
            type={BUTTON_TYPES.BUTTON}
            disabled={isLoadingSubmit || !isValid}
            onClick={onSubmit}
            position={SUPPORTED_ICON_POSITIONS.RIGHT}
            icon={<ArrowNakedRightIcon />}
            dataTestId="nextButton"
          >
            <SafeFormattedMessageWithoutSpread message={messages.nextButton} />
          </Button>
        </PageFooterContainer>
      </form>
    </FormProvider>
  );
};
