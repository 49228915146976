import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { CURRENCIES, REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { ClaimService } from 'myaccount/api';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import { useGetSolutionsByClaim } from 'myaccount/view/app/hooks/useGetSolutionsByClaim';

import { IrshStepValues } from '../IrshPages.types';

import claimGroupSolutionSchema from './ClaimGroupSolutionPage.schema';
import { ClaimGroupSolutionValues } from './ClaimGroupSolutionPage.types';

const useClaimGroupSolutionPage = ({
  onNextStep,
}: {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}) => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();
  const { pushErrors, removeAllNotifications } = useToasts();

  const { solutions } = useGetSolutionsByClaim({
    claimGroupId,
  });

  const { claimGroupConfirmation } = useGetClaimGroupConfirmation({
    claimGroupId,
  });

  const formContext = useForm<ClaimGroupSolutionValues>({
    resolver: yupResolver(claimGroupSolutionSchema),
    defaultValues: {
      chooseSolution: claimGroupConfirmation?.solutionTypeId,
    },
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const onSubmit = async ({ chooseSolution }: { chooseSolution: string }) => {
    const solutionValue = solutions?.find(
      (solution) => solution.solutionTypeId === chooseSolution,
    );

    if (solutionValue && claimGroupId) {
      try {
        removeAllNotifications();

        const responseSetSolution =
          await ClaimService.setClaimGroupSolutionCommand({
            claimGroupId,
            solutionTypeId: solutionValue?.solutionTypeId,
            solutionPrice: solutionValue?.price
              ? {
                  amount: solutionValue.price,
                  currency: solutionValue?.priceCurrencyCode as CURRENCIES,
                }
              : undefined,
          });

        if (responseSetSolution.failure) {
          pushErrors(responseSetSolution?.errors);

          return undefined;
        }

        const responseHandling = await ClaimService.getHandlingByClaimGroup({
          claimGroupId,
        });

        if (responseHandling.failure) {
          pushErrors(responseHandling?.errors as ErrorFromBack[]);

          return undefined;
        }

        onNextStep({
          newValues: {
            handling: responseHandling.value,
          },
        });

        return undefined;
      } catch (err: any) {
        pushErrors(err);

        return undefined;
      }
    }

    return undefined;
  };

  return {
    onSubmit,
    formContext,
  };
};

export default useClaimGroupSolutionPage;
