import React, { FC } from 'react';
import { Controller, FormProvider } from 'react-hook-form';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  Autocomplete,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import { NewStepLayoutProfessional } from 'myaccount/view/app/NewLayout/NewStepLayout/NewStepLayoutProfessional';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';

import { PageFooterContainer } from '../../../../../../app/NewLayout/NewStepLayout';

import messages from './messages';
import useClaimGroupReason from './ReasonPage.hooks';
import { IssuesReasonsPageForm } from './ReasonPage.types';

const ReasonPage: FC = () => {
  const { reasons, onSubmit, formContext, isLoading, isSubmitLoading } =
    useClaimGroupReason();

  const {
    control,
    formState: { errors, isValid },
  } = formContext;

  const options = reasons.map((reason) => ({
    label: reason.name,
    value: reason.id,
    data: reason,
  }));

  const { previousStep } = useStepOrchestratorContext<IssuesReasonsPageForm>();

  if (isLoading) {
    return <BaseLoader />;
  }

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <NewStepLayoutProfessional
          stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE}
          customSteps={PROFESSIONAL_STEP_PROGRESS}
          minHeight
        >
          <FormGroup dataTestId="dropdown-select-reason">
            <Controller
              control={control}
              name="reason"
              render={({ field }) => (
                <Autocomplete
                  name={field.name}
                  options={options}
                  isRequired
                  ref={field.ref}
                  onChange={field.onChange}
                  value={field.value}
                  errors={errors}
                  label={safeFormattedIntlString(messages.selectReason)}
                  shouldAutoSelectFirstOptions={options.length === 1}
                />
              )}
            />
          </FormGroup>
        </NewStepLayoutProfessional>

        <PageFooterContainer>
          <Button
            type={BUTTON_TYPES.BUTTON}
            secondary
            naked
            onClick={() => {
              previousStep();
            }}
            icon={<ArrowNakedLeftIcon />}
            position={SUPPORTED_ICON_POSITIONS.LEFT}
            dataTestId="previousButton"
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.previousButton}
            />
          </Button>

          <Button
            primary
            type={BUTTON_TYPES.BUTTON}
            icon={<ArrowNakedRightIcon />}
            position={SUPPORTED_ICON_POSITIONS.RIGHT}
            disabled={!isValid}
            isLoading={isSubmitLoading}
            onClick={onSubmit}
            dataTestId="nextButton"
          >
            <SafeFormattedMessageWithoutSpread message={messages.nextButton} />
          </Button>
        </PageFooterContainer>
      </form>
    </FormProvider>
  );
};

export default ReasonPage;
