import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { delay } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { ClaimService } from 'myaccount/api';

import { StartOrContinueDiagnosticTreeQueryReturnValues } from '../../../../api/Claim/startOrContinueDiagnosticTreeQuery';
import { FINAL_ROUTES } from '../../../app';
import { InitContext } from '../../../app/NewLayout/InitProvider/InitProvider.context';
import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { useInitializeClaimGroupId } from '../hooks/useInitializeClaimGroupId';
import { IrshStepValues } from '../IrshPages.types';

import { GET_START_OR_CONTINUE_DIAGNOSTIC_TREE } from './NewClaimGroupDiagnosticTreePage.hooks';
import { ClaimGroupDiagnosticTreeSchema } from './NewClaimGroupDiagnosticTreePageContent.schema';
import { ClaimGroupDiagnosticTreeValue } from './NewClaimGroupDiagnosticTreePageContent.types';

const HANDLE_DIAGNOSTIC_RESPONSE_COMMAND_QUERY =
  'handleDiagnosticResponseCommandQuery';
const HANDLE_RESTART_DIAGNOSTIC_RESPONSE_COMMAND_QUERY =
  'handleRestartDiagnosticResponseCommandQuery';

interface UseClaimGroupDiagnosticTreePageArgs {
  diagnosticTreeUniqueName: string;
  onPreviousStep: RoutedStepProps<IrshStepValues>['onPreviousStep'];
  startOrContinueDiagnosticTreeQueryReturnValues: StartOrContinueDiagnosticTreeQueryReturnValues;
}

const useClaimGroupDiagnosticTreePage = ({
  diagnosticTreeUniqueName,
  onPreviousStep,
  startOrContinueDiagnosticTreeQueryReturnValues,
}: UseClaimGroupDiagnosticTreePageArgs) => {
  const { enableServicePortal } = useContext(InitContext);

  const { removeAllNotifications, pushErrors } = useToasts();
  const navigate = useNavigate();

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();

  const claimGroupId = values?.claimGroupId;

  useInitializeClaimGroupId();

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const claimId = claims?.at(0)?.claimId || '';

  const formContext = useForm<ClaimGroupDiagnosticTreeValue>({
    resolver: yupResolver(ClaimGroupDiagnosticTreeSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit, reset } = formContext;

  const queryClient = useQueryClient();

  const {
    mutateAsync: handleUpdateDiagnosticTree,
    isLoading: ishandleDiagnosticResponseCommandLoading,
  } = useMutation(
    [HANDLE_DIAGNOSTIC_RESPONSE_COMMAND_QUERY],
    async ({
      claimId,
      treeUniqueName,
      nodeUniqueName,
      responseUniqueName,
      force = false,
    }: {
      claimId: string;
      treeUniqueName: string;
      nodeUniqueName: string;
      responseUniqueName: string;
      force?: boolean;
    }) => {
      if (!responseUniqueName) {
        return undefined;
      }

      removeAllNotifications();

      const response = await ClaimService.handleDiagnosticResponseCommandQuery({
        claimId,
        treeUniqueName,
        nodeUniqueName,
        responseUniqueName,
        force,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      onSuccess: (response) => {
        if (response) {
          queryClient.setQueriesData(
            [
              GET_START_OR_CONTINUE_DIAGNOSTIC_TREE,
              { claimId, diagnosticTreeUniqueName },
            ],
            response,
          );
        }
      },
    },
  );

  const { mutateAsync: handleRestartDiagnosticTree } = useMutation(
    [HANDLE_RESTART_DIAGNOSTIC_RESPONSE_COMMAND_QUERY],
    async () => {
      removeAllNotifications();

      const response = await ClaimService.startOrContinueDiagnosticTreeQuery({
        claimId,
        treeUniqueName: diagnosticTreeUniqueName,
        forceRestart: true,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      onSuccess: (response) => {
        if (response) {
          queryClient.setQueriesData(
            [
              GET_START_OR_CONTINUE_DIAGNOSTIC_TREE,
              { claimId, diagnosticTreeUniqueName },
            ],
            response,
          );
        }
      },
    },
  );

  const handlePreviousStep = async () => {
    const historyLength =
      startOrContinueDiagnosticTreeQueryReturnValues.history.length;

    if (historyLength === 0) {
      if (claimGroupId) {
        onPreviousStep({
          newValues: {
            diagnosticTree: {
              isDiagnosticIsAlreadyMade: false,
            },
          },
        });
      }

      return undefined;
    } else if (historyLength === 1) {
      await handleRestartDiagnosticTree();
    } else {
      await handleUpdateDiagnosticTree({
        claimId,
        treeUniqueName: diagnosticTreeUniqueName,
        nodeUniqueName:
          startOrContinueDiagnosticTreeQueryReturnValues.history[
            startOrContinueDiagnosticTreeQueryReturnValues.history.length - 2
          ].node.uniqueName,
        responseUniqueName:
          startOrContinueDiagnosticTreeQueryReturnValues.history[
            startOrContinueDiagnosticTreeQueryReturnValues.history.length - 2
          ].selectedResponseUniqueName,
        force: true,
      });

      await queryClient.invalidateQueries([
        GET_START_OR_CONTINUE_DIAGNOSTIC_TREE,
        { claimId, diagnosticTreeUniqueName },
      ]);
    }

    reset();

    navigate(
      generatePath(FINAL_ROUTES.CLAIM_GROUP_DIAGNOSTIC_TREE, {
        claimGroupId,
        questionId: diagnosticTreeUniqueName,
        step: `step${
          startOrContinueDiagnosticTreeQueryReturnValues.history.length - 1
        }`,
      }),
    );

    return undefined;
  };

  const handleSubmitResponse = handleSubmit(async ({ response }) => {
    if (response?.uniqueName) {
      await handleUpdateDiagnosticTree({
        claimId,
        treeUniqueName: diagnosticTreeUniqueName,
        nodeUniqueName:
          startOrContinueDiagnosticTreeQueryReturnValues?.currentNode
            ?.uniqueName || '',
        responseUniqueName: response?.uniqueName,
      });

      await delay(250);

      reset();

      navigate(
        generatePath(FINAL_ROUTES.CLAIM_GROUP_DIAGNOSTIC_TREE, {
          claimGroupId,
          questionId: diagnosticTreeUniqueName,
          step: `step${
            startOrContinueDiagnosticTreeQueryReturnValues.history.length + 1
          }`,
        }),
      );

      return undefined;
    }

    return undefined;
  });

  return {
    isLoading: ishandleDiagnosticResponseCommandLoading,
    formContext,
    handleSubmitResponse,
    handlePreviousStep,
    enableServicePortal,
  };
};

export default useClaimGroupDiagnosticTreePage;
