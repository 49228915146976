import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { MyAccountRootState } from 'myaccount/domains/MyAccountRootState';

import { ownerIdSelector } from '../../../domains/Customer/selectors';
import { useGetClientInformation } from '../hooks';
import { ROUTES } from '../routes/Routes';

import useUpdateFavicon from './hooks/useUpdateFavicon';

export const useLayout = () => {
  const location = useLocation();
  const { clientInformation, isLoading: isClientInformationLoading } =
    useGetClientInformation();
  const sellerId = clientInformation?.sellerId || clientInformation?.tenantId;

  const ownerId = useSelector(ownerIdSelector);
  const { login } = useSelector((state: MyAccountRootState) => {
    const { login } = state;

    return { login };
  });

  const match = matchPath(ROUTES.OLD_FILE_DETAILS, location.pathname);
  const fileId = match?.params?.fileId;

  useUpdateFavicon({
    sellerId,
  });

  return {
    fileId,
    sellerId,
    ownerId,
    login,
    bannerUrl: clientInformation?.bannerUrl,
    enableServicePortal: clientInformation?.enableServicePortal,
    isLoading: isClientInformationLoading,
  };
};
