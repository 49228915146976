import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

import {
  $IconContainer,
  $NotificationContainer,
  $TextContainer,
} from '../../../atoms/Banners/Banner.styles';

export const $AppointmentCalendarScreen = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
`;

export const $UnderlinedRow = styled.div<{ $columnCount: number }>`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.default}`};
  padding: 10px 0;
  margin-bottom: 1rem;
  position: relative;

  display: grid;
  grid-template-columns: repeat(${({ $columnCount }) => $columnCount}, 1fr);
  gap: 1rem;
`;

export const $TimeSlotSelectorContainer = styled.div<{ $columnCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $columnCount }) => $columnCount}, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const $EmptyDay = styled.div`
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;

export const $BannersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  margin-top: 1rem;

  ${$NotificationContainer} {
    width: 80%;
  }

  @media ${media.maxWidth.xs} {
    ${$NotificationContainer} {
      width: 100%;
      ${$IconContainer} {
        display: none;
      }
      ${$TextContainer} {
        margin-left: 1rem;
      }
    }
  }
`;
