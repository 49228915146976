import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMedia } from 'react-use';

import { media, PERMISSIONS } from '@savgroup-front-common/constants';
import {
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import { PanelBodyLine } from '../../../../atoms/Panel/PanelBodyLine/PanelBodyLine';
import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { formatReverseMoneyToString } from '../../../../formatters/intl';
import { getRecipientTypeMessage } from '../../../../helpers/i18n';
import { useHasPermission } from '../../../../hooks';
import { $AmountCell } from '../../QuotationLines';
import { useQuotationPendingCustomerValidationContext } from '../QuotationPendingCustomerValidation.context';
import { QuotationPendingCustomerValidationForm } from '../QuotationPendingCustomerValidation.types';

import { ConditionSelection } from './ConditionSelection';
import { OptionalLineSelection } from './OptionalLineSelection';
import { SparePartQuotationPendingCustomerValidationLineMobileView } from './SparePartQuotationPendingCustomerValidationLineMobileView';

interface SparePartQuotationPendingCustomerValidationLineProps {
  sparePartQuotationLine: SparePartQuotationLine;
  onSelectSparePartQuotationLineChange: ({
    values,
    alternativeSpareParts,
  }: {
    values: QuotationPendingCustomerValidationForm;
    alternativeSpareParts: Record<
      string,
      Record<string, SparePartSearchSummary>
    >;
  }) => Promise<{ failure: boolean }>;
  shouldShowRecipientColumn?: boolean;
  index: number;
  preDataTestId?: string;
}

const SparePartQuotationPendingCustomerValidationLine: FC<
  SparePartQuotationPendingCustomerValidationLineProps
> = ({
  sparePartQuotationLine,
  onSelectSparePartQuotationLineChange,
  shouldShowRecipientColumn = true,
  index,
  preDataTestId = 'sparePart',
}) => {
  const { alternativeSpareParts } =
    useQuotationPendingCustomerValidationContext();
  const isMobileView = useMedia(media.maxWidth.sm);

  const formContext = useFormContext<QuotationPendingCustomerValidationForm>();
  const { watch } = formContext;

  const sparePartQuotationFormLine =
    watch('sparePartLines')?.[sparePartQuotationLine.id];

  const concernedAlternativeSpareParts = Object.values(
    alternativeSpareParts[sparePartQuotationLine.sparePartId] || {},
  );

  const sparePart = concernedAlternativeSpareParts.find((part) => {
    return (
      part.sparePartId === sparePartQuotationLine.sparePartId &&
      part.sparePartSupplierId ===
        sparePartQuotationFormLine?.sparePartSupplierId &&
      part.condition === sparePartQuotationFormLine?.condition
    );
  });

  const isSelected = watch(
    `sparePartLines.${sparePartQuotationLine.id}.isSelectedForRepair`,
  );
  const hasInteractWithQuotationTabPermission = useHasPermission(
    PERMISSIONS.INTERACT_WITH_QUOTATION_TAB,
  );

  const hasHideQuotationDisplaySparePartsReferencePermission = useHasPermission(
    PERMISSIONS.HIDE_QUOTATION_DISPLAY_SPARE_PARTS_REFERENCE,
  );

  if (isMobileView) {
    return (
      <SparePartQuotationPendingCustomerValidationLineMobileView
        sparePartQuotationLine={sparePartQuotationLine}
        isSelected={isSelected}
        sparePart={sparePart}
        shouldShowRecipientColumn={shouldShowRecipientColumn}
        onSelectSparePartQuotationLineChange={
          onSelectSparePartQuotationLineChange
        }
        preDataTestId={preDataTestId}
        hasHideQuotationDisplaySparePartsReferencePermission={
          hasHideQuotationDisplaySparePartsReferencePermission
        }
        hasInteractWithQuotationTabPermission={
          hasInteractWithQuotationTabPermission
        }
        index={index}
      />
    );
  }

  return (
    <PanelBodyLine
      key={sparePartQuotationLine.id}
      isSelected={isSelected}
      isActive
      gridTemplateColumns={
        shouldShowRecipientColumn ? '4fr 4fr 2fr 2fr' : '6fr 4fr 2fr'
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        {!sparePartQuotationLine.isRequiredForRepair && (
          <OptionalLineSelection
            sparePartQuotationLine={sparePartQuotationLine}
            onOptionalLineSelectionChange={onSelectSparePartQuotationLineChange}
            preDataTestId={preDataTestId}
            index={index}
          />
        )}
        <span
          data-testid={`${preDataTestId}_subCategory_supplierReference_${index}`}
        >
          {hasHideQuotationDisplaySparePartsReferencePermission
            ? sparePartQuotationLine.manufacturerLabel
            : [
                sparePartQuotationLine.supplierReference,
                sparePartQuotationLine.manufacturerLabel,
              ]
                .filter((x) => !!x)
                .join(' - ')}
        </span>
      </div>
      <div>
        <ConditionSelection
          sparePartQuotationLine={sparePartQuotationLine}
          onConditionChange={onSelectSparePartQuotationLineChange}
          dataTestId={`${preDataTestId}_condition_${index}`}
        />
      </div>
      {shouldShowRecipientColumn && (
        <div data-testid={`${preDataTestId}_recipient_${index}`}>
          {sparePartQuotationLine.recipient?.data && (
            <SafeFormattedMessageWithoutSpread
              message={getRecipientTypeMessage(
                sparePartQuotationLine.recipient?.data,
              )}
            />
          )}
        </div>
      )}
      {sparePart && (
        <$AmountCell
          data-testid={`${preDataTestId}_recommendedSalePriceIncludingTaxes_${index}`}
        >
          {formatReverseMoneyToString(
            sparePart.recommendedSalePriceIncludingTaxes,
          )}
        </$AmountCell>
      )}
    </PanelBodyLine>
  );
};

SparePartQuotationPendingCustomerValidationLine.displayName =
  'SparePartQuotationPendingCustomerValidationLine';

export default SparePartQuotationPendingCustomerValidationLine;
