import { yupResolver } from '@hookform/resolvers/yup';
import head from 'lodash/head';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { CommonWorkflowService } from '@savgroup-front-common/core/src/api';
import AdditionalInformationSchema from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm/AdditionalInformation.schema';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { CustomerFile } from '../../../../../../domains/Customer/Customer.types';
import { customerState } from '../../../../../../domains/Customer/selectors';
import { useMyAccountTypedSelector } from '../../../../../../hooks';
import { getTransitionWithExpect } from '../ClientAction.helpers';

import { adaptFileProducts } from './adapters/adaptFileProducts';
import { AdaptedNeededInformation } from './AdditionalInformationExpect.types';
import { prepareSetUserActionAsyncCommandAdditionalInformations } from './AdditionalInformationExpectContent.adapters';
import { groupProductsBy } from './helpers/groupProductsBy';

interface UseAdditionalInformationExpectContentArgs {
  additionalInformations: Record<string, AdaptedNeededInformation[]>;
}

export const useAdditionalInformationExpectContent = ({
  additionalInformations,
}: UseAdditionalInformationExpectContentArgs) => {
  const { removeAllNotifications, pushErrors } = useBanners();
  const customer = useMyAccountTypedSelector(customerState);

  const file = customer?.file as CustomerFile;
  const singleProduct = file.fileProducts.length === 1;

  const adaptedFileProducts = adaptFileProducts({
    additionalInformations,
    fileProducts: file.fileProducts,
  });

  const groupedProducts = groupProductsBy({ fieldName: 'sellerProductId' })(
    adaptedFileProducts,
  );

  const formContext = useForm({
    resolver: yupResolver(
      AdditionalInformationSchema({
        neededInformationList: additionalInformations,
      }),
    ),
  });

  const { fileId } = useParams() as { fileId: string };
  const firstAction = head(customer?.availableFileActions);
  const module = firstAction?.module;
  const fileStatusName = firstAction?.fileStatus?.name;

  const { firstTransitionWithExpect = undefined } = firstAction
    ? getTransitionWithExpect([firstAction])
    : {};

  const { handleSubmit } = formContext;

  const headProduct = head(adaptedFileProducts);

  const handleAdditionalInformationNeededSubmit = handleSubmit(
    async (fileAdditionalInformation) => {
      removeAllNotifications();

      if (!firstTransitionWithExpect || !fileStatusName || !module) {
        return undefined;
      }

      const response = await CommonWorkflowService.setUserActionAsyncCommand({
        fileId,
        additionalFileInformation:
          prepareSetUserActionAsyncCommandAdditionalInformations({
            fileAdditionalInformation,
            neededFileAdditionalInformation: additionalInformations,
          }),
        fromState: fileStatusName,
        module,
        toState: firstTransitionWithExpect.targetState.name,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return undefined;
    },
  );

  return {
    formContext,
    handleAdditionalInformationNeededSubmit,
    singleProduct,
    headProduct,
    groupedProducts,
  };
};
