import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { OwnerService } from 'myaccount/api';
import { ownerIdSelector } from 'myaccount/domains/Customer/selectors';
import useGetClientInformation from 'myaccount/view/app/hooks/useGetClientInformation';

import useGetReasons from './hooks/useGetReasons';
import claimGroupReasonSchema from './ReasonPage.schema';
import {
  ClaimGroupReasonValues,
  IssuesReasonsPageForm,
} from './ReasonPage.types';

const useClaimGroupReason = () => {
  const { orderReference } = useParams<{ orderReference?: string }>();
  const {
    values: { selectedIssue, order },
    nextStep,
  } = useStepOrchestratorContext<IssuesReasonsPageForm>();

  const { reasons, isLoading } = useGetReasons({
    issueId: selectedIssue?.id,
  });

  const formContext = useForm<ClaimGroupReasonValues>({
    resolver: yupResolver(claimGroupReasonSchema()),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      reason: order?.reasonId
        ? { value: order.reasonId, label: '' }
        : undefined,
    },
  });

  const { handleSubmit } = formContext;

  const ownerId = useSelector(ownerIdSelector);
  const { clientInformation } = useGetClientInformation();

  const { removeAllNotifications, pushErrors } = useToasts();

  const { mutateAsync: handleStartOrder, isLoading: isSubmitLoading } =
    useMutation(
      async ({ selectedReason, selectedIssue }: IssuesReasonsPageForm) => {
        removeAllNotifications();

        if (!clientInformation?.sellerId) {
          return undefined;
        }

        const response =
          await OwnerService.startOrContinueBusinessReturnCommand({
            issueId: selectedIssue.id,
            reasonId: selectedReason.id,
            ownerId,
            sellerId: clientInformation?.sellerId,
            orderReference,
          });

        if (response.failure) {
          pushErrors(response.errors);

          return response;
        }

        return response;
      },
    );

  const onSubmit = handleSubmit(async ({ reason }) => {
    const response = await handleStartOrder({
      selectedReason: reason.data,
      selectedIssue,
    });

    if (response?.failure === false) {
      nextStep({ selectedReason: reason.data, order: response.value.order });
    }
  });

  return {
    reasons: reasons || [],
    isLoading,
    isSubmitLoading,
    formContext,
    onSubmit,
  };
};

export default useClaimGroupReason;
