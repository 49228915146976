import styled from 'styled-components';

import { rem } from '../../helpers';

interface $AccordionContainerProps {
  $naked: boolean;
  $isList?: boolean;
  $withoutMargin?: boolean;
}

export const $AccordionContainer = styled.div<$AccordionContainerProps>`
  margin-bottom: ${({ $isList, $withoutMargin }) =>
    $isList || $withoutMargin ? 'unset' : '1.25rem'};
  background-color: ${({ theme, $naked }) => {
    return $naked ? null : theme.systemColors.defaultBackground;
  }});
    
  border-radius: ${({ $isList }) => ($isList ? '0' : rem(5))};
`;
