export enum PROFESSIONAL_STEP_CONFIG {
  PROFESSIONAL_ISSUES_REASONS_PAGE = 'ProfessionalIssuesReasonsPage',
  PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE = 'ProfessionalProductsToReturnPage',
  PROFESSIONAL_DEPOSIT_PAGE = 'ProfessionalDepositPage',
  PROFESSIONAL_DELIVERY_PAGE = 'ProfessionalDeliveryPage',
  PROFESSIONAL_SOLUTION_PAGE = 'ProfessionalSolutionPage',
  PROFESSIONAL_CONFIRMATION_PAGE = 'ProfessionalConfirmationPage',
  PROFESSIONAL_FILE_FOLLOWUPS_PAGE = 'ProfessionalFileFollowups',
}

export enum PROFESSIONAL_HOME_CONFIG {
  PROFESSIONAL_HOME_PAGE = 'HomePageProfessional',
}

export const PROFESSIONAL_STEP_PROGRESS: Record<string, string[]>[] = [
  {
    issue: [
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE.toString(),
    ],
  },
  {
    scanProducts: [
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE.toString(),
    ],
  },
  {
    summary: [
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE.toString(),
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE.toString(),
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE.toString(),
      PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE.toString(),
    ],
  },
];
