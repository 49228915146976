import React from 'react';
import { useParams } from 'react-router-dom';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import { useGetSolutionsByClaim } from 'myaccount/view/app/hooks/useGetSolutionsByClaim';

import { IrshStepValues } from '../IrshPages.types';

import { ClaimGroupSolution } from './ClaimGroupSolution/ClaimGroupSolution';

const ClaimGroupSolutionPage: React.FC<RoutedStepProps<IrshStepValues>> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const { solutions } = useGetSolutionsByClaim({
    claimGroupId,
  });

  const { isLoading } = useGetClaimGroupConfirmation({
    claimGroupId,
  });

  if (!solutions || isLoading) {
    return <BaseLoader />;
  }

  return (
    <ClaimGroupSolution
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
    />
  );
};

export default ClaimGroupSolutionPage;
