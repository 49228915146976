import { Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $StepsOrchestratorContainer = styled.div`
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const $Grid = styled(Grid)`
  @media ${media.maxWidth.xs} {
    width: 100%;
  }
`;
