import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import AddressForm from './addressForm/AddressForm';
import useGetCountriesOptions from './hooks/useGetCountriesOptions/useGetCountriesOptions';

interface AddressManagementProps {
  onSubmit: () => void;
}

const AddressManagement: FC<AddressManagementProps> = ({ onSubmit }) => {
  const intl = useIntl();
  const countryOptions = useGetCountriesOptions(intl.locale);

  return <AddressForm countriesOptions={countryOptions} onSubmit={onSubmit} />;
};

export default AddressManagement;
