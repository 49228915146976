import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { FLEX_ALIGN } from '@savgroup-front-common/types';
import {
  IconDiagnosticTreeFinalStepNotRepared,
  IconDiagnosticTreeFinalStepRepared,
  IconDiagnosticTreeTools,
} from 'myaccount/view/components/IconDiagnosticTreeFinalStep';

import { StartOrContinueDiagnosticTreeQueryReturnValues } from '../../../../../../api/Claim/startOrContinueDiagnosticTreeQuery';
import { FINAL_ROUTES } from '../../../../../app';
import { IrshStepValues } from '../../../IrshPages.types';
import messages from '../../messages';
import { DIAGNOSTIC_STATUS } from '../../NewClaimGroupDiagnosticTreePageContent.types';

import {
  $ButtonOtherDemand,
  $RowBannerContainer,
  $RowBannerContainerButton,
  $RowBannerContainerIcon,
  $RowBannerContainerText,
  $RowBannerContent,
  $RowContainer,
  $TextContainer,
} from './StepFinal.styles';

interface StepFinalProps {
  currentDiagnosticTreeStep: StartOrContinueDiagnosticTreeQueryReturnValues;
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const StepFinal: React.FC<StepFinalProps> = ({
  currentDiagnosticTreeStep,
  onNextStep,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { onValuesUpdate } =
    useRoutedStepsOrchestratorContext<IrshStepValues>();

  return (
    <>
      <$RowContainer center="xs">
        {currentDiagnosticTreeStep?.currentNode?.status ===
          DIAGNOSTIC_STATUS.REPAIRED && (
          <>
            <IconDiagnosticTreeFinalStepRepared />
            <Heading
              level={3}
              align={FLEX_ALIGN.CENTER}
              color={theme.colors.black}
            >
              {currentDiagnosticTreeStep?.currentNode.title && (
                <SafeFormattedMessageWithoutSpread
                  message={currentDiagnosticTreeStep?.currentNode.title}
                />
              )}
            </Heading>
            {currentDiagnosticTreeStep?.currentNode.instructions && (
              <SafeFormattedMessageWithoutSpread
                message={currentDiagnosticTreeStep?.currentNode.instructions}
              />
            )}

            <$ButtonOtherDemand>
              <Button
                type={BUTTON_TYPES.BUTTON}
                primary
                onClick={() => {
                  onValuesUpdate({
                    newValues: {
                      diagnosticTree: {
                        hasRefusedDiagnosticTree: false,
                        isDiagnosticIsAlreadyMade: false,
                        diagnosticTreeUniqueName: undefined,
                      },
                      claimGroupId: undefined,
                      claims: undefined,
                      reason: undefined,
                    },
                  });

                  navigate(generatePath(FINAL_ROUTES.ISSUES));
                }}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.stepFinalRepairedAnotherDemand}
                />
              </Button>
            </$ButtonOtherDemand>
          </>
        )}

        {currentDiagnosticTreeStep?.currentNode?.status ===
          DIAGNOSTIC_STATUS.NOT_REPAIRED && (
          <>
            <IconDiagnosticTreeFinalStepNotRepared />

            <$TextContainer>
              <Heading
                level={3}
                align={FLEX_ALIGN.CENTER}
                color={theme.colors.black}
              >
                {currentDiagnosticTreeStep?.currentNode.title && (
                  <SafeFormattedMessageWithoutSpread
                    message={currentDiagnosticTreeStep?.currentNode.title}
                  />
                )}
              </Heading>
              {currentDiagnosticTreeStep?.currentNode.instructions && (
                <SafeFormattedMessageWithoutSpread
                  message={currentDiagnosticTreeStep?.currentNode.instructions}
                />
              )}
            </$TextContainer>

            <$RowBannerContainer>
              <$RowBannerContent>
                <$RowBannerContainerIcon>
                  <IconDiagnosticTreeTools />
                </$RowBannerContainerIcon>
                <$RowBannerContainerText>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.stepFinalRepairedBannerTitle}
                  />
                  <SafeFormattedMessageWithoutSpread
                    message={messages.stepFinalRepairedBannerSubtitle}
                  />
                </$RowBannerContainerText>
              </$RowBannerContent>

              <$RowBannerContainerButton>
                <Button
                  type={BUTTON_TYPES.BUTTON}
                  primary
                  onClick={() => {
                    onNextStep({
                      newValues: {
                        diagnosticTree: {
                          isDiagnosticIsAlreadyMade: true,
                        },
                      },
                    });
                  }}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.stepFinalRepairedBannerButton}
                  />
                </Button>
              </$RowBannerContainerButton>
            </$RowBannerContainer>
          </>
        )}
      </$RowContainer>
    </>
  );
};

export default StepFinal;
