import { head } from 'lodash';
import { useParams } from 'react-router-dom';

import { TARGET_ON_TYPE_VALUES } from '@savgroup-front-common/core/src/api/Workflow/getFileAdditionalInformationQuery';

import { customerState } from '../../../../../../domains/Customer/selectors';
import { useMyAccountTypedSelector } from '../../../../../../hooks';
import { getTransitionWithExpect } from '../ClientAction.helpers';

import { useGetNeededAdditionalInformations } from './hooks/useGetNeededAdditionalInformations';

export const useAdditionalInformationExpect = () => {
  const { fileId } = useParams() as { fileId: string };
  const customer = useMyAccountTypedSelector(customerState);
  const firstAction = head(customer?.availableFileActions);
  const module = firstAction?.module;
  const fileStatusName = firstAction?.fileStatus?.name;

  const { firstTransitionWithExpect = undefined } = firstAction
    ? getTransitionWithExpect([firstAction])
    : {};

  const { additionalInformations } = useGetNeededAdditionalInformations({
    fileId,
    filterStateName: fileStatusName,
    filterModuleDefinitionId: module?.definitionId,
    targetOnType: TARGET_ON_TYPE_VALUES.Transition,
    filterToStateName: firstTransitionWithExpect?.targetState?.name,
  });

  return { additionalInformations };
};
