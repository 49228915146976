import { darken, rgba } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import { rem } from '../../helpers';
import { Tooltip } from '../Tooltip';

const transition = css`
  transition-duration: 500ms;
  ${decelerateTimingFunctionTransition};
`;

export const $WizardIndex = styled.span`
  min-width: ${rem(14)};
  display: inline-block;
  text-align: center;

  ${transition};
`;
export const $WizardList = styled.ul`
  padding-left: 0;
  display: flex;
  border-radius: ${({ theme }) => theme.borders.radius};
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    right: 0;
    width: 100%;
    height: 4px;
    background: ${({ theme }) => darken(0.08, theme.colors.lightGrey)};
  }
`;

interface GetColorArgs {
  $isActive: boolean;
  $isSucceeded: boolean;
  theme: DefaultTheme;
}

const getGreyBackground = ({ theme }: { theme: DefaultTheme }) => {
  return darken(0.08, theme.colors.lightGrey);
};

const getBackground = ({
  theme,
  $isActive,
  $isSucceeded,
}: GetColorArgs): string => {
  if ($isSucceeded) {
    return theme.colors.success;
  }
  if ($isActive) {
    return theme.colors.primary;
  }

  return getGreyBackground({ theme });
};

const getTextColor = ({
  theme,
  $isActive,
  $isSucceeded,
}: GetColorArgs): string => {
  if ($isActive || $isSucceeded) {
    return theme.colors.white;
  }

  return theme.colors.mainTextColor;
};

export const $WizardTooltip = styled(Tooltip)`
  display: block !important;
  width: 100%;
  height: ${rem(30)};
  line-height: ${rem(30)};
  min-width: ${rem(30)};
  padding: 0 ${rem(8)};
  position: relative;
  ${transition}
`;

interface $HidingPartProps {
  $isActive: boolean;
  $isCompact: boolean;
  $isSucceeded: boolean;
}

export const $WizardItemList = styled.li<$HidingPartProps>`
  list-style: none;
  ${transition};

  flex: ${({ $isActive }) => ($isActive ? 1 : 0)};
  color: ${getTextColor};
  &:not(:last-child) > div::after,
  &:not(:first-child) > div::before {
    content: '';
  }
  & ${$WizardIndex} {
    opacity: ${({ $isSucceeded }) => ($isSucceeded ? 0 : 1)};
  }
  & svg {
    ${transition};
    position: absolute;
    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
    opacity: ${({ $isSucceeded }) => ($isSucceeded ? 1 : 0)};
  }
  > ${$WizardTooltip} {
    background-color: ${getBackground};
    border-radius: ${({ theme, $isCompact, $isActive }) =>
      $isCompact && !$isActive ? rem(30 / 2) : theme.borders.radius};

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
      height: 4px;
      ${transition};
    }
    &::before {
      left: -1rem;

      background: linear-gradient(
        to right,
        ${(props) => rgba(getBackground(props), 0)},
        ${(props) => rgba(getBackground(props), 1)}
      );
    }
    &::after {
      right: -1rem;
      background: linear-gradient(to right, ${getBackground}, ${getBackground});
    }
  }

  &:not(:first-child) {
    padding-left: 0.5rem;
  }
  &:not(:last-child) {
    padding-right: 0.5rem;
  }
  position: relative;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};

  cursor: ${({ $isCompact, $isActive }) =>
    $isCompact && !$isActive ? 'help' : null};
`;

export const $WizardHidingPart = styled.span<$HidingPartProps>`
  transition-property: opacity;
  opacity: ${({ $isActive, $isCompact }) => (!$isActive && $isCompact ? 0 : 1)};
  position: ${({ $isCompact }) => ($isCompact ? 'absolute' : null)};
  margin-left: ${rem(2)};
  ${transition};
`;
