import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

export const GetClaimGroupConfirmation = 'getClaimGroupConfirmation';

export const useGetClaimGroupConfirmation = ({
  claimGroupId,
  suspense,
}: {
  claimGroupId?: string;
  suspense?: boolean;
}) => {
  const { data: claimGroupConfirmation, isLoading } = useQuery(
    [GetClaimGroupConfirmation, { claimGroupId }],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getClaimGroupConfirmationQuery({
        claimGroupId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      suspense,
      keepPreviousData: true,
    },
  );

  return {
    claimGroupConfirmation,
    isLoading,
  };
};
