import React, { FC, Ref } from 'react';

import { CURRENCIES, INPUT_TYPES } from '@savgroup-front-common/constants';

import { formatCurrencyCode } from '../../../formatters';
import Input, { InputProps } from '../Input';

interface PriceInputProps extends Omit<InputProps, 'onChange' | 'onBlur'> {
  currency?: CURRENCIES;
  onChange?: (newValue?: number) => void;
  onBlur?: (newValue?: number) => void;
  forwardedRef: Ref<HTMLInputElement>;
  min?: number;
  max?: number;
}

const MAX = 2147483647;

const PriceInput: FC<PriceInputProps> = ({
  label,
  currency = CURRENCIES.EUR,
  onChange = () => undefined,
  onBlur = () => undefined,
  forwardedRef,
  min = 0,
  max = Infinity,
  ...rest
}) => {
  return (
    <Input
      {...rest}
      step="0.01"
      label={label}
      type={INPUT_TYPES.NUMBER}
      ref={forwardedRef}
      suffix={formatCurrencyCode(currency)}
      disableBrowserAutocomplete
      onChange={(event) => {
        const newValue = parseFloat(event.target.value);

        if (min && newValue < min) {
          return;
        }
        if (max && newValue > max) {
          return;
        }

        if (!Number.isNaN(newValue) && newValue <= MAX) {
          onChange(newValue);
        } else if (Number.isNaN(newValue) && newValue <= MAX) {
          onChange(undefined);
        }
      }}
      onBlur={(event) => {
        const newValue = parseFloat(event.target.value);

        if (!Number.isNaN(newValue) && newValue <= MAX) {
          onChange(newValue);
          onBlur(newValue);
        } else if (Number.isNaN(newValue) && newValue <= MAX) {
          onChange(undefined);
          onBlur(undefined);
        }
      }}
    />
  );
};

PriceInput.displayName = 'PriceInput';

export default React.forwardRef<
  HTMLInputElement,
  Omit<PriceInputProps, 'forwardedRef'>
>((props, ref) => <PriceInput forwardedRef={ref} {...props} />);
