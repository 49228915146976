import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { ownerIdSelector } from 'myaccount/domains/Customer/selectors';
import { useMyAccountTypedSelector } from 'myaccount/hooks';
import useGetClientInformation from 'myaccount/view/app/hooks/useGetClientInformation';

import { useGetIssues } from './hooks/useGetIssues';
import IssuesPageSchema from './IssuesPage.schema';
import { ChoiceIssueValues, IssuesReasonsPageForm } from './IssuesPage.types';

const useIssuesPage = () => {
  const ownerId = useMyAccountTypedSelector(ownerIdSelector);
  const { clientInformation } = useGetClientInformation();
  const { nextStep, values } =
    useStepOrchestratorContext<IssuesReasonsPageForm>();

  const { issuesSummaries } = useGetIssues({
    ownerId,
    sellerId: clientInformation?.sellerId,
  });

  const formContext = useForm<ChoiceIssueValues>({
    resolver: yupResolver(IssuesPageSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      choiceIssue: values.selectedIssue?.id ?? values.order?.issueId,
    },
  });

  const onSubmit = async ({ choiceIssue }: { choiceIssue: string }) => {
    const selectedIssue = issuesSummaries?.find(
      (issue) => issue.id === choiceIssue,
    );

    nextStep({ selectedIssue });
  };

  return {
    issues: issuesSummaries || [],
    issuesCount: issuesSummaries?.length || 0,
    formContext,
    onSubmit,
  };
};

export default useIssuesPage;
