import { useQuery } from 'react-query';

import { OwnerService } from 'myaccount/api';

export const UseGetOrdersByOwnerId = 'useGetOrderByOwnerId';

export const useGetOrderByOwnerId = ({ ownerId }: { ownerId?: string }) => {
  const { data: orders } = useQuery(
    [UseGetOrdersByOwnerId, { ownerId }],
    async () => {
      if (!ownerId) {
        return undefined;
      }

      const response = await OwnerService.getOrderByOwnerId({
        ownerId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
    },
  );

  return {
    orders,
  };
};
