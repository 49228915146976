import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import SwipeableDrawer, {
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { PickupPoint } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import useGetPickupPoints from 'myaccount/view/app/hooks/useGetPickupPoints';
import { NewStepLayoutProfessional } from 'myaccount/view/app/NewLayout/NewStepLayout/NewStepLayoutProfessional';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';
import { AddressManagement } from 'myaccount/view/components/AddressManagement';
import HandlingCard from 'myaccount/view/components/HandlingCard';
import PickupPointSelector from 'myaccount/view/components/PickupPointSelector';
import { ResumeDeposit } from 'myaccount/view/components/ResumeDeposit';
import { ResumeSolution } from 'myaccount/view/components/ResumeSolution';

import { PageFooterContainer } from '../../../../app/NewLayout/NewStepLayout';
import { ResumeCard } from '../../components/ResumeCard';
import { IrshStepValues } from '../IrshPages.types';

import useClaimGroupDeliveryPage from './ClaimGroupDeliveryPage.hooks';
import {
  $DeliveryItemWrapper,
  $DeliveryResultWrapper,
  $DeliveryWrapper,
  $Heading,
  $HeadingIcon,
  $LeftColumn,
} from './ClaimGroupDeliveryPage.styles';
import messages from './messages';

const ClaimGroupDeliveryPage: React.FC<RoutedStepProps<IrshStepValues>> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const {
    claimGroupConfirmation,
    groupedCarriers,
    formContext,
    changeSelection,
    onSubmit,
    isDeliveryHandlingLoading,
    chooseHandlingSelected,
    handlingSelected,
    homeSelectedAddressChanged,
  } = useClaimGroupDeliveryPage({
    onNextStep,
  });

  const {
    setValue,
    formState: { isSubmitting, isValid },
  } = formContext;

  const {
    sellerId,
    carriers,
    addressSelected,
    pickupPoints,
    noPickupPoints,
    addresses,
    handleSetAddressSelected,
    selectedPickupPoint,
    pickupPointsIsLoading,
  } = useGetPickupPoints({
    selectedCarrierType: chooseHandlingSelected,
    groupedCarriers,
    claimGroupId,
  });

  const customBuildUpdateSolutionUrl = claimGroupId
    ? buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: { claimGroupId },
      })
    : undefined;

  const handleSetSelectedPickupPoint = (pickupPointSelected: PickupPoint) => {
    Promise.resolve(setValue('pickupPointSelected', pickupPointSelected)).then(
      () => onSubmit(),
    );
  };

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  return (
    <>
      <NewStepLayoutProfessional
        stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE}
        customSteps={PROFESSIONAL_STEP_PROGRESS}
      >
        <FormProvider {...formContext}>
          <form onSubmit={onSubmit}>
            <$DeliveryWrapper>
              <$LeftColumn>
                {claimGroupId && (
                  <ResumeSolution
                    claimGroupId={claimGroupId}
                    claimGroupConfirmation={claimGroupConfirmation}
                    customBuildUpdateSolutionUrl={customBuildUpdateSolutionUrl}
                  />
                )}
                <ResumeDeposit
                  claimGroupConfirmation={claimGroupConfirmation}
                />
                <$Heading>
                  <$HeadingIcon>
                    <MyAccountIcon icon={ICONS_TYPE.DELIVERY_ICON} />
                  </$HeadingIcon>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.handlingTitle}
                  />
                </$Heading>
                <$DeliveryItemWrapper>
                  {isDeliveryHandlingLoading ? (
                    <BaseLoader />
                  ) : (
                    <HandlingCard groupedCarriers={groupedCarriers} />
                  )}
                </$DeliveryItemWrapper>
              </$LeftColumn>
              <$DeliveryResultWrapper>
                <ResumeCard />
              </$DeliveryResultWrapper>
            </$DeliveryWrapper>

            <SwipeableDrawer
              isOpen={handlingSelected}
              onClose={changeSelection}
              hasBackdrop
              hasPadding={false}
              hasBackdropScroll={false}
              hasCloseButton={false}
            >
              {handlingSelected && (
                <PickupPointSelector
                  maxDistance={200}
                  pickupPoints={pickupPoints}
                  selectedPickupPointId={
                    selectedPickupPoint ? selectedPickupPoint.id : undefined
                  }
                  onSelectPickupPoint={handleSetSelectedPickupPoint}
                  searchAddress={addressSelected}
                  userAddresses={addresses}
                  onAddressSelected={handleSetAddressSelected}
                  carriers={carriers}
                  onClose={changeSelection}
                  pickupPointsIsLoading={pickupPointsIsLoading}
                  sellerId={sellerId}
                  noPickupPoints={noPickupPoints}
                />
              )}
            </SwipeableDrawer>
            <SwipeableDrawer
              isOpen={homeSelectedAddressChanged}
              size={DRAWER_SIZE.SMALL}
              onClose={changeSelection}
              hasBackdrop
              hasBackdropScroll={false}
            >
              <AddressManagement onSubmit={onSubmit} />
            </SwipeableDrawer>
          </form>
        </FormProvider>
        <Modal
          isOpen={openResumeModal}
          onClose={handleOpenModal}
          shouldShowPadding={false}
        >
          <ResumeCard />
        </Modal>
      </NewStepLayoutProfessional>

      <PageFooterContainer>
        <Button
          type={BUTTON_TYPES.BUTTON}
          secondary
          naked
          onClick={() => {
            onPreviousStep({});
          }}
          icon={<ArrowNakedLeftIcon />}
          position={SUPPORTED_ICON_POSITIONS.LEFT}
          dataTestId="previousButton"
        >
          <SafeFormattedMessageWithoutSpread
            message={messages.previousButton}
          />
        </Button>

        <Button
          primary
          type={BUTTON_TYPES.BUTTON}
          icon={<ArrowNakedRightIcon />}
          position={SUPPORTED_ICON_POSITIONS.RIGHT}
          disabled={!isValid}
          isLoading={isSubmitting}
          onClick={onSubmit}
          dataTestId="nextButton"
        >
          <SafeFormattedMessageWithoutSpread message={messages.nextButton} />
        </Button>
      </PageFooterContainer>
    </>
  );
};

export default ClaimGroupDeliveryPage;
