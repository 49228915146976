import { generatePath } from 'react-router-dom';

import { RoutedStep } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';

import { FINAL_ROUTES } from '../../app';
import { STEP_CONFIG } from '../../app/NewLayout/ClassiqueRoutes.config';

import { computeReasonSteps } from './helpers/computeReasonSteps';
import { IrshStepValues } from './IrshPages.types';
import { NewChooseProductPage } from './NewChooseProductPage/NewChooseProductPage';
import { NewClaimGroupConfirmationPage } from './NewClaimGroupConfirmationPage/NewClaimGroupConfirmationPage';
import { NewClaimGroupConfirmationPageWrapper } from './NewClaimGroupConfirmationPage/NewClaimGroupConfirmationPageWrapper';
import { NewClaimGroupDeliveryPage } from './NewClaimGroupDeliveryPage/NewClaimGroupDeliveryPage';
import { NewClaimGroupDeliveryPageWrapper } from './NewClaimGroupDeliveryPage/NewClaimGroupDeliveryPageWrapper';
import { NewClaimGroupDepositPage } from './NewClaimGroupDepositPage/NewClaimGroupDepositPage';
import { NewClaimGroupDepositPageWrapper } from './NewClaimGroupDepositPage/NewClaimGroupDepositPageWrapper';
import { NewClaimGroupDiagnosticTreePage } from './NewClaimGroupDiagnosticTreePage/NewClaimGroupDiagnosticTreePage';
import { NewClaimGroupDiagnosticTreePageWrapper } from './NewClaimGroupDiagnosticTreePage/NewClaimGroupDiagnosticTreePageWrapper';
import { NewClaimGroupDocumentPageRelatedToClaim } from './NewClaimGroupDocumentPageRelatedToClaim/NewClaimGroupDocumentPageRelatedToClaim';
import { NewClaimGroupDocumentPageRelatedToClaimWrapper } from './NewClaimGroupDocumentPageRelatedToClaim/NewClaimGroupDocumentPageRelatedToClaimWrapper';
import { NewClaimGroupDocumentPageRelatedToProduct } from './NewClaimGroupDocumentPageRelatedToProduct/NewClaimGroupDocumentPageRelatedToProduct';
import { NewClaimGroupDocumentPageRelatedToProductWrapper } from './NewClaimGroupDocumentPageRelatedToProduct/NewClaimGroupDocumentPageRelatedToProductWrapper';
import { NewClaimGroupProductInfoPage } from './NewClaimGroupProductInfoPage/NewClaimGroupProductInfoPage';
import { NewClaimGroupProductInfoWrapper } from './NewClaimGroupProductInfoPage/NewClaimGroupProductInfoWrapper';
import { NewClaimGroupReasonPageWrapper } from './NewClaimGroupReasonPage/NewClaimGroupReasonPageWrapper';
import { NewClaimGroupSolutionPage } from './NewClaimGroupSolutionPage/NewClaimGroupSolutionPage';
import { NewClaimGroupSolutionPageWrapper } from './NewClaimGroupSolutionPage/NewClaimGroupSolutionPageWrapper';
import { NewIssuesPage } from './NewIssuesPage/NewIssuesPage';

export const STEPS_CONFIG: RoutedStep<IrshStepValues>[] = [
  {
    name: STEP_CONFIG.ISSUES,
    route: FINAL_ROUTES.ISSUES,
    Component: NewIssuesPage,
    onNextStep: ({ values }) =>
      generatePath(FINAL_ROUTES.CHOOSE_PRODUCTS, {
        issueId: values?.issue?.id,
      }),
  },
  {
    name: STEP_CONFIG.CHOOSE_PRODUCTS,
    route: FINAL_ROUTES.CHOOSE_PRODUCTS,
    Component: NewChooseProductPage,
    onPreviousStep: () => generatePath(FINAL_ROUTES.ISSUES),
    onNextStep: ({ values }) => {
      return generatePath(FINAL_ROUTES.CLAIM_GROUP_REASON, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_DIAGNOSTIC_TREE,
    route: FINAL_ROUTES.CLAIM_GROUP_DIAGNOSTIC_TREE,
    Component: NewClaimGroupDiagnosticTreePage,
    Wrapper: NewClaimGroupDiagnosticTreePageWrapper,
    onPreviousStep: ({ values }) => {
      return generatePath(FINAL_ROUTES.CLAIM_GROUP_REASON, {
        claimGroupId: values?.claimGroupId,
      });
    },
    onNextStep: ({ values }) => {
      return generatePath(FINAL_ROUTES.CLAIM_GROUP_REASON, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_REASON,
    route: FINAL_ROUTES.CLAIM_GROUP_REASON,
    Component: NewClaimGroupReasonPageWrapper,
    onPreviousStep: ({ values }) => {
      return generatePath(FINAL_ROUTES.CHOOSE_PRODUCTS, {
        issueId: values?.issue?.id,
      });
    },
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      const {
        hasAdditionalInformationFile,
        hasAdditionnalInformationFileRelatedToClaim,
        hasAdditionnalInformationFileRelatedToProduct,
        hasAdditionnalInformationProductRelatedToProduct,
      } = computeReasonSteps({
        claims: values?.claims,
        reason: values?.reason,
      });

      if (hasAdditionnalInformationProductRelatedToProduct) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_PRODUCT_INFO, {
          claimGroupId: values?.claimGroupId,
        });
      }

      if (hasAdditionnalInformationFileRelatedToProduct) {
        return generatePath(
          FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT,
          {
            claimGroupId: values?.claimGroupId,
          },
        );
      }

      if (
        hasAdditionnalInformationFileRelatedToClaim ||
        hasAdditionalInformationFile
      ) {
        return generatePath(
          FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
          {
            claimGroupId: values?.claimGroupId,
          },
        );
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_SOLUTION, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },

  {
    name: STEP_CONFIG.CLAIM_GROUP_PRODUCT_INFO,
    route: FINAL_ROUTES.CLAIM_GROUP_PRODUCT_INFO,
    Component: NewClaimGroupProductInfoPage,
    Wrapper: NewClaimGroupProductInfoWrapper,
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      const {
        hasAdditionalInformationFile,
        hasAdditionnalInformationFileRelatedToClaim,
        hasAdditionnalInformationFileRelatedToProduct,
      } = computeReasonSteps({
        claims: values?.claims,
        reason: values?.reason,
      });

      if (hasAdditionnalInformationFileRelatedToProduct) {
        return generatePath(
          FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT,
          {
            claimGroupId: values?.claimGroupId,
          },
        );
      }

      if (
        hasAdditionnalInformationFileRelatedToClaim ||
        hasAdditionalInformationFile
      ) {
        return generatePath(
          FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
          {
            claimGroupId: values?.claimGroupId,
          },
        );
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_SOLUTION, {
        claimGroupId: values?.claimGroupId,
      });
    },
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_REASON, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT,
    route: FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT,
    Component: NewClaimGroupDocumentPageRelatedToProduct,
    Wrapper: NewClaimGroupDocumentPageRelatedToProductWrapper,
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      const {
        hasAdditionalInformationFile,
        hasAdditionnalInformationFileRelatedToClaim,
      } = computeReasonSteps({
        claims: values?.claims,
        reason: values?.reason,
      });

      if (
        hasAdditionnalInformationFileRelatedToClaim ||
        hasAdditionalInformationFile
      ) {
        return generatePath(
          FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
          {
            claimGroupId: values?.claimGroupId,
          },
        );
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_SOLUTION, {
        claimGroupId: values?.claimGroupId,
      });
    },
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      const { hasAdditionnalInformationProductRelatedToProduct } =
        computeReasonSteps({
          claims: values?.claims,
          reason: values?.reason,
        });

      if (hasAdditionnalInformationProductRelatedToProduct) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_PRODUCT_INFO, {
          claimGroupId: values?.claimGroupId,
        });
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_REASON, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
    route: FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
    Component: NewClaimGroupDocumentPageRelatedToClaim,
    Wrapper: NewClaimGroupDocumentPageRelatedToClaimWrapper,
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_SOLUTION, {
        claimGroupId: values?.claimGroupId,
      });
    },
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      const {
        hasAdditionnalInformationFileRelatedToProduct,
        hasAdditionnalInformationProductRelatedToProduct,
      } = computeReasonSteps({
        claims: values?.claims,
        reason: values?.reason,
      });

      if (hasAdditionnalInformationFileRelatedToProduct) {
        return generatePath(
          FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT,
          {
            claimGroupId: values?.claimGroupId,
          },
        );
      }

      if (hasAdditionnalInformationProductRelatedToProduct) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_PRODUCT_INFO, {
          claimGroupId: values?.claimGroupId,
        });
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_REASON, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },

  {
    name: STEP_CONFIG.CLAIM_GROUP_SOLUTION,
    route: FINAL_ROUTES.CLAIM_GROUP_SOLUTION,
    Component: NewClaimGroupSolutionPage,
    Wrapper: NewClaimGroupSolutionPageWrapper,
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      const handling = values?.handling;
      const { hasHome, hasDelivery, hasDeposit } = handling;

      if (hasDeposit || hasHome) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_HANDLING_DEPOSIT, {
          claimGroupId: values?.claimGroupId,
        });
      }

      if (hasDelivery) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_HANDLING_DELIVERY, {
          claimGroupId: values?.claimGroupId,
        });
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_CONFIRMATION, {
        claimGroupId: values?.claimGroupId,
      });
    },
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      const {
        hasAdditionalInformationFile,
        hasAdditionnalInformationFileRelatedToClaim,
        hasAdditionnalInformationFileRelatedToProduct,
        hasAdditionnalInformationProductRelatedToProduct,
      } = computeReasonSteps({
        claims: values?.claims,
        reason: values?.reason,
      });

      if (
        hasAdditionnalInformationFileRelatedToClaim ||
        hasAdditionalInformationFile
      ) {
        return generatePath(
          FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM,
          {
            claimGroupId: values?.claimGroupId,
          },
        );
      }

      if (hasAdditionnalInformationFileRelatedToProduct) {
        return generatePath(
          FINAL_ROUTES.CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT,
          {
            claimGroupId: values?.claimGroupId,
          },
        );
      }

      if (hasAdditionnalInformationProductRelatedToProduct) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_PRODUCT_INFO, {
          claimGroupId: values?.claimGroupId,
        });
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_REASON, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },

  {
    name: STEP_CONFIG.CLAIM_GROUP_DEPOSIT,
    route: FINAL_ROUTES.CLAIM_GROUP_HANDLING_DEPOSIT,
    Component: NewClaimGroupDepositPage,
    Wrapper: NewClaimGroupDepositPageWrapper,
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_SOLUTION, {
        claimGroupId: values?.claimGroupId,
      });
    },
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      const handling = values?.handling;
      const { hasDelivery } = handling;

      if (hasDelivery) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_HANDLING_DELIVERY, {
          claimGroupId: values?.claimGroupId,
        });
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_CONFIRMATION, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_DELIVERY,
    route: FINAL_ROUTES.CLAIM_GROUP_HANDLING_DELIVERY,
    Component: NewClaimGroupDeliveryPage,
    Wrapper: NewClaimGroupDeliveryPageWrapper,
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      const handling = values?.handling;
      const { hasHome, hasDeposit } = handling;

      if (hasDeposit || hasHome) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_HANDLING_DEPOSIT, {
          claimGroupId: values?.claimGroupId,
        });
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_SOLUTION, {
        claimGroupId: values?.claimGroupId,
      });
    },
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_CONFIRMATION, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },
  {
    name: STEP_CONFIG.CLAIM_GROUP_CONFIRMATION,
    route: FINAL_ROUTES.CLAIM_GROUP_CONFIRMATION,
    Component: NewClaimGroupConfirmationPage,
    Wrapper: NewClaimGroupConfirmationPageWrapper,
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      const handling = values?.handling;
      const { hasHome, hasDelivery, hasDeposit } = handling;

      if (hasDelivery) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_HANDLING_DELIVERY, {
          claimGroupId: values?.claimGroupId,
        });
      }

      if (hasDeposit || hasHome) {
        return generatePath(FINAL_ROUTES.CLAIM_GROUP_HANDLING_DEPOSIT, {
          claimGroupId: values?.claimGroupId,
        });
      }

      return generatePath(FINAL_ROUTES.CLAIM_GROUP_SOLUTION, {
        claimGroupId: values?.claimGroupId,
      });
    },
  },
];
