import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { HANDLING_MODES } from '@savgroup-front-common/constants';
import { CommonCarrierService } from '@savgroup-front-common/core/src/api';
import { CarrierProductSummary } from '@savgroup-front-common/core/src/api/Carrier/getCarrierProductByIdQuery';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { ClaimService } from '../../../../../../api';
import { getGroupedCarriers } from '../../../../../../helpers';

const UseGetGroupedCarriers = 'useGetGroupedCarriers';

export const useGetGroupedCarriers = () => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const { pushErrors } = useToasts();

  const { data } = useQuery(
    [UseGetGroupedCarriers, { claimGroupId }],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const responseGetHandling = await ClaimService.getHandlingByClaimGroup({
        claimGroupId,
      });
      const responseGetCarrier = await ClaimService.getClaimGroupCarrierQuery({
        claimGroupId,
        handlingMode: HANDLING_MODES.DEPOSIT,
      });

      if (responseGetHandling.failure) {
        pushErrors(responseGetHandling.errors);

        return undefined;
      }
      if (responseGetCarrier.failure) {
        pushErrors(responseGetCarrier.errors);

        return undefined;
      }

      const handlingInfo = responseGetHandling.value;
      const carriers = responseGetCarrier.value;

      const carrierProductIds = carriers
        ?.map((carrier) => carrier.carrierProductId)
        .filter((carrier) => carrier) as string[];

      if (!carrierProductIds) {
        return undefined;
      }

      const carrierProductSummaries = await Promise.all(
        carrierProductIds?.map(async (carrierProductId) => {
          const response =
            await CommonCarrierService.getCarrierProductByIdQuery({
              carrierProductId,
            });

          if (response.failure) {
            pushErrors(responseGetHandling.errors);

            return undefined;
          }

          return response.value;
        }),
      );

      const { groupedCarriers } = getGroupedCarriers({
        carriers,
        multiCarrierProductSummary: carrierProductSummaries.filter(
          (carrierProductId) => carrierProductId,
        ) as CarrierProductSummary[],
        handlingInfo,
      });

      return { groupedCarriers, handlingInfo };
    },
    { staleTime: Infinity },
  );

  return {
    groupedCarriers: data?.groupedCarriers,
    handlingInfo: data?.handlingInfo,
  };
};
