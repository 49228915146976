import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  HANDLING_GROUPS,
  HANDLING_MODES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import {
  AddressInfoDto,
  PickupPointAddress,
} from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { useGetClaimGroupCarrierQuery } from 'myaccount/view/app/hooks/useGetClaimGroupCarrierQuery';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import { useGetHandlingByClaimGroup } from 'myaccount/view/app/hooks/useGetHandlingByClaimGroup';
import useGetMultiCarrierProductByIdQuery from 'myaccount/view/app/hooks/useGetMultiCarrierProductByIdQuery';

import { getGroupedCarriers } from '../../../../../helpers';
import { useLayout } from '../../../../app/NewLayout/NewLayout.hooks';
import { IrshStepValues } from '../IrshPages.types';

import ClaimGroupDepositPageSchema from './ClaimGroupDepositPage.schema';
import { ClaimGroupDepositValues } from './ClaimGroupDepositPage.types';
import useSuggestedAddress from './hooks/useSuggestedAddress';

const useClaimGroupDepositPage = ({
  onNextStep,
}: {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}) => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const { removeAllNotifications, pushErrors } = useToasts();

  const { login: owner } = useLayout();

  const { ownerAddress, mail } = owner;

  const { claimGroupConfirmation, isLoading: isGetClaimGroupConfirmation } =
    useGetClaimGroupConfirmation({
      claimGroupId,
    });

  const { handling, isLoading: isGetHandlingLoading } =
    useGetHandlingByClaimGroup({ claimGroupId });
  const { carriers, isLoading: isGetCarrierLoading } =
    useGetClaimGroupCarrierQuery({
      claimGroupId,
      handlingMode: HANDLING_MODES.DEPOSIT,
    });

  const { multiCarrierProductSummary } = useGetMultiCarrierProductByIdQuery({
    carrierProductIds: carriers
      ?.map((carrier) => carrier.carrierProductId)
      .filter((carrier) => carrier) as string[],
  });

  const { groupedCarriers } = getGroupedCarriers({
    carriers,
    multiCarrierProductSummary,
    handlingInfo: handling,
  });

  const shouldShowTransportDeduction =
    handling?.canDeduceTransportFromRefund &&
    carriers?.some((carrier) => carrier.priceWithTax);

  const formContext = useForm<ClaimGroupDepositValues>({
    defaultValues: ownerAddress
      ? {
          addressSelected: {
            ...ownerAddress,
            firstName: ownerAddress.firstname,
            lastName: ownerAddress.lastname,
            mail,
          },
        }
      : {},
    resolver: yupResolver(ClaimGroupDepositPageSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit, setValue, watch } = formContext;

  const changeSelection = () => {
    setValue('chooseHandling', undefined);
  };

  const {
    mutateAsync: handleDepositHandling,
    isLoading: isLoadingDepositHandlingSubmit,
  } = useMutation(
    ['setDepositHandling'],
    async ({
      claimGroupId,
      payload,
    }: {
      claimGroupId?: string;
      payload: {
        handlingMode: HANDLING_MODES;
        pickupPointId?: string;
        pickupPointAddress?: PickupPointAddress | null;
        carrierCustomerPriceId?: string;
        carrierSellerPriceId?: string;
        address?: AddressInfoDto | null;
      };
    }) => {
      removeAllNotifications();
      const responseHandling = await ClaimService.setClaimGroupHandling({
        claimGroupId,
        payload,
      });

      if (responseHandling.failure) {
        pushErrors(responseHandling.errors);

        return undefined;
      }

      return responseHandling;
    },
  );

  const { handleAddressSubmit } = useSuggestedAddress({
    formContext,
  });

  const chooseHandlingSelected = watch('chooseHandling');

  const handlingSelected = Boolean(
    chooseHandlingSelected === HANDLING_GROUPS.PICKUP_POINT ||
      chooseHandlingSelected === HANDLING_GROUPS.PICKUP_STORE ||
      chooseHandlingSelected === HANDLING_GROUPS.PICKUP_STORE_DELIVERY,
  );

  const homeSelectedAddressChanged = Boolean(
    chooseHandlingSelected === HANDLING_GROUPS.HOME ||
      chooseHandlingSelected === HANDLING_GROUPS.HOME_INTERVENTION ||
      chooseHandlingSelected === HANDLING_GROUPS.EXTERNAL,
  );

  const onSubmit = handleSubmit(
    async ({
      chooseHandling,
      pickupPointSelected,
      addressSelected,
      addressSuggested,
      keepSuggestedAddress,
    }) => {
      const hasHome = handling?.hasHome;

      Promise.resolve(handleAddressSubmit({ addressSelected }))
        .then((shouldBeChoiceAnAddress) => {
          const payload = pickupPointSelected
            ? {
                handlingMode: HANDLING_MODES.DEPOSIT,
                pickupPointId: pickupPointSelected.id,
                pickupPointAddress: pickupPointSelected && {
                  id: pickupPointSelected.id,
                  name: pickupPointSelected.name,
                  firstName: pickupPointSelected.name,
                  address: pickupPointSelected.adress,
                  additionalAddress: pickupPointSelected.adress3,
                  postalCode: pickupPointSelected.postalCode,
                  city: pickupPointSelected.city,
                  countryCode: pickupPointSelected.countryCode,
                },
                carrierCustomerPriceId:
                  pickupPointSelected.carrierCustomerPriceId,
                carrierSellerPriceId: pickupPointSelected.carrierSellerPriceId,
                address: undefined,
              }
            : {
                handlingMode: hasHome
                  ? HANDLING_MODES.HOME
                  : HANDLING_MODES.DEPOSIT,
                pickupPointId: undefined,
                pickupPointAddress: null,
                carrierCustomerPriceId:
                  groupedCarriers[chooseHandling || HANDLING_GROUPS.HOME][0]
                    .carrierCustomerPriceId,
                carrierSellerPriceId:
                  groupedCarriers[chooseHandling || HANDLING_GROUPS.HOME][0]
                    .carrierSellerPriceId,
                address: keepSuggestedAddress
                  ? addressSuggested
                  : addressSelected,
              };

          return {
            shouldBeChoiceAnAddress,
            payload,
          };
        })
        .then(async ({ payload }) => {
          const responseHandling = await handleDepositHandling({
            claimGroupId,
            payload,
          });

          if (!responseHandling) {
            return undefined;
          }

          onNextStep({
            newValues: {
              handlingDeposit: {
                chooseHandling,
                pickupPointSelected,
                depositAddress: keepSuggestedAddress
                  ? addressSuggested
                  : addressSelected,
              },
              handling: responseHandling.value,
            },
          });

          return undefined;
        });
    },
  );

  const isDepositHandlingLoading =
    isGetCarrierLoading || isGetHandlingLoading || isGetClaimGroupConfirmation;

  return {
    claimGroupConfirmation,
    claimGroupId,
    groupedCarriers,
    depositAddress: '',
    shouldShowTransportDeduction,
    formContext,
    changeSelection,
    onSubmit,
    handling,
    isDepositHandlingLoading,
    isLoadingSubmit: isLoadingDepositHandlingSubmit,
    chooseHandlingSelected,
    handlingSelected,
    homeSelectedAddressChanged,
  };
};

export default useClaimGroupDepositPage;
