import React, { FC, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import BanneredLayout from 'myaccount/view/app/NewLayout/BanneredLayout/BanneredLayout';

import { forkLoadFileTrackingPage } from '../../../domains/FileTrackingPage/actionCreators';
import { useRedirectToNonNewDesignRoute } from '../irshPages/hooks/useRedirectToNonNewDesignRoute';

import FileFollowups from './FileFollowups/FileFollowups';
import {
  useBreadCrumbs,
  useGetProductsDetails,
} from './FileFollowupsPage.hooks';
import messages from './messages';

const FileFollowupsPage: FC = () => {
  const { fileId } = useParams() as { fileId: string };

  const dispatch = useDispatch();
  const {
    productsDetails,
    isLoadingSolution,
    isLoadingModel,
    isLoadingFileSummary,
  } = useGetProductsDetails();

  useRedirectToNonNewDesignRoute();

  useEffect(() => {
    dispatch(
      forkLoadFileTrackingPage({
        fileId: fileId,
      }),
    );
  }, [dispatch, fileId]);

  const isLoading = isLoadingSolution || isLoadingModel || isLoadingFileSummary;

  const breadCrumbs = useBreadCrumbs({ isLoading, productsDetails, fileId });

  if (isLoading || !productsDetails) {
    return <BaseLoader />;
  }

  return (
    <BanneredLayout>
      <BanneredLayout.BannerTitle
        breadcrumbs={breadCrumbs}
        title={safeFormattedIntlString(messages.bannerTitle, {
          reference: productsDetails[0]?.fileReference,
        })}
        description={messages.bannerDescription}
      />
      <BanneredLayout.Content>
        <Suspense fallback={<BaseLoader />}>
          <FileFollowups />
        </Suspense>
      </BanneredLayout.Content>
    </BanneredLayout>
  );
};

export default FileFollowupsPage;
