import React from 'react';
import { useParams } from 'react-router-dom';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';

import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { useInitializeClaimGroupId } from '../hooks/useInitializeClaimGroupId';
import { useInitializeClaims } from '../hooks/useInitializeClaims';
import { useInitializeIssue } from '../hooks/useInitializeIssue';
import { useInitializeReason } from '../hooks/useInitializeReason';
import { IrshStepValues } from '../IrshPages.types';

import { NewClaimGroupReasonPage } from './NewClaimGroupReasonPage';

export const NewClaimGroupReasonPageWrapper: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onPreviousStep, onNextStep }) => {
  const { claimGroupId: claimGroupIdFromUrl } = useParams<{
    claimGroupId: string;
  }>();

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const claimGroupId = values?.claimGroupId ?? claimGroupIdFromUrl;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const firstClaim = claims?.at(0);
  const reason = values?.reason;

  useInitializeIssue({
    claim: firstClaim,
  });
  useInitializeClaimGroupId();
  useInitializeClaims({
    claimGroupId,
  });
  useInitializeReason({
    claimGroupId,
    claim: firstClaim,
  });

  const isClaimsLoaded = claimGroupId && !!claims?.length;

  const hasReasonToLoad = isClaimsLoaded && firstClaim?.reasonId;
  const isReasonOk = hasReasonToLoad ? !!reason : true;

  if (!claimGroupId || !isClaimsLoaded || !isReasonOk) {
    return <BaseLoader />;
  }

  return (
    <NewClaimGroupReasonPage
      onNextStep={onNextStep}
      onPreviousStep={onPreviousStep}
    />
  );
};
