import styled from 'styled-components';

export const $Didactic = styled.div<{
  $withBottomMargin?: boolean;
  $isNewUiEnabled?: boolean;
}>`
  display: block;
  color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? theme.colors.paragraphTextColor
      : theme.colors.paragraphTextColor};
  font-style: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? null : 'italic')};
  margin-bottom: ${({ $withBottomMargin = true }) =>
    $withBottomMargin ? '1rem !important' : null};
  white-space: pre-wrap;
`;
