import React from 'react';

import { StartOrContinueDiagnosticTreeQueryReturnValues } from '../../../../../../api/Claim/startOrContinueDiagnosticTreeQuery';

import { $Paragraphe, $Question } from './StepInformations.styles';

interface StepInformationsProps {
  currentDiagnosticTreeStep: StartOrContinueDiagnosticTreeQueryReturnValues;
}

const StepInformations: React.FC<StepInformationsProps> = ({
  currentDiagnosticTreeStep,
}) => {
  return (
    <>
      {currentDiagnosticTreeStep?.currentNode?.title &&
        currentDiagnosticTreeStep.currentNode.title.length && (
          <$Question>{currentDiagnosticTreeStep.currentNode.title}</$Question>
        )}
      {currentDiagnosticTreeStep?.currentNode?.instructions &&
        currentDiagnosticTreeStep.currentNode.instructions.length && (
          <$Paragraphe>
            {currentDiagnosticTreeStep.currentNode.instructions}
          </$Paragraphe>
        )}
    </>
  );
};

export default StepInformations;
