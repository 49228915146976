import { rgba, transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { rem } from '../helpers';

import { defaultButtons } from './defaultComponents/buttons';

const defaultTheme: DefaultTheme = {
  colors: {
    primary: '#205DF3',
    primaryHover: '#205DF3',
    secondary: '#009BFF',
    secondaryBackground: '#E5F1FA',
    tertiary: '#8C9FAA',

    success: '#0AA171',
    danger: '#F03D3D',
    alert: '#F07300',
    default: '#C9CED6',
    disabled: '#E1E4E8',
    mainTextColor: '#343434',
    headingTextColor: '#205DF3',
    headerTitleColor: '#eaf7ff',
    paragraphTextColor: '#8A94A6',
    linkColor: '#4183c4',
    headerTextColor: '#54617a',
    iconColor: '#000000',
    menuItemSeparator: '#666666',

    white: '#FFFFFF',
    lightGrey: '#E6EAEE',
    backgroundColor: transparentize(0.65, '#C9CED6'),
    darkBackground: '#2a2833',
    secondaryColor1: '#ebebeb',
    secondaryColor2: '#f7f7f7',
    neutralBackground: '#ffffff',
    black: '#000000',
    purple: '#9c36f2',
    datatableRowBackgroundHoverColor: '#689ABA0D',
    datatableRowExpandedBackgroundColor: '#689ABA0D',
  },
  systemColors: {
    defaultTextColor: '#FFFFFF',
    invertedTextColor: '#2a2833',
    defaultBackground: '#111111',
    secondaryBackground: '#212121',
  },
  interactions: {
    basicTiming: '.2s',
    transformTiming: '.25s',
    activeScale: '.96',
    buttonHoverAsHollow: false,
  },
  fonts: {
    weight: {
      thin: 100,
      light: 300,
      normal: 400,
      mediumFont: 500,
      mediumBold: 600,
      bold: 700,
    },
    size: {
      tiny: '0.438rem',
      ultraSmall: '0.813rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.125rem',
      big: '1.563rem',
      huge: '1.875rem',
      header: '4.375rem',
      heading: {
        h1: rem(32),
        h2: rem(24),
        h3: rem(21),
        h4: rem(18),
        h5: rem(16),
        h6: rem(15),
      },
    },
  },
  spacing: {
    none: '0',
    xs: '.125rem',
    sm: '.25rem',
    md: '.5rem',
    lg: '1rem',
    xl: '1.5rem',
    xxl: '2rem',
    get: ({
      theme,
      top,
      right,
      bottom,
      left,
    }: {
      theme: DefaultTheme;
      top: string;
      right?: string;
      bottom?: string;
      left?: string;
    }) => {
      const $1 = (theme.spacing as any)[top];
      const $2 = right ? (theme.spacing as any)[right] : undefined;
      const $3 = bottom ? (theme.spacing as any)[bottom] : undefined;
      const $4 = left ? (theme.spacing as any)[left] : undefined;
      const result = `${$1}${$2 ? ` ${$2}` : ''}${$3 ? ` ${$3}` : ''}${
        $4 ? ` ${$4}` : ''
      }`;

      return result;
    },
  },
  shadows: {
    // eslint-disable-next-line camelcase
    elevation__1: `box-shadow: 0 0 ${rem('1px')} 0 rgba(10,31,68,0.08), 0 ${rem(
      '1px',
    )} ${rem('1px')} 0 rgba(10,31,68,0.08);`,
    // eslint-disable-next-line camelcase
    elevation__2: `box-shadow: 0 0 ${rem('1px')} 0 rgba(10,31,68,0.08), 0 ${rem(
      '3px',
    )} ${rem('4px')} 0 rgba(10,31,68,0.1);`,
  },
  borders: {
    radius: '4px',
    thickness: '1px',
    thicknessSpecialInput: '2px',
  },
  components: {
    [SUPPORTED_COMPONENTS.BACK_BUTTON]: {
      [SUPPORTED_PROPERTIES.SPACE_BETWEEN_ICON_AND_TEXT]: {
        value: '10px',
      },
    },
    [SUPPORTED_COMPONENTS.DEFAULT_BUTTONS]: defaultButtons,
    [SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS]: {
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: `${rem(9)} 0.5rem`,
      },
      [SUPPORTED_PROPERTIES.SPACE_BETWEEN_ICON_AND_TEXT]: {
        value: `${rem(10)}`,
      },
    },
    [SUPPORTED_COMPONENTS.DEFAULT_INPUTS]: {
      [SUPPORTED_PROPERTIES.HEIGHT]: {
        value: '3.536rem',
      },
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: '1.125rem 1.25rem',
      },
    },
    [SUPPORTED_COMPONENTS.DEFAULT_AUTOCOMPLETE]: {
      [SUPPORTED_PROPERTIES.HEIGHT]: {
        fallback: `components.${SUPPORTED_COMPONENTS.DEFAULT_INPUTS}.height`,
      },
    },
    [SUPPORTED_COMPONENTS.LOGOUT_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.headingTextColor',
      },
    },
    [SUPPORTED_COMPONENTS.LANGUAGE_CHOOSER]: {
      [SUPPORTED_PROPERTIES.HEIGHT]: {
        value: '3.536rem',
      },
    },
    [SUPPORTED_COMPONENTS.SMALL_LOGOUT_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.headingTextColor',
      },
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: '0.64rem',
      },
    },
    [SUPPORTED_COMPONENTS.FORM_GROUP]: {
      [SUPPORTED_PROPERTIES.MARGIN_BOTTOM]: {
        value: '1rem',
      },
      [SUPPORTED_PROPERTIES.MARGIN_TOP]: {
        value: '0.75rem',
      },
    },
    [SUPPORTED_COMPONENTS.IRSH_ICONS]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        value: '#000000',
      },
    },
    [SUPPORTED_COMPONENTS.SOLUTION_CHOICE_BUTTON]: {
      [SUPPORTED_PROPERTIES.TEXT_TRANSFORM]: {
        value: 'upset',
      },
      [SUPPORTED_PROPERTIES.WHITE_SPACE]: {
        value: 'unset',
      },
      [SUPPORTED_PROPERTIES.WIDTH]: {
        value: '90%',
      },
    },
    [SUPPORTED_COMPONENTS.AUTOCOMPLETE_OPTION]: {
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        value: ({ theme }: { theme: DefaultTheme }) =>
          rgba(theme.colors.primary, 0.05),
      },
      [SUPPORTED_PROPERTIES.ON_FOCUS_BACKGROUND_COLOR]: {
        value: ({ theme }: { theme: DefaultTheme }) =>
          rgba(theme.colors.primary, 0.05),
      },
    },
    card: {
      coloredBackgroundColor: {
        value: '#f7f7f7',
      },
    },
  },

  newUI: {
    borders: {
      smallRadius: '4px',
      mediumRadius: '6px',
      bigRadius: '8px',
    },
    gradients: {
      blueStart: '#010B35',
      blueEnd: '#002E74',
      purpleStart: '#151F4C',
      purpleMiddle: '#482F99',
      purpleEnd: '#4F56D1',
      lightStart: '#EEEFFE',
      lightMiddle: '#EEEFFE99',
      lightEnd: '#E1E5FD',
    },
    defaultColors: {
      heading: '#002E74',
      menu: '#002E74',
      primary: '#5369EB',
      secondary: '#5A72FF',
      text: '#000000',
      secondaryText: '#515969',
    },
    shadows: {
      level1: `0px 1px 4px 0px ${rgba(0, 0, 0, 0.1)}`,
    },
    iconColors: {
      primary: {
        color: '#5369EB',
        bgColor: '#EEF0FD',
      },
      secondary: {
        color: '#A661FF',
        bgColor: '#F1E5FF',
      },
      alert: {
        color: '#F36C6C',
        bgColor: '#FFF9E5',
      },
      caution: {
        color: '#FB6D86',
        bgColor: '#FFE9ED',
      },
      danger: {
        color: '#E25E5E',
        bgColor: '#FEF0F0',
      },
      info: {
        color: '#3AACC6',
        bgColor: '#E2F5FF',
      },
      success: {
        color: '#31BF95',
        bgColor: '#E7FAEB',
      },
      warning: {
        color: '#FF7B1A',
        bgColor: '#FFE9DA',
      },
      disabled: {
        color: '#515969',
        bgColor: '#F1F2F5',
      },
      gold: {
        color: '#CC9300',
        bgColor: '#FFF9E5',
      },
    },
    tagsColors: {
      primary: {
        color: '#5369EB',
        bgColor: '#EEF0FD',
      },
      secondary: {
        color: '#A661FF',
        bgColor: '#F1E5FF',
      },
      danger: {
        color: '#E25E5E',
        bgColor: '#FEF0F0',
      },
      success: {
        color: '#31BF95',
        bgColor: '#E7FAEB',
      },
      alert: {
        color: '#FF7B1A',
        bgColor: '#FFE9DA',
      },
      disabled: {
        color: '#515969',
        bgColor: '#F1F2F5',
      },
      neutral: {
        color: '#515969',
        bgColor: '#F1F2F5',
      },
    },
    inputBorderColors: {
      default: '#CCD2F9',
    },
    dividerColors: {
      default: '#DDE1FB',
    },
    variationColors: {
      light1: '#5A72FF',
      light2: '#A2ABE9',
      light3: '#CCD2F9',
      light4: '#DDE1FB',
      light5: '#EEF0FD',
      light6: '#F4F6FF',
      light7: '#FBFBFF',
      dark1: '#465AD3',
      dark2: '#3F51BD',
    },
  },
};

export default defaultTheme;
