import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants';

import { StatelessQuotationTotalVariants } from './StatelessQuotationTotal.constants';

export const $TotalLine = styled.div`
  color: ${({ theme }) => theme.colors.mainTextColor};
`;

export const $FinalTotalLine = styled.div<{
  $variant: StatelessQuotationTotalVariants;
}>`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.mainTextColor};

  ${({ $variant }) =>
    $variant === StatelessQuotationTotalVariants.SELFCARE
      ? css`
          background-color: ${({ theme }) => theme.colors.headerTitleColor};
          padding: 1rem;

          display: flex;
          justify-content: end;
          margin-bottom: 1rem;
          margin-top: 1rem;
        `
      : undefined}
`;

export const $QuotationTotal = styled('div')`
  margin-bottom: 1rem;
  text-align: right;
  margin-right: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: end;
  @media ${media.maxWidth.xs} {
    margin-right: unset;
  }
`;
