import React, { FunctionComponent } from 'react';

import { useNewUiContext } from '../../components/NewUiProvider/NewUiProvider';

import { $SkeletonItem } from './Skeleton.styles';

interface SkeletonProps {
  Component?: any;
  isLoading?: boolean;
  width?: string;
  height?: string;
  marginBottom?: string;
  marginRight?: string;
  isCircle?: boolean;
  isInline?: boolean;
  dataTestId?: string;
  highContrast?: boolean;
}

const Skeleton: FunctionComponent<React.PropsWithChildren<SkeletonProps>> = ({
  children,
  Component = $SkeletonItem,
  isLoading = true,
  width = '100%',
  height = '1rem',
  marginBottom = '0',
  marginRight = '0',
  isCircle = false,
  isInline = false,
  dataTestId = 'skeleton',
  highContrast = false,
}) => {
  const { isDarkModeEnabled } = useNewUiContext();

  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <Component
      $isInline={isInline}
      $width={width}
      $height={height}
      $marginBottom={marginBottom}
      $marginRight={marginRight}
      $isCircle={isCircle}
      $highContrast={isDarkModeEnabled || highContrast}
      data-testid={dataTestId}
    >
      {isInline ? '\xa0' : null}
    </Component>
  );
};

export default Skeleton;
