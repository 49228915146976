import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { DataGouvService } from '@savgroup-front-common/core/src/api';
import { AddressInfoDto } from '@savgroup-front-common/types';

import { ClaimGroupDepositValues } from '../../../../newDesignPages/irshPages/NewClaimGroupDepositPage/NewClaimGroupDepositPage.types';

const useSuggestedAddress = ({
  formContext,
}: {
  formContext: UseFormReturn<ClaimGroupDepositValues>;
}) => {
  const [isAddressSuggestedOpenModal, setIsAddressSuggestedOpenModal] =
    useState<boolean>(false);
  const { setValue } = formContext;

  const { data: suggestedAddressData, mutateAsync: handleValidateAddress } =
    useMutation([], async (newAddress: AddressInfoDto | undefined) => {
      if (!newAddress) {
        return undefined;
      }

      try {
        const response = await DataGouvService.geoCodeAddressFromDataGouv({
          query: [newAddress?.address, newAddress?.postalCode, newAddress?.city]
            .filter((x) => x)
            .join(' '),
        });

        if (response.features.length) {
          const [feature] = response.features;

          return {
            ...newAddress,
            address: feature.properties.name,
            city: feature.properties.city,
            postalCode: feature.properties.postcode,
          };
        }

        return undefined;
      } catch (error) {
        logError(error);

        return undefined;
      }
    });

  const handleCloseSuggestedAddressModal = () =>
    setIsAddressSuggestedOpenModal(false);

  const handleCompareAddress = useCallback(
    (suggestedAddress: any, addressForm: any) => {
      if (suggestedAddress && addressForm) {
        const hasSameAddress =
          suggestedAddress?.address === addressForm?.address;
        const hasSamePostalCode =
          suggestedAddress?.postalCode === addressForm?.postalCode;
        const hasSameCountryCode =
          suggestedAddress?.countryCode === addressForm?.countryCode;
        const hasSameCity = suggestedAddress?.city === addressForm?.city;

        return Boolean(
          hasSameAddress &&
            hasSamePostalCode &&
            hasSameCountryCode &&
            hasSameCity,
        );
      }

      return true;
    },
    [],
  );

  const handleAddressSubmit = async ({
    addressSelected,
  }: {
    addressSelected: AddressInfoDto | undefined;
  }) => {
    const suggestedAddressData = await handleValidateAddress(addressSelected);

    const resultHasSameAddress = handleCompareAddress(
      suggestedAddressData,
      addressSelected,
    );

    setValue('shouldBeChoiceAnAddress', !resultHasSameAddress);
    setValue('addressSuggested', suggestedAddressData as any);

    return resultHasSameAddress;
  };

  return {
    suggestedAddressData,
    isAddressSuggestedOpenModal,
    handleAddressSubmit,
    handleCloseSuggestedAddressModal,
  };
};

export default useSuggestedAddress;
