import { Elements } from '@stripe/react-stripe-js';
import { rgba } from 'polished';
import React from 'react';
import { useTheme } from 'styled-components';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import CheckoutForm from './CheckoutForm/CheckoutForm';
import usePaymentStripe from './PaymentStripe.hooks';

interface PaymentStripeProps {
  onSubmitSuccess: () => void;
  isFormCompleted: boolean;
  invoice: { id: string; priceWithTax: number; currencyTrigram: CURRENCIES };
  claimGroupId: string;
}

const PaymentStripe: React.FC<PaymentStripeProps> = ({
  onSubmitSuccess,
  isFormCompleted,
  invoice,
  claimGroupId,
}) => {
  const theme = useTheme();
  const { stripePromise, paymentIntentClientSecret, isLoading } =
    usePaymentStripe({
      invoiceId: invoice?.id,
      claimGroupId,
      onSubmitSuccess,
    });

  const options = {
    clientSecret: paymentIntentClientSecret,
    appearance: {
      rules: {
        '.Input': {
          padding: '1rem 1.25rem',
          outline: 'none',
          border: '0.07rem solid',
          borderColor: '#C9CED6',
          borderRadius: '0.35rem',
          lineHeight: '1.15',
          boxShadow: 'unset',
          fontFamily: 'inherit',
          fontSize: '14px',
          color: '#343434',
        },
        '.Input:focus': {
          boxShadow: `0px 0px 4px ${rgba(theme.colors.primary, 0.4)}`,
          borderColor: `${theme.colors.primary}`,
        },
        '.Label': {
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '16px',
          color: '#8A94A6',
          margin: '0 0 0.5rem 0',
        },
      },
    },
  };

  if (isLoading || !stripePromise) {
    return <BaseLoader />;
  }

  return (
    <div>
      {options.clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            onSubmitSuccess={onSubmitSuccess}
            isFormCompleted={isFormCompleted}
            invoice={invoice}
          />
        </Elements>
      )}
    </div>
  );
};

export default PaymentStripe;
