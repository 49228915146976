import React, { FC, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { selectLocale } from '../../domains/i18n/selectors';
import useCommonTypedSelector from '../../domains/useCommonTypedSelector';
import {
  defaultLanguage,
  getConfigLanguage,
  IntlGlobalProvider,
} from '../../helpers/intl';
import { useIsSuperAdmin } from '../../hooks';
import { FullScreenLoader } from '../../molecules/FullScreenLoader';

import { fetchDynamicIntl, fetchIntl } from './IntlManager.helpers';

export const IntlManager: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const locale = useCommonTypedSelector(selectLocale);

  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState({});
  const [dynamicMessages, setDynamicMessages] = useState({});

  const config = useMemo(() => getConfigLanguage(locale), [locale]);

  useEffect(() => {
    setIsLoading(true);

    (async function () {
      const response = await fetchIntl(locale);

      if (!response.failure) {
        setMessages(response.value);
      } else {
        setMessages({});

        logError(response, {
          operation: 'Fetch intl',
        });
      }
      setIsLoading(false);
    })();
  }, [locale]);

  useEffect(() => {
    setIsLoading(true);

    (async function () {
      const response = await fetchDynamicIntl(locale);

      if (!response.failure) {
        setDynamicMessages(response.value);
      } else {
        logError(response);
        setDynamicMessages({});

        logError(response, {
          operation: 'Fetch dynamic intl',
        });
      }
      setIsLoading(false);
    })();
  }, [locale]);

  const isSuperAdmin = useIsSuperAdmin();

  if (isLoading) {
    return (
      <FullScreenLoader isOpen size="16px" message="Loading translations..." />
    );
  }

  return (
    <>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={{ ...messages, ...dynamicMessages }}
        formats={config.formats}
        textComponent="span"
        defaultLocale={defaultLanguage}
      >
        <IntlGlobalProvider isSuperAdmin={isSuperAdmin}>
          {children}
        </IntlGlobalProvider>
      </IntlProvider>
    </>
  );
};

export default IntlManager;
