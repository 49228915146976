import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutedStepsOrchestrator } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator';
import { OnSubmit } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';

import { buildFileFollowupUrl } from '../../app/NewLayout/ClassiqueLayout.helpers';

import { STEPS_CONFIG } from './IrshPages.config';
import { IrshStepValues } from './IrshPages.types';

export const IrshPages: FC = () => {
  const navigate = useNavigate();

  const handleOnSubmit: OnSubmit<IrshStepValues> = useCallback(
    ({ values }) => {
      if (!values.fileId) {
        throw new Error('File id is required');
      }

      const fileFollowupUrl = buildFileFollowupUrl({
        fileId: values.fileId,
      });

      navigate(fileFollowupUrl);
    },
    [navigate],
  );

  return (
    <RoutedStepsOrchestrator config={STEPS_CONFIG} onSubmit={handleOnSubmit} />
  );
};
