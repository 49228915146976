import { RoutedStep } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';

import { PROFESSIONAL_STEP_CONFIG } from '../../../app/NewLayout/ProfessionalRoutes.config';
import { buildProfessionalRoute } from '../../../app/NewLayout/ProfessionalRoutes.helpers';
import { ROUTES_PROFESSIONAL } from '../../../app/routes/ProfessionalRoutes';

import ClaimGroupConfirmationPage from './ClaimGroupConfirmationPage/ClaimGroupConfirmationPage';
import ClaimGroupDeliveryPage from './ClaimGroupDeliveryPage/ClaimGroupDeliveryPage';
import ClaimGroupDepositPage from './ClaimGroupDepositPage/ClaimGroupDepositPage';
import ClaimGroupSolutionPage from './ClaimGroupSolutionPage/ClaimGroupSolutionPage';
import { IrshStepValues } from './IrshPages.types';
import { IssuesReasonsPage } from './IssuesReasonsPage/IssuesReasonsPage';
import ProductsToReturnPage from './ProductsToReturnPage/ProductsToReturnPage';

export const STEPS_CONFIG: RoutedStep<IrshStepValues>[] = [
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE,
    route: ROUTES_PROFESSIONAL.ISSUES_REASONS,
    Component: IssuesReasonsPage,
    onNextStep: ({ values }) => {
      if (!values?.orderReference) {
        throw new Error('orderReference is required');
      }

      return buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE,
        payload: {
          orderReference: values?.orderReference,
        },
      });
    },
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE,
    route: ROUTES_PROFESSIONAL.PRODUCTS_TO_RETURN,
    Component: ProductsToReturnPage,
    onPreviousStep: ({ values }) =>
      buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE,
        payload: {
          orderReference: values?.orderReference,
        },
      }),
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      return buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: {
          claimGroupId: values?.claimGroupId,
        },
      });
    },
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
    route: ROUTES_PROFESSIONAL.SOLUTIONS,
    Component: ClaimGroupSolutionPage,
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      const handling = values?.handling;
      const { hasHome, hasDelivery, hasDeposit } = handling;

      if (hasDeposit || hasHome) {
        return buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE,
          payload: { claimGroupId: values?.claimGroupId },
        });
      }

      if (hasDelivery) {
        return buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE,
          payload: { claimGroupId: values?.claimGroupId },
        });
      }

      return buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE,
        payload: { claimGroupId: values?.claimGroupId },
      });
    },
    onPreviousStep: ({ values }) => {
      if (!values?.orderReference) {
        throw new Error('orderReference is required');
      }

      return buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE,
        payload: { orderReference: values?.orderReference },
      });
    },
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE,
    route: ROUTES_PROFESSIONAL.DEPOSIT,
    Component: ClaimGroupDepositPage,
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      return buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: { claimGroupId: values?.claimGroupId },
      });
    },
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      const handling = values?.handling;
      const { hasDelivery } = handling;

      if (hasDelivery) {
        return buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE,
          payload: { claimGroupId: values?.claimGroupId },
        });
      }

      return buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE,
        payload: { claimGroupId: values?.claimGroupId },
      });
    },
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE,
    route: ROUTES_PROFESSIONAL.DELIVERY,
    Component: ClaimGroupDeliveryPage,
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      const handling = values?.handling;
      const { hasHome, hasDeposit } = handling;

      if (hasDeposit || hasHome) {
        return buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE,
          payload: { claimGroupId: values?.claimGroupId },
        });
      }

      return buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: { claimGroupId: values?.claimGroupId },
      });
    },
    onNextStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      return buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE,
        payload: { claimGroupId: values?.claimGroupId },
      });
    },
  },
  {
    name: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE,
    Component: ClaimGroupConfirmationPage,
    route: ROUTES_PROFESSIONAL.CONFIRMATION,
    onPreviousStep: ({ values }) => {
      if (!values?.claimGroupId) {
        throw new Error('claimGroupId is required');
      }

      if (!values?.handling) {
        {
          throw new Error('handling is required');
        }
      }

      const handling = values?.handling;
      const { hasHome, hasDelivery, hasDeposit } = handling;

      if (hasDelivery) {
        return buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE,
          payload: { claimGroupId: values?.claimGroupId },
        });
      }

      if (hasDeposit || hasHome) {
        return buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE,
          payload: { claimGroupId: values?.claimGroupId },
        });
      }

      return buildProfessionalRoute({
        route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
        payload: { claimGroupId: values?.claimGroupId },
      });
    },
  },
];
