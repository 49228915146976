import React from 'react';
import { Row } from 'react-styled-flexboxgrid';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { STEP_CONFIG } from '../../../ClassiqueRoutes.config';
import { PROFESSIONAL_STEP_CONFIG } from '../../../ProfessionalRoutes.config';

import { getStepHeaderSubtitle } from './StepSubtitle.helpers';
import { $StepHeaderDidactic } from './StepSubtitle.styles';

interface StepSubtitleProps {
  stepName: STEP_CONFIG | PROFESSIONAL_STEP_CONFIG;
}

const StepSubtitle: React.FC<StepSubtitleProps> = ({ stepName }) => {
  return (
    <Row start="xs">
      <$StepHeaderDidactic data-testid={`subTitle_${stepName}`}>
        <SafeFormattedMessageWithoutSpread
          message={getStepHeaderSubtitle(stepName)}
        />
      </$StepHeaderDidactic>
    </Row>
  );
};

export default StepSubtitle;
