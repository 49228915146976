import React from 'react';
import { FormProvider } from 'react-hook-form';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { AdditionalInformation } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';

import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { IrshStepValues } from '../IrshPages.types';
import messages from '../NewClaimGroupReasonPage/messages';

import useClaimGroupDocumentRelatedToClaim from './NewClaimGroupDocumentPageRelatedToClaim.hooks';

export const NewClaimGroupDocumentPageRelatedToClaim: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onNextStep, onPreviousStep }) => {
  const {
    neededInformationOnlyFileType,
    onSubmit,
    formContext,
    isLoadingSubmit,
  } = useClaimGroupDocumentRelatedToClaim({
    onNextStep,
  });

  const {
    formState: { isValid, isSubmitting },
  } = formContext;

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <NewStepLayout
          stepName={STEP_CONFIG.CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM}
        >
          <AdditionalInformation
            neededInformation={neededInformationOnlyFileType}
            formContext={formContext}
            prefixFormName="reasonAdditionalInformationDocumentRelatedToClaim"
            isNewDesign
            shouldAllowQrCode={false}
          />
        </NewStepLayout>

        <PageFooterContainer>
          <Button
            type={BUTTON_TYPES.BUTTON}
            secondary
            naked
            onClick={() => {
              onPreviousStep({});
            }}
            icon={<ArrowNakedLeftIcon />}
            position={SUPPORTED_ICON_POSITIONS.LEFT}
            dataTestId="previousButton"
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.previousButton}
            />
          </Button>

          <Button
            primary
            type={BUTTON_TYPES.BUTTON}
            icon={<ArrowNakedRightIcon />}
            position={SUPPORTED_ICON_POSITIONS.RIGHT}
            disabled={!isValid}
            isLoading={isLoadingSubmit || isSubmitting}
            onClick={onSubmit}
            dataTestId="nextButton"
          >
            <SafeFormattedMessageWithoutSpread message={messages.nextButton} />
          </Button>
        </PageFooterContainer>
      </form>
    </FormProvider>
  );
};
