import { useLocation } from 'react-router-dom';

import findRoutesIndex, {
  findSubRoutesIndex,
} from '../helpers/findRoutesIndex';
import { RouteTabConfig } from '../RouteTabs.types';

const useTabAnimation = ({ config }: { config: RouteTabConfig[] }) => {
  const configWithoutHidden = config.filter((c) => !c.isHidden);
  const totalCount = configWithoutHidden.length;
  const location = useLocation();
  const url = location.pathname;

  const currentIndex = findRoutesIndex(configWithoutHidden, url);
  const currentSubTab = configWithoutHidden[currentIndex]?.subTab;
  const currentName = configWithoutHidden[currentIndex]?.name;

  const currentSubTabIndex = findSubRoutesIndex(configWithoutHidden, url);

  const currentSubTabName = currentSubTab
    ? currentSubTab[currentSubTabIndex]?.name
    : undefined;

  return {
    currentIndex,
    totalCount,
    currentName,
    currentSubTabName,
  };
};

export default useTabAnimation;
