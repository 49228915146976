import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

import { IssueSummaryDto } from '../../../../../../../../types/IssueSummaryDto';

export const useGetIssues = ({
  ownerId,
  sellerId,
  onSuccess,
}: {
  ownerId?: string;
  sellerId?: string;
  onSuccess?: (issues: IssueSummaryDto[]) => void;
}) => {
  const { data: issuesSummaries, isLoading } = useQuery(
    ['getIssuesForOwnerQuery', { ownerId, sellerId }],
    async () => {
      if (!ownerId || !sellerId) {
        return undefined;
      }

      const response = await ClaimService.getIssuesForOwnerQuery({
        ownerId,
        sellerId,
      });

      if (response?.failure) {
        return undefined;
      }

      return response.value;
    },
    { staleTime: Infinity, onSuccess },
  );

  return { issuesSummaries, isLoading };
};
