import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ClaimContextState } from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import useGetModelById from 'myaccount/hooks/useGetModelById';
import { useGetClaimContextsByGroupIdQuery } from 'myaccount/view/app/hooks/useGetClaimContextsByGroupIdQuery';
import { useGetCustomerFileFullSummaryQuery } from 'myaccount/view/app/hooks/useGetCustomerFileFullSummaryQuery';
import useGetIssuesByOrder from 'myaccount/view/app/hooks/useGetIssuesByOrder';
import { useGetSolutionTypeQuery } from 'myaccount/view/app/hooks/useGetSolutionTypeQuery';
import {
  buildClaimReasonUrl,
  buildClaimStepUrl,
  buildFileFollowupUrl,
} from 'myaccount/view/app/NewLayout/ClassiqueLayout.helpers';

import { OwnerProductSummaryDto } from '../../HomePage.types';

export const useGetProductsDetails = ({
  product,
}: {
  product: OwnerProductSummaryDto;
}) => {
  const { removeAllNotifications, pushErrors } = useToasts();
  const navigate = useNavigate();
  const { model, isLoading: isModelLoading } = useGetModelById({
    modelId: product?.modelId,
  });
  const { issues, isIssuesLoading } = useGetIssuesByOrder({
    orderId: product.orderId,
  });
  const { productsInfo } = useGetCustomerFileFullSummaryQuery({
    fileId: product.fileId,
  });
  const { solution, isLoading: isLoadingSolution } = useGetSolutionTypeQuery({
    solutionTypeId: productsInfo?.solutionId,
  });
  const { claimContexts, isLoading: isLoadingClaimContexts } =
    useGetClaimContextsByGroupIdQuery({
      claimGroupId: product.claimGroupId,
    });

  const handleCreateClaimWithIssue = useCallback(
    async ({
      ownerId,
      ownerProductIds,
      issueId,
    }: {
      ownerId: string;
      ownerProductIds?: string[];
      issueId: string;
    }) => {
      if (!ownerId || !ownerProductIds || !issueId) {
        return undefined;
      }

      removeAllNotifications();

      const response = await ClaimService.setCreateClaimGroup({
        ownerId,
        ownerProductIds,
        issueId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      const claimGroupId = response.value;

      navigate(buildClaimReasonUrl({ claimGroupId }));

      return claimGroupId;
    },
    [navigate, pushErrors, removeAllNotifications],
  );

  const handleFollowClaim = useCallback(
    ({ fileId }: { fileId: string }) => {
      navigate(buildFileFollowupUrl({ fileId }));
    },
    [navigate],
  );

  const handleFinalizeClaim = useCallback(
    (claimContextsState: ClaimContextState) => {
      if (product.claimGroupId) {
        navigate(
          buildClaimStepUrl({
            claimContextsState,
            claimGroupId: product.claimGroupId,
          }),
        );
      }
    },
    [product.claimGroupId, navigate],
  );

  return {
    productsDetails: {
      ...product,
      ...model,
      solutionName: solution?.name,
    },
    issues,
    claimContexts,
    isLoading:
      isModelLoading ||
      isIssuesLoading ||
      isLoadingSolution ||
      isLoadingClaimContexts,
    handleCreateClaimWithIssue,
    handleFollowClaim,
    handleFinalizeClaim,
  };
};
