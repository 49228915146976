import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

export const USE_GET_SOLUTIONS_BY_CLAIM_GROUP_ID =
  'USE_GET_SOLUTIONS_BY_CLAIM_GROUP_ID';

export const useGetSolutionsByClaim = ({
  claimGroupId,
  suspense = false,
}: {
  claimGroupId?: string;
  suspense: boolean;
}) => {
  const intl = useIntl();

  const {
    data: solutions,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(
    [
      USE_GET_SOLUTIONS_BY_CLAIM_GROUP_ID,
      { claimGroupId, locale: intl.locale },
    ],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getSolutionsByClaimGroup({
        claimGroupId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      suspense,
      staleTime: Infinity,
    },
  );

  return {
    solutions: solutions?.solutions,
    solutionsCount: solutions?.solutions?.length || 0,
    isSolutionsLoading: isLoading || isRefetching,
    refetch,
  };
};
