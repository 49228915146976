import styled from 'styled-components';

export const $CardContentThumbnailWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borders.radius};
  margin-right: 1rem;
  max-width: 40px;
  flex: 0 0 40px;
  height: 40px;
  overflow: hidden;
`;

export const $CardContentThumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  && svg {
    max-width: 36px;
    max-height: 36px;
  }

  && img {
    object-fit: contain;
    max-height: 4.285714rem;
    max-width: 4.285714rem;
  }
`;

export const $CardContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: 0;
`;

export const $CardContentProductName = styled.div`
  color: ${({ theme }) => theme.colors.mainTextColor};
`;

export const $CardContentSerialNumber = styled.div`
  color: ${({ theme }) => theme.colors.tertiary};
  span {
    display: block;
  }

  & > span > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const $CardContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
`;
