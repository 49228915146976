import React, { FunctionComponent } from 'react';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';

import { VERTICAL_MENU_ANIMATION_DURATION } from './Menu.helpers';
import { $MenuContainer, $MenuTransition } from './Menu.styles';
import { MENU_POSITIONS } from './Menu.types';

interface MenuAnimatorProps {
  position?: MENU_POSITIONS | { top: string; left: string };
  dataTestId?: string;
  componentThemeName?: string;
}
const MenuAnimator: FunctionComponent<
  React.PropsWithChildren<MenuAnimatorProps>
> = ({ position, dataTestId, children, componentThemeName }) => {
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$MenuContainer
      animationDuration={VERTICAL_MENU_ANIMATION_DURATION}
      $position={position}
    >
      <$MenuTransition
        $position={position}
        data-testid={dataTestId}
        $componentThemeName={componentThemeName}
        $isNewUiEnabled={isNewUiEnabled}
      >
        {children}
      </$MenuTransition>
    </$MenuContainer>
  );
};

export default MenuAnimator;
