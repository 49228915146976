import { LOCATION_CHANGE } from './actionCreators';

const initialState = {
  location: {
    pathname: '',
  },
};

function onLocationChange(state, { payload: { location } }) {
  return {
    ...state,
    location,
  };
}

export default function mainReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE.BASE: {
      return onLocationChange(state, action);
    }

    default:
      return state;
  }
}
