import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ClaimService } from 'myaccount/api';
import useGetClientInformation from 'myaccount/view/app/hooks/useGetClientInformation';

interface UseGetClaimReasonsArgs {
  issueId: string;
}

const GetClaimReasonsByIssueQuery = 'getClaimReasonsByIssueQuery';

const useGetReasons = ({ issueId }: UseGetClaimReasonsArgs) => {
  const { pushErrors } = useToasts();
  const { clientInformation } = useGetClientInformation();

  const sellerId = clientInformation?.sellerId;

  const { data: reasons, isLoading } = useQuery(
    [GetClaimReasonsByIssueQuery, { issueId, sellerId }],
    async () => {
      if (!sellerId || !issueId) {
        return undefined;
      }

      const response = await ClaimService.getClaimReasonsByIssueQuery({
        issueId,
        sellerId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
    },
  );

  return {
    reasons: reasons || [],
    isLoading,
  };
};

export default useGetReasons;
