import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Origin from '@savgroup-front-common/constants/src/shared/origin';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { ClaimService } from 'myaccount/api';
import { ownerIdSelector } from 'myaccount/domains/Customer/selectors';
import { useGetClientInformation } from 'myaccount/view/app/hooks';

import { IrshStepValues } from '../../../IrshPages.types';

import { useGetBusinessReturn } from './useGetBusinessReturn';

export const useCreateClaimGroupFromBusinessReturn = ({
  onNextStep,
}: {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}) => {
  const { orderReference } = useParams<{ orderReference: string }>();
  const { clientInformation } = useGetClientInformation();
  const ownerId = useSelector(ownerIdSelector);
  const { pushErrors } = useToasts();
  const { order } = useGetBusinessReturn();

  const {
    mutateAsync: handleCreateClaimGroup,
    isLoading: isCreateClaimGroupLoading,
  } = useMutation(['useCreateClaimGroupFromBusinessReturn'], async () => {
    if (!clientInformation?.sellerId || !order || !orderReference) {
      return undefined;
    }

    const response =
      await ClaimService.createClaimGroupContextFromBusinessReturnCommand({
        sellerId: clientInformation?.sellerId,
        orderReference,
        ownerId,
        origin: Origin.MY_ACCOUNT,
      });

    if (response.failure) {
      pushErrors(response.errors);

      return undefined;
    }

    const claimGroupId = response.value;

    onNextStep({
      newValues: {
        claimGroupId,
        order,
      },
    });

    return undefined;
  });

  return {
    handleCreateClaimGroup,
    isCreateClaimGroupLoading,
  };
};
