import styled from 'styled-components';

import { simpleSlideFromBottomAnimation } from '../../animations/simpleAnimation';

export const $ErrorSpacer = styled.div`
  display: block;
  max-width: 39rem;
  width: 100%;
  margin: 1rem auto;
  ${simpleSlideFromBottomAnimation};
  & > svg {
    color: ${({ theme }) => theme.colors.mainTextColor};
  }
`;

export const $ErrorContainer = styled.div<{ $isFull: boolean }>`
  height: ${({ $isFull }) => ($isFull ? '100vh' : null)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  background: ${({ theme }) => theme.systemColors.defaultBackground};
`;
