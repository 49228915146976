import React, { PropsWithChildren } from 'react';
import { Row } from 'react-styled-flexboxgrid';

import { PROFESSIONAL_STEP_CONFIG } from '../ProfessionalRoutes.config';

import { $ColContainer, $StepContainer } from './NewStepLayout.styles';
import StepHeader from './StepHeader/StepHeader';

interface StepLayoutProfessionalProps {
  stepName?: PROFESSIONAL_STEP_CONFIG;
  customSteps?: Record<string, string[]>[];
  isFinalStep?: boolean;
  minHeight?: boolean;
}

export const NewStepLayoutProfessional: React.FC<
  PropsWithChildren<StepLayoutProfessionalProps>
> = ({
  children,
  stepName = undefined,
  customSteps = undefined,
  isFinalStep = false,
  minHeight = undefined,
}) => {
  return (
    <Row center="xs">
      <$ColContainer xs={12} sm={12} enableServicePortal>
        {stepName && (
          <StepHeader
            stepName={stepName}
            isFinalStep={isFinalStep}
            customSteps={customSteps}
          />
        )}

        <$StepContainer $minHeight={minHeight}>{children}</$StepContainer>
      </$ColContainer>
    </Row>
  );
};
