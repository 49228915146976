import React, { FC, ReactElement, useCallback, useRef, useState } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../formatters';
import { ChevronDownIcon } from '../../../protons/icons';
import { $MessageWithIcon } from '../RouteTabs.styles';
import { RouteSubTabConfig } from '../RouteTabs.types';

import { $IconMargin, $RouteSubTab } from './RouteSubTab.styles';
import RouteTabsSubMenu from './RouteTabsSubMenu';

interface RouteSubTabProps {
  subTab: RouteSubTabConfig[];
  isActive: boolean;
  icon?: ReactElement;
  message: MessageType | string;
  currentSubTabName?: string;
}

const RouteSubTab: FC<RouteSubTabProps> = ({
  subTab,
  isActive,
  icon,
  message,
  currentSubTabName,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <$RouteSubTab
      ref={ref}
      $isActive={isActive}
      onClick={() => setIsOpen(!isOpen)}
    >
      {icon && (
        <$MessageWithIcon $isActive={isActive}>
          {icon}
          {SafeFormattedMessage(message)}
          <$IconMargin>
            <ChevronDownIcon />
          </$IconMargin>
        </$MessageWithIcon>
      )}
      {!icon && SafeFormattedMessage(message)}
      <RouteTabsSubMenu
        subTab={subTab}
        wrapperRef={ref}
        onClose={handleClose}
        isOpen={isOpen}
        currentSubTabName={currentSubTabName}
      />
    </$RouteSubTab>
  );
};

export default RouteSubTab;
