import styled from 'styled-components';

import { DefaultStyledProps } from '@savgroup-front-common/types';

import { decelerateTimingFunctionTransition } from '../../../../animations/timingFunction';
import { rem } from '../../../../helpers';
import { getTextColorBasedOnProps } from '../../../../theme/helpers/index';

import {
  colorBasedOnProps,
  colorHoverBasedOnProps,
} from './RadioButton.helpers';

interface RadioInputProps extends DefaultStyledProps {
  danger?: boolean;
  isHighlighted: boolean;
  $isChecked?: boolean;
  $isNewUiEnabled?: boolean;
  $isRequired?: boolean;
}
export const $RadioInput = styled.input<RadioInputProps>`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: ${rem(22)};
  height: ${rem(22)};
  opacity: 0;

  & {
    ~ span {
      transition: color 100ms ease-in-out, border-color 100ms ease-in-out;

      border-color: ${(props) => {
        const { theme, isHighlighted, $isChecked } = props;

        if ($isChecked) {
          return colorHoverBasedOnProps(props);
        }

        if (isHighlighted) {
          return theme.colors.primary;
        }

        return null;
      }};
      color: ${(props) => {
        const { theme, isHighlighted, $isChecked, $isNewUiEnabled } = props;

        if (isHighlighted) {
          return theme.colors.primary;
        }
        if ($isNewUiEnabled && $isChecked) {
          return theme.newUI.defaultColors.text;
        }

        if ($isChecked) {
          return theme.colors.primary;
        }

        return null;
      }};
    }
  }

  &:hover {
    ~ span {
      border-color: ${(props) =>
        props.disabled || props.danger ? null : colorHoverBasedOnProps(props)};
    }
  }

  &:checked {
    ~ span {
      border-color: ${(props) =>
        props.$isNewUiEnabled ? null : colorHoverBasedOnProps(props)};
      color: ${({ theme, $isNewUiEnabled }) =>
        $isNewUiEnabled
          ? theme.newUI.defaultColors.text
          : theme.colors.primary};

      &::before {
        transform: scale(1);
        transform-origin: 50% 50%;
        background-color: ${(props) => colorHoverBasedOnProps(props)};
      }
    }
  }
`;

interface RadioSpanProps extends DefaultStyledProps {
  disabled?: boolean;
  $isChecked?: boolean;
  $hasDescription?: boolean;
  $isNewUiEnabled?: boolean;
}
export const $RadioSpan = styled.span<RadioSpanProps>`
  display: block;
  position: absolute;
  box-sizing: content-box;
  top: ${({ $hasDescription }) => ($hasDescription ? '10px' : 0)};
  left: 0;
  width: ${rem('18px')};
  height: ${rem('18px')};
  border-radius: 50%;
  border-width: ${({ theme }) => theme.borders.thicknessSpecialInput};
  border-color: ${(props) => colorBasedOnProps(props)};
  border-style: solid;
  margin: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '16px' : null)};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition-duration: 0.2s;
  ${decelerateTimingFunctionTransition};

  &::before {
    content: '';
    display: block;
    position: relative;
    border-radius: 50%;
    top: ${rem('4px')};
    left: ${rem('4px')};
    width: ${rem('10px')};
    height: ${rem('10px')};
    background-color: ${(props) =>
      props.$isChecked
        ? colorHoverBasedOnProps(props)
        : colorBasedOnProps(props)};
    transform: scale(
      ${({ disabled, $isChecked }) => (disabled || $isChecked ? 1 : 0)}
    );
    transform-origin: 50% 50%;
    transition-duration: 0.2s;
    ${decelerateTimingFunctionTransition};
  }
`;

interface LabelProps extends DefaultStyledProps {
  $isFluid?: boolean;
  $isChecked?: boolean;
  $isNewUiEnabled?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
}
export const $RadioLabel = styled.label<LabelProps>`
  display: block;
  position: relative;
  padding: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '16px' : null)};
  padding-left: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled ? rem('50px') : rem('30px')};
  line-height: 22px;
  font-weight: ${({ $isChecked }) => ($isChecked ? 'bold' : 'normal')};
  width: ${({ $isFluid }) => ($isFluid ? '100%' : 'fit-content')};
  min-height: ${rem('18px')};

  margin: 0 0 ${rem('12px')} 0;
  color: ${(props) => getTextColorBasedOnProps(props)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  border: ${({ $isNewUiEnabled, theme }) => {
    if ($isNewUiEnabled) {
      return `1px solid ${theme.newUI.variationColors?.light4}`;
    }

    return null;
  }};
  border-radius: ${({ $isNewUiEnabled, theme }) =>
    $isNewUiEnabled ? theme.newUI.borders.bigRadius : null};

  background-color: ${({ $isNewUiEnabled, theme }) => {
    return $isNewUiEnabled ? theme.systemColors.secondaryBackground : null;
  }};

  box-shadow: ${({ $isNewUiEnabled, theme }) =>
    $isNewUiEnabled ? theme.newUI.shadows.level1 : null};
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin: 0;
  }
  transition-duration: 0.2s;
  ${decelerateTimingFunctionTransition};
  &:hover {
    border-color: ${({ $isNewUiEnabled, theme }) =>
      $isNewUiEnabled ? theme.newUI.variationColors?.dark1 : null};
  }
`;

export const $RadioDescription = styled.div<LabelProps>`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  margin-top: -2px;
`;
