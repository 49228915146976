import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { DiagnosticTreeStep } from 'myaccount/types/DiagnosticTree';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/diagnostic/${COMMON_QUERY_PARAMS.TREE_UNIQUE_NAME}/node/${COMMON_QUERY_PARAMS.NODE_UNIQUE_NAME}/response/${COMMON_QUERY_PARAMS.RESPONSE_UNIQUE_NAME}`;

interface HandleDiagnosticResponseCommandArgs {
  claimId: string;
  treeUniqueName: string;
  nodeUniqueName: string;
  responseUniqueName: string;
  force: boolean;
}

interface HandleDiagnosticResponseCommandReturnValues {
  currentNode: DiagnosticTreeStep;
  history: DiagnosticTreeStep[];
}

const handleDiagnosticResponseCommandQuery = async ({
  claimId,
  treeUniqueName,
  nodeUniqueName,
  responseUniqueName,
  force,
}: HandleDiagnosticResponseCommandArgs): Promise<
  | BackResponse<HandleDiagnosticResponseCommandReturnValues>
  | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId)
      .replace(COMMON_QUERY_PARAMS.TREE_UNIQUE_NAME, treeUniqueName)
      .replace(COMMON_QUERY_PARAMS.NODE_UNIQUE_NAME, nodeUniqueName)
      .replace(COMMON_QUERY_PARAMS.RESPONSE_UNIQUE_NAME, responseUniqueName);

    const response = await apiCall<
      BackResponse<HandleDiagnosticResponseCommandReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        force,
      },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default handleDiagnosticResponseCommandQuery;
