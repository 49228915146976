import { useLocation, useNavigate } from 'react-router-dom';

export const useRedirectToNonNewDesignRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname.includes('/new_design')) {
    navigate(location.pathname.replace('/new_design', ''), {
      replace: true,
    });
  }
};
