import styled from 'styled-components';

import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import {
  getCustomComponentProperty,
  rem,
  styledGetCustomComponentProperty,
} from '../../../helpers';

interface $ContentProps {
  $naked: boolean;
  $isList?: boolean;
  $componentThemeName?: string;
}

const $Content = styled.div<$ContentProps>`
  padding: ${({ $naked, $isList }) => {
    if ($isList) {
      return '1rem 1.5rem';
    }

    return `1rem ${$naked ? 0 : '1rem'}`;
  }};

  border-radius: ${({ $componentThemeName, theme }) =>
    `0 0 ${
      getCustomComponentProperty({
        theme,
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
      }) || rem(5)
    } ${
      getCustomComponentProperty({
        theme,
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
      }) || rem(5)
    }`};
  border: ${({ $componentThemeName, theme }) =>
    $componentThemeName
      ? styledGetCustomComponentProperty({
          componentName: $componentThemeName,
          property: SUPPORTED_PROPERTIES.BORDER,
        })
      : `1px solid ${theme.colors.default}}`};

  border-top: 0;
  background-color: ${({ theme }) => theme.systemColors.defaultBackground};
`;

export default $Content;
