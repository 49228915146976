import { darken, rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import {
  DefaultStyledProps,
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { rem, styledGetCustomComponentProperty } from '../../../helpers';
import { getBordersBasedOnProps } from '../../../theme/helpers';
import {
  FIELD_STATUS,
  InputStatus,
} from '../common/helpers/getFinalFieldState.types';

interface ColorBasedOnProps extends DefaultStyledProps {
  $status: InputStatus;
  $isNewUiEnabled?: boolean;
}
const colorBasedOnProps = (props: ColorBasedOnProps) => {
  const { theme, $status, $isNewUiEnabled } = props;

  if ($status === FIELD_STATUS.ERROR) {
    return theme.colors.danger;
  }
  if ($status === FIELD_STATUS.WARNING) {
    return theme.colors.alert;
  }
  if ($status === FIELD_STATUS.SUCCESS) {
    return theme.colors.success;
  }

  if ($isNewUiEnabled) {
    return theme.newUI.inputBorderColors.default;
  }

  return theme.colors.default;
};

const colorFocusBasedOnProps = (props: DefaultStyledProps) => {
  const { theme } = props;

  return theme.colors.primary;
};

interface StyledTextAreaProps extends DefaultStyledProps {
  $status: InputStatus;
  autoHeight?: boolean;
  $isInitialOneLine?: boolean;
  disabled: boolean;
  $isRequired: boolean;
  $isLoading: boolean;
  $hasIcon: boolean;
  $isNewUiEnabled: boolean;
  $isScrollBarActive: boolean;
}

export const $StyledTextArea = styled.textarea<StyledTextAreaProps>`
  outline: none;
  overflow-y: ${({ $isScrollBarActive }) =>
    $isScrollBarActive ? 'auto' : 'hidden'} !important;

  padding-top: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.TEXT_AREA,
    property: SUPPORTED_PROPERTIES.PADDING_TOP,
    fallback: '0.7857142857rem',
  })};
  padding-bottom: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.TEXT_AREA,
    property: SUPPORTED_PROPERTIES.PADDING_BOTTOM,
    fallback: '0.7857142857rem',
  })};

  padding-left: ${({ $hasIcon }) =>
    $hasIcon
      ? rem(35)
      : styledGetCustomComponentProperty({
          componentName: SUPPORTED_COMPONENTS.TEXT_AREA,
          property: SUPPORTED_PROPERTIES.PADDING_LEFT,
          fallback: '1rem',
        })};
  padding-right: ${({ $isLoading }) =>
    $isLoading
      ? rem(35)
      : styledGetCustomComponentProperty({
          componentName: SUPPORTED_COMPONENTS.TEXT_AREA,
          property: SUPPORTED_PROPERTIES.PADDING_RIGHT,
          fallback: '1rem',
        })};
  border-style: solid;
  border-radius: ${(props) =>
    props.$isNewUiEnabled
      ? props.theme.newUI.borders.mediumRadius
      : getBordersBasedOnProps(props).radius};
  border-width: ${(props) => getBordersBasedOnProps(props).thickness};
  border-color: ${(props) => colorBasedOnProps(props)};
  width: 100%;
  height: ${({ $isInitialOneLine }) => {
    return $isInitialOneLine ? rem(41) : null;
  }};
  min-height: ${({ $isInitialOneLine }) =>
    $isInitialOneLine
      ? styledGetCustomComponentProperty({
          componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
          property: SUPPORTED_PROPERTIES.HEIGHT,
        })
      : rem(128)};
  max-height: ${rem(164)};
  resize: none;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};

  &:focus,
  &&&:focus,
  &:hover {
    border-color: ${(props) =>
      props.disabled || props.$status === FIELD_STATUS.ERROR
        ? null
        : colorFocusBasedOnProps(props)};
    box-shadow: ${({ theme }) =>
      `0px 0px 4px ${rgba(theme.colors.primary, 0.4)}`};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.paragraphTextColor};
    line-height: 12px;
  }

  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.disabled : theme.systemColors.defaultBackground};
  color: ${({ disabled, theme }) =>
    disabled ? darken(0.3, theme.colors.default) : theme.colors.mainTextColor};
  resize: ${({ autoHeight }) => (autoHeight ? 'none' : null)};
`;

export const $TextareaContainer = styled.div`
  position: relative;
  width: 100%;
  line-height: 0;
`;

export const $IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: ${rem(10)};
  transform: translateY(-50%);
  height: ${rem(20)};
`;

export const $CustomIcon = styled(({ icon, ...props }) =>
  React.cloneElement(icon, props),
)``;

export const $TextAreaFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const $RemainingCharactersStyled = styled.div`
  margin: 0 0.5rem;
`;

export const $SuffixBottomButton = styled.div`
  display: flex;
  height: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: ${rem(1)};
`;
