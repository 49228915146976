export enum STEP_CONFIG {
  ISSUES = 'Issues',
  CHOOSE_PRODUCTS = 'ChooseProduct',
  CLAIM_GROUP_REASON = 'ClaimGroupReason',
  CLAIM_GROUP_DIAGNOSTIC_TREE = 'ClaimGroupDiagnosticTree',

  CLAIM_GROUP_PRODUCT_INFO = 'ClaimGroupProductInfo',
  CLAIM_GROUP_DOCUMENT_RELATED_TO_CLAIM = 'ClaimGroupDocumentRelatedToClaim',
  CLAIM_GROUP_DOCUMENT_RELATED_TO_PRODUCT = 'ClaimGroupDocumentRelatedToProduct',
  CLAIM_GROUP_SOLUTION = 'ClaimGroupSolution',

  CLAIM_GROUP_DEPOSIT = 'ClaimGroupDeposit',
  CLAIM_GROUP_DELIVERY = 'ClaimGroupDelivery',
  CLAIM_GROUP_CONFIRMATION = 'ClaimGroupConfirmation',
}

export const stepsConfig: any = [];
