import React, { Fragment, FunctionComponent, MouseEventHandler } from 'react';

import { SafeFormattedMessage } from '../../formatters';
import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';
import { TabScroller } from '../../protons/TabScroller';

import useTabAnimation from './hooks/useTabAnimation';
import RouteSubTab from './RouteSubTab/RouteSubTab';
import { $MessageWithIcon } from './RouteTabs.styles';
import { RouteTabConfig } from './RouteTabs.types';
import RouteTabsLink from './RouteTabsLink';

interface RouteTabsNavProps {
  config: RouteTabConfig[];
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  dataTestId?: string;
  level?: 1 | 2;
  fluid?: boolean;
  hasBottomBorder?: boolean;
  hasOverflow?: boolean;
  componentThemeName?: string;
  hideButton?: boolean;
}

const RouteTabsNav: FunctionComponent<RouteTabsNavProps> = ({
  config,
  onClick,
  dataTestId,
  level = 1,
  fluid = false,
  hasBottomBorder = false,
  hasOverflow,
  componentThemeName,
  hideButton = false,
}) => {
  const { totalCount, currentIndex, currentName, currentSubTabName } =
    useTabAnimation({ config });

  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <TabScroller
      dataTestId={`${dataTestId}__selected__${currentName || ''}`}
      totalCount={totalCount}
      currentIndex={currentIndex}
      fluid={fluid}
      hasBottomBorder={hasBottomBorder}
      hasOverflow={hasOverflow}
      componentThemeName={componentThemeName}
      hideButton={hideButton}
      isNewUiEnabled={isNewUiEnabled}
    >
      {config.map(
        ({ name, message, url, count = 0, isHidden, icon, subTab }) => {
          return (
            <Fragment key={url ?? name}>
              {!subTab && (
                <RouteTabsLink
                  url={url}
                  count={count}
                  dataTestId={`${dataTestId}__linkTab__${name}`}
                  onClick={onClick}
                  level={level}
                  isHidden={isHidden}
                  isNewUiEnabled={isNewUiEnabled}
                >
                  {icon && (
                    <$MessageWithIcon $isActive={name === currentName}>
                      {icon}
                      {SafeFormattedMessage(message)}
                    </$MessageWithIcon>
                  )}
                  {!icon && SafeFormattedMessage(message)}
                </RouteTabsLink>
              )}

              {subTab && (
                <RouteSubTab
                  subTab={subTab}
                  isActive={name === currentName}
                  message={message}
                  icon={icon}
                  currentSubTabName={currentSubTabName}
                />
              )}
            </Fragment>
          );
        },
      )}
    </TabScroller>
  );
};

export default RouteTabsNav;
