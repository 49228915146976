import React, { FunctionComponent } from 'react';

import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { AuthConfiguration } from 'myaccount/configuration';
import { FINAL_ROUTES } from 'myaccount/view/app';
import BanneredLayout from 'myaccount/view/app/NewLayout/BanneredLayout/BanneredLayout';
import { ProductsInfo } from 'myaccount/view/components/ProductsInfo';

import messages from './messages';
import { useOrdersPage } from './OrdersPage.hooks';
import { $LayoutGrid } from './OrdersPage.styles';

const OrdersPages: FunctionComponent = () => {
  const { productsInfoAndHistoryList, handleShowProduct } = useOrdersPage();

  return (
    <>
      <NeedHelp clientId={AuthConfiguration.clientId} />
      <BanneredLayout>
        <BanneredLayout.BannerTitle
          breadcrumbs={[
            {
              label: messages.breadcrumbMyRequests,
              route: FINAL_ROUTES.PRODUCTS,
            },
          ]}
          title={messages.bannerTitle}
          description={messages.bannerDescription}
          actions={[
            {
              label: messages.actionNewRequest,
              route: FINAL_ROUTES.ISSUES,
            },
          ]}
        />
        <BanneredLayout.Content>
          <$LayoutGrid>
            {productsInfoAndHistoryList?.map(({ productsInfo, histories }) => {
              if (productsInfo) {
                return (
                  <ProductsInfo
                    key={productsInfo[0]?.fileReference}
                    productsInfo={productsInfo}
                    histories={histories}
                    hasHistory
                    onClick={() =>
                      handleShowProduct({ fileId: productsInfo[0].fileId })
                    }
                  />
                );
              }

              return <></>;
            })}
          </$LayoutGrid>
        </BanneredLayout.Content>
      </BanneredLayout>
    </>
  );
};

OrdersPages.displayName = 'OrdersPages';

export default OrdersPages;
