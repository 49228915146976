import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

export const GetHandlingByClaimGroup = 'getHandlingByClaimGroup';

export const useGetHandlingByClaimGroup = ({
  claimGroupId,
}: {
  claimGroupId?: string;
}) => {
  const { data: handling, isLoading } = useQuery(
    [GetHandlingByClaimGroup],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getHandlingByClaimGroup({
        claimGroupId,
      });

      if (!response || response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
    },
  );

  return {
    handling,
    isLoading,
  };
};
