import React, { FC } from 'react';

import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { StepsOrchestrator } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import { IrshStepValues } from '../IrshPages.types';

import { useIssuesReasonsPage } from './IssuesReasonsPage.hooks';
import {
  ISSUES_REASONS_STEP_NAMES,
  ISSUES_REASONS_STEPS,
} from './IssuesReasonsPage.steps';
import { IssuesReasonsPageSubmitValues } from './IssuesReasonsPage.types';

export const IssuesReasonsPage: FC<RoutedStepProps<IrshStepValues>> = ({
  onNextStep,
}) => {
  const { handleSubmit, order, isLoading } = useIssuesReasonsPage({
    onNextStep,
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <StepsOrchestrator<IssuesReasonsPageSubmitValues>
      initialStep={ISSUES_REASONS_STEP_NAMES.SELECT_ISSUE}
      config={ISSUES_REASONS_STEPS}
      onSubmit={handleSubmit}
      withWizard={false}
      initialValues={{
        order,
      }}
    />
  );
};
