import { APIConfiguration } from '@savgroup-front-common/configuration';
import { CommonAttachmentService } from '@savgroup-front-common/core/src/api';
import { HTMLFileInputElement } from '@savgroup-front-common/types';
import { AdditionalInformation } from 'myaccount/types';

export const uploadAdditionalInformationFilesToClaim = async ({
  neededInformations,
  reasonAdditionalInformationDocument,
  claimId,
}: {
  neededInformations?: ({
    internalId: string;
    fileUploadEndpoints: Record<string, { uploadEndpoint: string }>;
  } & AdditionalInformation)[];
  reasonAdditionalInformationDocument?: Record<string, unknown>;
  claimId: string;
}) => {
  if (!neededInformations) {
    return undefined;
  }

  const neededInformationParams = neededInformations
    .filter(
      (neededInformation) =>
        reasonAdditionalInformationDocument &&
        reasonAdditionalInformationDocument[neededInformation.internalId] &&
        (
          reasonAdditionalInformationDocument[
            neededInformation.internalId
          ] as HTMLFileInputElement
        ).value,
    )
    .map((neededInformation) => {
      if (
        !reasonAdditionalInformationDocument ||
        !neededInformation.fileUploadEndpoints
      ) {
        return undefined;
      }

      return {
        id: neededInformation.id,
        file: (
          reasonAdditionalInformationDocument[
            neededInformation.internalId
          ] as HTMLFileInputElement
        ).value,
        fileUploadUrl: neededInformation.fileUploadEndpoints[claimId],
      };
    });

  const responses = [];

  for (const neededInformationParam of neededInformationParams) {
    if (neededInformationParam) {
      const response = await CommonAttachmentService.uploadAttachements<string>(
        {
          files: [neededInformationParam.file] as unknown as File[],
          endpoint: neededInformationParam.fileUploadUrl.uploadEndpoint,
        },
      );

      responses.push(response);
    }
  }

  return responses;
};

export const downloadFile = async (url: string, name: string) => {
  const imageUrl = `${url.replace('ReverseDocumentService/', '')}`;
  const response = await fetch(`${APIConfiguration.document}${imageUrl}`);
  const blob = await response.blob();
  const file = new File([blob], name, {
    type: blob.type,
  });

  return file;
};
