import React, { FC, useContext } from 'react';
import { useTheme } from 'styled-components';

import { RouteTabs } from '@savgroup-front-common/core/src/atoms/RouteTabs';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';
import BanneredLayout from 'myaccount/view/app/NewLayout/BanneredLayout/BanneredLayout';
import { InitContext } from 'myaccount/view/app/NewLayout/InitProvider/InitProvider.context';
import { PROFESSIONAL_STEP_CONFIG } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';

import { $HomeContentWrapper } from './HomePageProfessional.styles';
import useHomeTabs from './hooks/useHomeTabs';
import messages from './messages';

const HomePageProfessional: FC = () => {
  const theme = useTheme();
  const { login, openedFiles, closedFiles } = useContext(InitContext);
  const { tabsConfig } = useHomeTabs({ openedFiles, closedFiles });

  return (
    <BanneredLayout isHomePage>
      <BanneredLayout.BannerTitle
        title={{
          ...messages.bannerTitle,
          values: { userName: login?.firstName || '' },
        }}
        description={messages.bannerDescription}
        actions={[
          {
            label: messages.createFileButton,
            route: buildProfessionalRoute({
              route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE,
            }),
            icon: <MyAccountIcon icon={ICONS_TYPE.CONSUMABLE_ICON} />,
            backgroundColor: theme.colors.primary,
          },
        ]}
      />

      <BanneredLayout.Content hasBannerUrl>
        <$HomeContentWrapper>
          <RouteTabs
            config={tabsConfig.map((tab) => ({
              ...tab,
              icon: <MyAccountIcon icon={tab.icon as ICONS_TYPE} />,
            }))}
            hasBottomBorder
            hideButton
            level={2}
          />
        </$HomeContentWrapper>
      </BanneredLayout.Content>
    </BanneredLayout>
  );
};

export default HomePageProfessional;
