import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { DocumentData } from '@savgroup-front-common/core/src/formatters/getDocumentNameTranslated';
import { globalMessages, intl } from '@savgroup-front-common/core/src/helpers';
import { MessageType } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import messages from './messages';

export const getDocumentInfo = ({ document }: { document: DocumentData }) => {
  const documentMessages = globalMessages.documentMessages as Record<
    string,
    MessageType
  >;

  const label = documentMessages[document.documentName]
    ? documentMessages[document.documentName]
    : document.documentName;

  const billingLabel = document?.additionalInfo
    ? `${safeFormattedIntlString(documentMessages[document.documentName], {
        sourceAndDestination: '',
      })} ${document.additionalInfo}`
    : `${safeFormattedIntlString(documentMessages[document.documentName], {
        sourceAndDestination: '',
      })}`;

  switch (document.type) {
    case DOCUMENT_TYPES.BILLING:
      return {
        label: billingLabel,
        icon: ICONS_TYPE.NEWSPAPER_ICON,
        url: document.url,
        downloadType: DOCUMENT_TYPES.RETURN_VOUCHER,
        description: messages.dateDocument,
        date: intl?.formatDate(document.uploadDateUtc, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
      };

    case DOCUMENT_TYPES.SHIPPING_LABEL:
      return {
        label: safeFormattedIntlString(messages.ShippingLabel, {
          sourceAndDestination: '',
        }),
        icon: ICONS_TYPE.SCAN_BARCODE_ICON,
        fileId: document.fileId,
        moduleDefinitionId: document.moduleDefinitionId,
        moduleInstanceId: document.moduleInstanceId,
        downloadType: DOCUMENT_TYPES.SHIPPING_LABEL,
        description: messages.ShippingLabelDescription,
      };

    case DOCUMENT_TYPES.RETURN_VOUCHER:
      return {
        label,
        icon: ICONS_TYPE.NEWSPAPER_ICON,
        url: document.url,
        downloadType: DOCUMENT_TYPES.RETURN_VOUCHER,
        description: messages.ReturnVoucherDescription,
      };

    case DOCUMENT_TYPES.INTERVENTION_REPORT:
      return {
        label,
        icon: ICONS_TYPE.TOOLS_ICON,
        url: document.url,
        downloadType: DOCUMENT_TYPES.RETURN_VOUCHER,
        description: messages.dateDocument,
        date: intl?.formatDate(document.uploadDateUtc, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
      };

    case DOCUMENT_TYPES.IMAGE:
      return {
        label: document.originalFileName || DOCUMENT_TYPES.IMAGE,
        icon: ICONS_TYPE.MEDIA_IMAGE_ICON,
        url: document.url,
        downloadType: DOCUMENT_TYPES.IMAGE,
        description: messages.dateDocument,
        date: intl?.formatDate(document.uploadDateUtc, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
      };

    case DOCUMENT_TYPES.ADDITIONAL_INFORMATION_DOCUMENTS:
      return {
        label: DOCUMENT_TYPES.IMAGE,
        icon: ICONS_TYPE.MEDIA_IMAGE_ICON,
        url: document.url,
        downloadType: DOCUMENT_TYPES.ADDITIONAL_INFORMATION_DOCUMENTS,
        description: messages.dateDocument,
        date: intl?.formatDate(document.uploadDateUtc, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
      };

    default:
      return {
        label,
        icon: ICONS_TYPE.NEWSPAPER_ICON,
        url: document.url,
        downloadType: DOCUMENT_TYPES.RETURN_VOUCHER,
        description: messages.dateDocument,
        date: intl?.formatDate(document.uploadDateUtc, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
      };
  }
};
