import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { ClaimService } from 'myaccount/api';

import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { useInitializeClaimGroupId } from '../hooks/useInitializeClaimGroupId';
import { IrshStepValues } from '../IrshPages.types';

export const GET_START_OR_CONTINUE_DIAGNOSTIC_TREE =
  'getStartOrContinueDiagnosticTree';

interface UseClaimGroupDiagnosticTreePageArgs {
  diagnosticTreeUniqueName: string;
}

const useClaimGroupDiagnosticTreePage = ({
  diagnosticTreeUniqueName,
}: UseClaimGroupDiagnosticTreePageArgs) => {
  const { removeAllNotifications, pushErrors } = useToasts();

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();

  const claimGroupId = values?.claimGroupId;

  useInitializeClaimGroupId();

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const claimId = claims?.at(0)?.claimId || '';

  const { data: startOrContinueDiagnosticTreeQueryReturnValues } = useQuery(
    [
      GET_START_OR_CONTINUE_DIAGNOSTIC_TREE,
      { claimId, diagnosticTreeUniqueName },
    ],
    async () => {
      if (!claimId) {
        return undefined;
      }

      removeAllNotifications();

      const response = await ClaimService.startOrContinueDiagnosticTreeQuery({
        claimId,
        treeUniqueName: diagnosticTreeUniqueName,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      suspense: true,
      keepPreviousData: true,
    },
  );

  return {
    startOrContinueDiagnosticTreeQueryReturnValues,
  };
};

export default useClaimGroupDiagnosticTreePage;
