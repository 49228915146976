import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { formatPhoneNumber } from '@savgroup-front-common/core/src/formatters';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { ClaimService } from 'myaccount/api';
import { useGetSellerConfiguration } from 'myaccount/view/app/hooks';
import { useCreateInvoiceWithClaimGroup } from 'myaccount/view/app/hooks/useCreateInvoiceWithClaimGroup';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import useGetClientInformation from 'myaccount/view/app/hooks/useGetClientInformation';
import useGetOrigin from 'myaccount/view/app/hooks/useGetOrigin';
import { useLayout } from 'myaccount/view/app/NewLayout/NewLayout.hooks';

import { IrshStepValues } from '../../IrshPages.types';

import claimGroupConfirmationSchema from './ClaimGroupConfirmation.schema';
import { ClaimGroupConfirmationValues } from './ClaimGroupConfirmation.types';

export const useClaimGroupConfirmation = ({
  onNextStep,
}: {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}) => {
  const { origin } = useGetOrigin();
  const { removeAllNotifications, pushErrors } = useToasts();
  const { login } = useLayout();

  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const { clientInformation } = useGetClientInformation();
  const { claimGroupConfirmation, isLoading: isGetConfirmationLoading } =
    useGetClaimGroupConfirmation({
      claimGroupId,
    });
  const { sellerConfiguration, isLoading: isGetSellerConfigurationLoading } =
    useGetSellerConfiguration({ sellerId: clientInformation?.sellerId });

  const { isLoading: isCheckInvoiceLoading } = useCreateInvoiceWithClaimGroup({
    claimGroupId,
  });

  const agreementUrl =
    sellerConfiguration?.cgvUrl ||
    `${APIConfiguration.catalogCDN}miscellaneous/CGU%20Revers.io%20-%20Client%20final_fr.pdf`;

  const formContext = useForm<ClaimGroupConfirmationValues>({
    resolver: yupResolver(claimGroupConfirmationSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      phone:
        formatPhoneNumber(
          login.phone || login?.ownerAddress?.phone,
          login.countryCode,
        ) || '',
      email: login.email,
    },
  });

  const { handleSubmit } = formContext;

  const {
    mutateAsync: handleConfirmationSubmit,
    isLoading: isLoadingConformationSubmit,
  } = useMutation(
    async ({
      claimGroupId,
      payload,
    }: {
      claimGroupId?: string;
      payload: {
        phoneNumber?: string;
        mail?: string;
        swornStatement?: boolean;
        generalSalesCondition?: boolean;
        iMEI?: string;
        devicePassword?: string;
        serialNumber?: string;
        invoiceId?: string;
        sourceTokenId?: string;
        issueDate?: Date;
        origin?: string;
        ticketId?: string;
      };
    }) => {
      removeAllNotifications();
      if (!claimGroupId) {
        return undefined;
      }

      const responseConfirmation = await ClaimService.setClaimConfirmation({
        claimGroupId,
        payload,
      });

      if (responseConfirmation.failure) {
        pushErrors(responseConfirmation.errors);

        return undefined;
      }

      return responseConfirmation;
    },
  );

  const onSubmit = handleSubmit(async ({ email, phone }) => {
    if (claimGroupId) {
      const payload = {
        phoneNumber: phone,
        mail: email,
        swornStatement: undefined,
        generalSalesCondition: true,
        iMEI: '',
        serialNumber: '',
        devicePassword: '',
        invoiceId: undefined,
        sourceTokenId: '',
        issueDate: new Date(),
        origin,
        ticketId: undefined,
      };

      const responseSetConfirmation = await handleConfirmationSubmit({
        claimGroupId,
        payload,
      });

      if (!responseSetConfirmation) {
        return undefined;
      }

      const responseGetConfirmation =
        await ClaimService.getClaimGroupConfirmationQuery({
          claimGroupId,
        });

      if (responseGetConfirmation.failure) {
        return undefined;
      }

      const { fileId } = responseGetConfirmation.value;

      if (fileId) {
        onNextStep({
          newValues: {
            fileId,
          },
        });
      }

      return undefined;
    }

    return undefined;
  });

  const isLoading =
    isCheckInvoiceLoading ||
    isGetConfirmationLoading ||
    isGetSellerConfigurationLoading;

  return {
    claimGroupConfirmation,
    formContext,
    onSubmit,
    agreementUrl,
    isLoading,
    isLoadingSubmit: isLoadingConformationSubmit,
  };
};
