import React, { useState } from 'react';

import { ICONS_TYPE } from '../../../../types';
import { MyAccountIcon } from '../../protons/IconsNewDesign';

import { $ImageCardStyled, $ProductImage } from './ProductImage.styles';

interface ProductImageProps {
  title?: string;
  pictureUrl?: string;
  width?: string;
  dataTestId?: string;
}

const ProductImage: React.FunctionComponent<
  React.PropsWithChildren<ProductImageProps>
> = ({ title, pictureUrl, children, width = '40px', dataTestId }) => {
  const [imgErrorUrl, setImgErrorUrl] = useState(false);

  return (
    <$ImageCardStyled
      $imgErrorUrl={imgErrorUrl}
      title={title}
      $minWidth={width}
      data-testid={dataTestId}
    >
      {pictureUrl && !imgErrorUrl && (
        <$ProductImage
          src={pictureUrl}
          alt="pictureUrl"
          onError={() => setImgErrorUrl(true)}
        />
      )}

      {(imgErrorUrl || !pictureUrl) && (
        <MyAccountIcon icon={ICONS_TYPE.BOX_ICON} />
      )}

      {children}
    </$ImageCardStyled>
  );
};

export default ProductImage;
