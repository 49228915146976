import { darken, lighten, tint } from 'polished';
import styled from 'styled-components';

import { simpleSlideFromTopAnimation } from '../../../animations/simpleAnimation';

export const $Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  border: none;
  box-shadow: none;
  width: 100%;

  & .react-datepicker__triangle {
    display: none;
  }
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container {
    display: block;
    width: 100%;
  }
  & .react-datepicker-popper {
    z-index: 2 !important;
    transform: translate(0, 70px) !important;
  }

  & .react-datepicker {
    font-family: Roboto;
    border: none;
    margin-bottom: 0;
    border: 1px solid ${({ theme }) => theme.colors.default};
    ${({ theme }) => theme.shadows.elevation__1};
    border-radius: ${({ theme }) => theme.borders.radius};
    ${simpleSlideFromTopAnimation};
      background-color: ${({ theme }) => theme.systemColors.defaultBackground};
      color: ${({ theme }) => theme.colors.mainTextColor};
  }

  && .react-datepicker__day {
    color: ${({ theme }) => theme.colors.mainTextColor};
    font-size: ${({ theme }) => theme.fonts.size.small};
    font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
    margin: 0;
    height: 35px;
    width: 35px;
    line-height: 35px;
    border-radius: 0;

    user-select: none;

    &--disabled {
      color: ${({ theme }) => theme.colors.disabled};
    }
  }
    && .react-datepicker__day:not([aria-disabled=true]):hover {
        background-color: ${({ theme }) => darken(0.05, theme.colors.primary)};
        color: ${({ theme }) => theme.colors.white};
    };

    & .react-datepicker__month-select, & .react-datepicker__year-select {
        background-color: ${({ theme }) =>
          theme.systemColors.defaultBackground};
        color: ${({ theme }) => theme.colors.mainTextColor};
        }
    
  & .react-datepicker__header {
    padding-top: 1.5rem;
    background-color: unset;
    border-bottom: unset;
  }
  & .react-datepicker__day-names {
    background-color: transparent;
  }
  & .react-datepicker__day-name {
    user-select: none;
    color: ${({ theme }) => theme.colors.tertiary};
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.fonts.size.small};
    font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
    margin: 9px 7px;
  }
  & .react-datepicker__current-month {
    color: ${({ theme }) => theme.colors.mainTextColor};
    margin-bottom: 12px;
    font-size: ${({ theme }) => theme.fonts.size.small};
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }
  & .react-datepicker__month {
    margin-top: 0;
  }
  & .react-datepicker__navigation {
    top: 1rem;

    border-radius: ${({ theme }) => theme.borders.radius};

    &:hover,
    &:focus {
      background-color: ${({ theme }) => tint(0.8, theme.colors.primary)};
      outline: none;
    }
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    background-color: ${({ theme }) =>
      `1px solid ${theme.systemColors.defaultBackground}`};
    border: ${({ theme }) =>
      `1px solid ${theme.newUI.variationColors?.light4}`};
    border-radius: ${({ theme }) => theme.borders.radius};
    padding: 5px;
    color: ${({ theme }) => `1px solid ${theme.newUI.defaultColors.text}`};
    font-size: ${({ theme }) => theme.fonts.size.small};
  }

  .react-datepicker__month-select:hover,
  .react-datepicker__year-select:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }

    .react-datepicker__close-icon {
        border-radius: 50%;
    }
  .react-datepicker__close-icon::after {
    background: ${({ theme }) => theme.systemColors.defaultBackground};
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.5rem;
      line-height: 1rem;
  }
  .react-datepicker__close-icon:hover::after {
    color: ${({ theme }) => darken(0.05, theme.colors.primary)};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => lighten(0.3, theme.colors.primary)};
  }

  && .react-datepicker__day--selecting-range-start,
  && .react-datepicker__day--selecting-range-end,
  && .react-datepicker__day--range-start,
  && .react-datepicker__day--range-end, 
  && .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.colors.primary};
    font-size: 1.5rem;
  }
  .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon--next::before {
    top: 35% !important;
    left: -5px !important;
  }

  .react-datepicker__day--outside-month,
  .react-datepicker__day--outside-month:hover {
    visibility: hidden;
    cursor: default;
  }

  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__day--selected:not([aria-disabled='true']):hover,
  .react-datepicker__day--in-selecting-range:not([aria-disabled='true']):hover,
  .react-datepicker__day--in-range:not([aria-disabled='true']):hover,
  .react-datepicker__day--keyboard-selected:not([aria-disabled='true']):hover {
    border-radius: 0;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary)}};
  }

`;
