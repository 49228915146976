import React, { FunctionComponent } from 'react';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';

import { $Didactic } from './Didactic.styles';

interface DidacticProps {
  dataTestId?: string;
  withBottomMargin?: boolean;
}

const Didactic: FunctionComponent<React.PropsWithChildren<DidacticProps>> = ({
  dataTestId,
  children,
  withBottomMargin,
}) => {
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$Didactic
      $withBottomMargin={withBottomMargin}
      data-testid={dataTestId}
      $isNewUiEnabled={isNewUiEnabled}
    >
      {children}
    </$Didactic>
  );
};

export default Didactic;
