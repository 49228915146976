import React, { PropsWithChildren } from 'react';
import { Row } from 'react-styled-flexboxgrid';

import { STEP_CONFIG } from '../ClassiqueRoutes.config';
import { PROFESSIONAL_STEP_CONFIG } from '../ProfessionalRoutes.config';

import { $ColContainer, $StepContainer } from './NewStepLayout.styles';
import StepHeader from './StepHeader/StepHeader';

interface StepLayoutProps {
  isFinalStep?: boolean;
  minHeight?: boolean;
  isStepEmpty?: boolean;
  stepName?: STEP_CONFIG | PROFESSIONAL_STEP_CONFIG;
  withoutMarginBottom?: boolean;
}

export const NewStepLayout: React.FC<PropsWithChildren<StepLayoutProps>> = ({
  children,
  isFinalStep = false,
  minHeight = undefined,
  isStepEmpty = false,
  stepName,
  withoutMarginBottom = false,
}) => {
  return (
    <Row center="xs">
      <$ColContainer xs={12} sm={12} $withoutMarginBottom={withoutMarginBottom}>
        {stepName && (
          <StepHeader
            stepName={stepName}
            isFinalStep={isFinalStep}
            isStepEmpty={isStepEmpty}
          />
        )}

        <$StepContainer $minHeight={minHeight}>{children}</$StepContainer>
      </$ColContainer>
    </Row>
  );
};
