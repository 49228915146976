import { PaylineWidget } from 'react-payline';
import styled from 'styled-components';

import { getCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

export const $PaylineWidget = styled(PaylineWidget)`
  &&&& .pl-paymentMethod-view {
    padding: 0;
  }

  &&&& .pl-pmContainer.pl-cb.pl-odd.pl-active,
  &&&& .pl-container-view {
    border: none !important;
    margin: 0;
  }

  &&&& .pl-card-logos-container {
    display: none !important;
  }

  &&&& .pl-spinner {
    margin: 0;
  }

  &&&& .pl-paymentMethodLayout-view .pl-pmContainer .pl-input {
    border: 1px solid ${({ theme }) => theme.colors.default} !important;
    border-radius: ${({ theme }) => theme.newUI.borders.mediumRadius};
  }
  &&&& .pl-paymentMethodLayout-view .pl-pmContainer .pl-input:focus,
  &&&&& .pl-input.pl-iframe-focus {
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
    border-radius: ${({ theme }) => theme.newUI.borders.mediumRadius};
    box-shadow: none;
  }

  &&&& .pl-pay-btn,
  &&&& button {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) =>
      getCustomComponentProperty({
        theme,
        componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
        property: 'borderRadius',
      })};
    line-height: 1rem;
    font-size: ${({ theme }) =>
      getCustomComponentProperty({
        theme,
        componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
        property: 'fontSize',
      })};
    font-weight: ${({ theme }) =>
      getCustomComponentProperty({
        theme,
        componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
        property: 'fontWeight',
      })};
    height: ${({ theme }) =>
      getCustomComponentProperty({
        theme,
        componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
        property: 'height',
      })};
  }

  &&&& .pl-pmContainer.pl-cb.pl-odd.pl-active {
    background: transparent !important;
    padding: 0;
  }
`;

export const $Total = styled.div`
  color: ${({ theme }) => theme.colors.mainTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;
