import { useParams } from 'react-router-dom';

import {
  PAYMENT_BEHAVIOUR,
  SPECIFIC_ERROR_CODES,
} from '@savgroup-front-common/constants';
import { useGetSellerConfiguration } from 'myaccount/view/app/hooks';
import { useCreateInvoiceWithClaimGroup } from 'myaccount/view/app/hooks/useCreateInvoiceWithClaimGroup';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import useGetClientInformation from 'myaccount/view/app/hooks/useGetClientInformation';

const useClaimGroupConfirmationPage = () => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const { clientInformation } = useGetClientInformation();
  const { claimGroupConfirmation, isLoading: isGetConfirmationLoading } =
    useGetClaimGroupConfirmation({
      claimGroupId,
    });
  const { isLoading: isGetSellerConfigurationLoading } =
    useGetSellerConfiguration({ sellerId: clientInformation?.sellerId });

  const { errors, isLoading: isCheckInvoiceLoading } =
    useCreateInvoiceWithClaimGroup({ claimGroupId });
  const isInvoiceAlreadyPaid = errors?.some(
    (error) => error.code === SPECIFIC_ERROR_CODES.INVOICE_ALREADY_PAID,
  );

  const paymentRequired =
    !isInvoiceAlreadyPaid &&
    claimGroupConfirmation?.paymentBehavior ===
      PAYMENT_BEHAVIOUR.PAYMENT_REQUIRED;

  const isLoading =
    isCheckInvoiceLoading ||
    isGetConfirmationLoading ||
    isGetSellerConfigurationLoading;

  return {
    isLoading,
    paymentRequired,
  };
};

export default useClaimGroupConfirmationPage;
