import { yupResolver } from '@hookform/resolvers/yup';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  NOTIFICATION_TYPES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { buildNotification } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { useConfirmPaymentIntent } from '../../hooks/useConfirmPaymentIntent';

import checkoutFormSchema from './CheckoutForm.schema';
import { CheckoutFormValues } from './CheckoutForm.types';
import messages from './messages';

interface UseCheckoutFormArgs {
  onSubmitSuccess: () => void;
  invoiceId: string;
}

const useCheckoutForm = ({
  onSubmitSuccess,
  invoiceId,
}: UseCheckoutFormArgs) => {
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { pushErrors, pushNotification } = useToasts({
    extendedMessages: messages,
  });
  const stripe = useStripe();
  const elements = useElements();

  const formContext = useForm<CheckoutFormValues>({
    resolver: yupResolver(checkoutFormSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit } = formContext;

  const { handleConfirmPaymentIntent } = useConfirmPaymentIntent({
    invoiceId,
    onSuccess: () => {
      onSubmitSuccess();
    },
  });

  const onSubmit = handleSubmit(async ({ name }) => {
    setPaymentLoading(true);

    if (!stripe || !elements) {
      return undefined;
    }

    const responseStripe = await stripe.confirmPayment({
      elements,
      redirect: 'if_required' as any,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name,
          },
        },
      },
    });

    if (responseStripe.error) {
      pushNotification(
        buildNotification({
          notificationType: NOTIFICATION_TYPES.ERROR,
          message: responseStripe.error.message || '',
        }),
      );

      setPaymentLoading(false);

      return undefined;
    }

    try {
      await handleConfirmPaymentIntent();
    } catch (error: any) {
      pushErrors(error);

      return undefined;
    }

    return undefined;
  });

  return {
    formContext,
    onSubmit,
    paymentLoading,
  };
};

export default useCheckoutForm;
