import { useQuery } from 'react-query';

import { ClaimService } from 'myaccount/api';

export const GetCarriersByClaim = 'getCarriersByClaim';

export const useGetClaimGroupCarrierQuery = ({
  claimGroupId,
  handlingMode,
}: {
  claimGroupId?: string;
  handlingMode: string;
}) => {
  const { data: carriers, isLoading } = useQuery(
    [GetCarriersByClaim, { claimGroupId, handlingMode }],
    async () => {
      if (!claimGroupId || !handlingMode) {
        return undefined;
      }

      const response = await ClaimService.getClaimGroupCarrierQuery({
        claimGroupId,
        handlingMode,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      staleTime: 300,
    },
  );

  return {
    carriers,
    isLoading,
  };
};
