import React from 'react';
import { Outlet, useLocation, useNavigationType } from 'react-router-dom';

import { logDebug } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

const DebugLayout = () => {
  const location = useLocation();
  const navigationType = useNavigationType(); // "POP" | "PUSH" | "REPLACE"

  React.useEffect(() => {
    logDebug('The current URL is', { ...location });
    logDebug('The last navigation action was', navigationType);
  }, [location, navigationType]);

  return <Outlet />;
};

export default DebugLayout;
