import { useMemo } from 'react';

import { FileShortSummary } from '@savgroup-front-common/types';
import {
  HOME_TABS,
  PROFESSIONAL_FINAL_ROUTES,
} from 'myaccount/view/app/routes/ProfessionalRoutes';

import ClaimsToFinalizePage from '../ClaimsToFinalizePage/ClaimsToFinalizePage';
import HomePageFiles from '../HomePageFiles/HomePageFiles';
import { TABS_HOME } from '../HomePageProfessional.constants';
import messages from '../messages';

interface UseHomeTabsArgs {
  openedFiles: FileShortSummary[];
  closedFiles: FileShortSummary[];
}

const useHomeTabs = ({ openedFiles, closedFiles }: UseHomeTabsArgs) => {
  const hasFiles = Boolean(openedFiles.length || closedFiles.length);

  const tabsConfig = useMemo(() => {
    if (hasFiles) {
      return [
        {
          name: TABS_HOME.REPAIRS,
          path: HOME_TABS.REPAIRS,
          Component: HomePageFiles,
          message: messages.myRepairsLabel,
          url: PROFESSIONAL_FINAL_ROUTES.HOME_REPAIRS,
          icon: 'FolderIcon',
        },
      ];
    }

    return [
      {
        name: 'home',
        path: HOME_TABS.PRODUCTS,
        Component: ClaimsToFinalizePage,
        message: messages.myProductsLabel,
        url: PROFESSIONAL_FINAL_ROUTES.HOME_PRODUCTS,
        icon: 'SmallShopIcon',
      },
    ];
  }, [hasFiles]);

  return {
    tabsConfig,
  };
};

export default useHomeTabs;
