import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $ColContainer = styled(Col)<{
  enableServicePortal?: boolean;
  $withoutMarginBottom?: boolean;
}>`
  margin-top: unset;

  @media ${media.minWidth.sm} {
    margin-top: ${({ enableServicePortal = false }) =>
      enableServicePortal ? rem(30) : rem(80)};
  }
  position: relative;

  margin-bottom: ${({ enableServicePortal = false, $withoutMarginBottom }) => {
    if (enableServicePortal) {
      return 'unset';
    }

    if ($withoutMarginBottom) {
      return '0';
    }

    return rem(100);
  }};

  box-sizing: border-box;
`;

export const $StepContainer = styled('div')<{ $minHeight?: boolean }>`
  min-height: ${({ $minHeight }) => ($minHeight ? '40vh' : 'unset')};
`;
