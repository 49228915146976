import { fromJS } from 'immutable';
import get from 'lodash/get';

export function onPickupPointsLoading(state, { meta }) {
  const carrierCompany = get(meta, ['carrierCompany']);
  const productType = get(meta, ['productType']);

  if (!carrierCompany || !productType) {
    return state;
  }

  return state.setIn(
    ['pickupPoints', carrierCompany, productType],
    fromJS({ isLoaded: false, isDirty: false, value: undefined }),
  );
}

export function onPickupPointsLoadingErrored(state, { meta }) {
  const carrierCompany = get(meta, ['carrierCompany']);
  const productType = get(meta, ['productType']);

  if (!carrierCompany || !productType) {
    return state;
  }

  return state.updateIn(
    ['pickupPoints', carrierCompany, productType],
    (oldValue) => oldValue.setIn(['isDirty'], true).setIn(['hasErrors'], true),
  );
}

export function onPickupPointsLoaded(state, { payload, meta }) {
  const carrierCompany = get(meta, ['carrierCompany']);
  const productType = get(meta, ['productType']);
  const pickupPoints = get(payload, ['value']);

  if (!carrierCompany || !productType) {
    return state;
  }

  return state.setIn(
    ['pickupPoints', carrierCompany, productType],
    fromJS({ isLoaded: true, isDirty: false }).setIn(['value'], pickupPoints),
  );
}
