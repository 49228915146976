import { generatePath } from 'react-router-dom';

import { PROFESSIONAL_FINAL_ROUTES } from '../routes/ProfessionalRoutes';

import {
  PROFESSIONAL_HOME_CONFIG,
  PROFESSIONAL_STEP_CONFIG,
} from './ProfessionalRoutes.config';

type BuildProfessionalRouteArgs =
  | {
      route: PROFESSIONAL_HOME_CONFIG.PROFESSIONAL_HOME_PAGE;
      payload?: undefined;
    }
  | {
      route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE;
      payload?: { orderReference?: string };
    }
  | {
      route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE;
      payload: { claimGroupId: string };
    }
  | {
      route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE;
      payload: { claimGroupId: string };
    }
  | {
      route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE;
      payload: { claimGroupId: string };
    }
  | {
      route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE;
      payload: { claimGroupId: string };
    }
  | {
      route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE;
      payload: { orderReference: string };
    }
  | {
      route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_FILE_FOLLOWUPS_PAGE;
      payload: { fileId: string };
    };

export const buildProfessionalRoute = ({
  route,
  payload,
}: BuildProfessionalRouteArgs) => {
  switch (route) {
    case PROFESSIONAL_HOME_CONFIG.PROFESSIONAL_HOME_PAGE:
      return generatePath(PROFESSIONAL_FINAL_ROUTES.HOME);
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE:
      return generatePath(PROFESSIONAL_FINAL_ROUTES.ISSUES_REASONS, {
        orderReference: payload?.orderReference,
      });
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE:
      return generatePath(PROFESSIONAL_FINAL_ROUTES.PRODUCTS_TO_RETURN, {
        orderReference: payload?.orderReference,
      });

    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE:
      return generatePath(PROFESSIONAL_FINAL_ROUTES.SOLUTIONS, {
        claimGroupId: payload?.claimGroupId,
      });
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DEPOSIT_PAGE:
      return generatePath(PROFESSIONAL_FINAL_ROUTES.DEPOSIT, {
        claimGroupId: payload?.claimGroupId,
      });
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_DELIVERY_PAGE:
      return generatePath(PROFESSIONAL_FINAL_ROUTES.DELIVERY, {
        claimGroupId: payload?.claimGroupId,
      });

    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE:
      return generatePath(PROFESSIONAL_FINAL_ROUTES.CONFIRMATION, {
        claimGroupId: payload?.claimGroupId,
      });
    case PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_FILE_FOLLOWUPS_PAGE:
      return generatePath(PROFESSIONAL_FINAL_ROUTES.FILE_FOLLOWUPS, {
        fileId: payload?.fileId,
      });
    default:
      return '';
  }
};
