import React, {
  FunctionComponent,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  useState,
} from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../formatters';

import { $AccordionContainer } from './Accordion.styles';
import { ACCORDION_ROLE } from './Accordion.types';
import AccordionContent from './Content';
import AccordionHeader from './Header';

export interface AccordionProps {
  isOpen?: boolean;
  isDisabled?: boolean;
  isErrored?: boolean;
  naked?: boolean;
  initialIsOpen?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  headerRightChildren?: ReactNode;
  title?: Record<string, unknown> | MessageType | ReactNode;
  unmountOnExit?: boolean;
  dataTestId?: string;
  role?: ACCORDION_ROLE;
  componentThemeName?: string;
  isChevronOnRightSide?: boolean;
  isListTheme?: boolean;
  withoutMargin?: boolean;
  isColored?: boolean;
  shouldNotRenderIfEmpty?: boolean;
}

const Accordion: FunctionComponent<PropsWithChildren<AccordionProps>> = ({
  title = '',
  isOpen = undefined,
  initialIsOpen = false,
  isDisabled = false,
  isErrored = false,
  onClick = undefined,
  headerRightChildren = undefined,
  children = undefined,
  naked = false,
  unmountOnExit = true,
  dataTestId = undefined,
  role = ACCORDION_ROLE.PRIMARY,
  componentThemeName,
  isChevronOnRightSide,
  isListTheme = false,
  withoutMargin = false,
  isColored = false,
  shouldNotRenderIfEmpty = false,
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(initialIsOpen);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }

    if (isOpen === undefined) {
      setInternalIsOpen(!internalIsOpen);
    }
  };

  if (shouldNotRenderIfEmpty && !children) {
    return <></>;
  }

  return (
    <$AccordionContainer
      $naked={naked}
      data-testid={dataTestId}
      $isList={isListTheme}
      $withoutMargin={withoutMargin}
    >
      <AccordionHeader
        isOpen={isOpen !== undefined ? isOpen : internalIsOpen}
        isDisabled={isDisabled}
        isErrored={isErrored}
        onClick={handleClick}
        headerRightChildren={headerRightChildren}
        role={role}
        isChevronOnRightSide={isChevronOnRightSide}
        componentThemeName={componentThemeName}
        isListTheme={isListTheme}
        isColored={isColored}
      >
        {SafeFormattedMessage(title)}
      </AccordionHeader>

      <AccordionContent
        naked={naked}
        unmountOnExit={unmountOnExit}
        isOpen={isOpen !== undefined ? isOpen : internalIsOpen}
        componentThemeName={componentThemeName}
        isListTheme={isListTheme}
      >
        {children}
      </AccordionContent>
    </$AccordionContainer>
  );
};

export default Accordion;
