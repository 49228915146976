import styled from 'styled-components';

export const $HeadingIcon = styled.span`
  margin-right: 1rem;
  display: flex;
  align-items: baseline;
  & svg {
    width: 24px;
    height: 24px;
  }
`;
