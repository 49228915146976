import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { OwnerService } from 'myaccount/api';
import { ownerIdSelector } from 'myaccount/domains/Customer/selectors';
import useGetClientInformation from 'myaccount/view/app/hooks/useGetClientInformation';

import { IrshStepValues } from '../IrshPages.types';

import { IssuesReasonsPageSubmitValues } from './IssuesReasonsPage.types';

export const useIssuesReasonsPage = ({
  onNextStep,
}: {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}) => {
  const { orderReference } = useParams<{ orderReference?: string }>();

  const { removeAllNotifications, pushErrors } = useToasts();
  const { clientInformation } = useGetClientInformation();
  const ownerId = useSelector(ownerIdSelector);

  const { data, isLoading } = useQuery(
    [
      'startOrContinueBusinessReturnCommand',
      { sellerId: clientInformation?.sellerId, orderReference, ownerId },
    ],
    async () => {
      removeAllNotifications();

      if (!clientInformation?.sellerId) {
        return undefined;
      }

      const response = await OwnerService.startOrContinueBusinessReturnCommand({
        ownerId,
        sellerId: clientInformation?.sellerId,
        orderReference,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    { enabled: !!orderReference },
  );

  const handleSubmit = useCallback(
    async (values: IssuesReasonsPageSubmitValues) => {
      onNextStep({
        newValues: {
          order: values.order,
          orderReference: values.order.orderReference,
          issue: values.selectedIssue,
          reason: values.selectedReason,
        },
      });
    },
    [onNextStep],
  );

  return {
    handleSubmit,
    order: data?.order,
    eligibility: data?.eligibility,
    isLoading,
  };
};
