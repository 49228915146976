import { UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { OwnerService } from 'myaccount/api';
import { useGetClientInformation } from 'myaccount/view/app/hooks';

import { useRemoveProductFromBusinessReturn } from '../CartManager.hooks';
import { CartManagerForm } from '../CartManager.types';

import {
  GET_BUSINESS_RETURN,
  useGetBusinessReturn,
} from './useGetBusinessReturn';

export const useSetProductToBusinessReturn = ({
  formContext,
}: {
  formContext: UseFormReturn<CartManagerForm>;
}) => {
  const queryClient = useQueryClient();
  const { clientInformation } = useGetClientInformation();

  const { pushErrors } = useToasts();

  const { order } = useGetBusinessReturn();

  const { handleRemoveProduct } = useRemoveProductFromBusinessReturn({
    formContext,
  });

  const { mutateAsync: handleSetProduct, isLoading: isSetProductLoading } =
    useMutation(
      async ({
        modelId,
        batchQuantity,
      }: {
        modelId: string;
        batchQuantity: number;
      }) => {
        if (!clientInformation?.sellerId || !order) {
          return undefined;
        }

        if (batchQuantity === 0) {
          await handleRemoveProduct({ modelId });
        } else {
          const response = await OwnerService.setProductToBusinessReturnCommand(
            {
              sellerId: clientInformation?.sellerId,
              modelId,
              batchQuantity,
              orderReference: order.orderReference,
            },
          );

          if (response.failure) {
            pushErrors(response.errors);

            return undefined;
          }
        }

        await queryClient.invalidateQueries([GET_BUSINESS_RETURN]);

        return undefined;
      },
    );

  return {
    handleSetProduct,
    isSetProductLoading,
  };
};
