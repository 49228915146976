import React, { FC, Suspense } from 'react';

import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { Invoice } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import { Payment } from '../../../../../../components/Payments/Payment';

import { FileFollowupsCashRegisterPayment } from './FileFollowupsCashRegisterPayment/FileFollowupsCashRegisterPayment';
import { usePaymentCard } from './hooks/usePaymentCard';
import messages from './messages';
import { $HeadingIcon } from './PaymentCard.styles';

interface PaymentCardProps {
  invoice?: Invoice;
  sellerId: string;
  claimGroupId: string;
  fileId: string;
  onSubmitSuccess?: () => void;
  isFormCompleted?: boolean;
}

export const PaymentCard: FC<PaymentCardProps> = ({
  invoice,
  sellerId,
  claimGroupId,
  fileId,
  onSubmitSuccess = () => undefined,
  isFormCompleted = true,
}) => {
  const { paymentUrl } = usePaymentCard({ sellerId });

  if (!invoice) {
    return <></>;
  }

  return (
    <Suspense fallback={<BaseLoader />}>
      <Heading level={3}>
        <$HeadingIcon>
          <MyAccountIcon icon={ICONS_TYPE.CART_ICON} />
        </$HeadingIcon>
        <SafeFormattedMessageWithoutSpread message={messages.paymentTitle} />
      </Heading>

      {paymentUrl && (
        <FileFollowupsCashRegisterPayment
          fileId={fileId}
          invoice={{
            priceWithTax: invoice.priceWithTax,
            currencyTrigram: invoice.currencyTrigram,
          }}
          paymentUrl={paymentUrl}
          claimGroupId={claimGroupId}
          isFormCompleted={isFormCompleted}
        />
      )}

      {!paymentUrl && (
        <Payment
          invoice={{
            id: invoice.id,
            priceWithTax: invoice.priceWithTax,
            currencyTrigram: invoice.currencyTrigram,
          }}
          onSubmitSuccess={onSubmitSuccess}
          isFormCompleted={isFormCompleted}
          claimGroupId={claimGroupId}
        />
      )}
    </Suspense>
  );
};
