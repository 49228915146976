import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';

import { IrshStepValues } from '../IrshPages.types';

import useClaimGroupDiagnosticTreePage from './NewClaimGroupDiagnosticTreePage.hooks';
import { NewClaimGroupDiagnosticTreePageContent } from './NewClaimGroupDiagnosticTreePageContent';

export const NewClaimGroupDiagnosticTreePage: FC<
  RoutedStepProps<IrshStepValues>
> = ({ onPreviousStep, onNextStep }) => {
  const { questionId: diagnosticTreeUniqueName } = useParams() as {
    questionId: string;
  };

  const { startOrContinueDiagnosticTreeQueryReturnValues } =
    useClaimGroupDiagnosticTreePage({
      diagnosticTreeUniqueName,
    });

  if (!startOrContinueDiagnosticTreeQueryReturnValues) {
    return <BaseLoader />;
  }

  return (
    <NewClaimGroupDiagnosticTreePageContent
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
      startOrContinueDiagnosticTreeQueryReturnValues={
        startOrContinueDiagnosticTreeQueryReturnValues
      }
    />
  );
};
