export const ROUTES_PROFESSIONAL = {
  HOME: `home`,
  HOME_REPAIRS: 'home/repairs',
  HOME_PRODUCTS: 'home/products',

  ISSUES_REASONS: `issues/reasons/:orderReference?`,
  PRODUCTS_TO_RETURN: `productsToReturn/:orderReference`,
  SOLUTIONS: `solutions/:claimGroupId`,
  DEPOSIT: `deposit/:claimGroupId`,
  DELIVERY: `delivery/:claimGroupId`,
  CONFIRMATION: `confirmation/:claimGroupId`,
  FILE_FOLLOWUPS: `files/:fileId`,
};

export const HOME_TABS = {
  PRODUCTS: 'products',
  REPAIRS: 'repairs',
};

export const PROFESSIONAL_FINAL_ROUTES = {
  HOME: `/professional/home`,
  HOME_REPAIRS: '/professional/home/repairs',
  HOME_PRODUCTS: '/professional/home/products',

  ISSUES_REASONS: `/professional/issues/reasons/:orderReference?`,

  PRODUCTS_TO_RETURN: `/professional/productsToReturn/:orderReference`,
  SOLUTIONS: `/professional/solutions/:claimGroupId`,
  DEPOSIT: `/professional/deposit/:claimGroupId`,
  DELIVERY: `/professional/delivery/:claimGroupId`,
  CONFIRMATION: `/professional/confirmation/:claimGroupId`,

  FILE_FOLLOWUPS: `/professional/files/:fileId`,
};
