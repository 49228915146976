import React, { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import { Checkbox } from '../../../../../atoms/checkbox';
import { FormGroup } from '../../../../../atoms/Form';
import { useQuotationPendingCustomerValidationContext } from '../../QuotationPendingCustomerValidation.context';
import { QuotationPendingCustomerValidationForm } from '../../QuotationPendingCustomerValidation.types';

interface OptionalLineSelectionProps {
  sparePartQuotationLine: SparePartQuotationLine;
  onOptionalLineSelectionChange: ({
    values,
    alternativeSpareParts,
  }: {
    values: QuotationPendingCustomerValidationForm;
    alternativeSpareParts: Record<
      string,
      Record<string, SparePartSearchSummary>
    >;
  }) => Promise<{ failure: boolean }>;
  preDataTestId: string;
  index: number;
}

const OptionalLineSelection: FC<OptionalLineSelectionProps> = ({
  sparePartQuotationLine,
  onOptionalLineSelectionChange,
  preDataTestId,
  index,
}) => {
  const { alternativeSpareParts } =
    useQuotationPendingCustomerValidationContext();
  const formContext = useFormContext<QuotationPendingCustomerValidationForm>();
  const { getValues, setValue, control, watch } = formContext;

  useEffect(() => {
    setValue(
      `sparePartLines.${sparePartQuotationLine.id}.isSelectedForRepair`,
      sparePartQuotationLine.isSelectedForRepair || false,
    );
  }, [
    setValue,
    sparePartQuotationLine.isSelectedForRepair,
    sparePartQuotationLine.id,
  ]);

  const isSelected = watch(
    `sparePartLines.${sparePartQuotationLine.id}.isSelectedForRepair`,
  );

  return (
    <FormGroup isFluid={false} withoutPaddingBottom>
      <Controller
        name={`sparePartLines.${sparePartQuotationLine.id}.isSelectedForRepair`}
        control={control}
        render={({ field }) => {
          return (
            <Checkbox
              dataTestId={`${preDataTestId}_isSelectedForRepair_${index}`}
              name={field.name}
              onChange={async () => {
                const newValue = !isSelected;

                field.onChange(newValue);

                const values = getValues();

                const response = await onOptionalLineSelectionChange({
                  values,
                  alternativeSpareParts,
                });

                if (response.failure) {
                  field.onChange(isSelected);
                }
              }}
              checked={!!field.value}
              label={[
                sparePartQuotationLine.subCategory,
                sparePartQuotationLine.supplierReference,
              ]
                .filter((x) => !!x)
                .join(' - ')}
              withoutMargin
              isFlexAlign
              withoutPaddingLeft
            />
          );
        }}
      />
    </FormGroup>
  );
};

OptionalLineSelection.displayName = 'OptionalLineSelection';

export default OptionalLineSelection;
