import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { EmptyInboxIcon } from '@savgroup-front-common/core/src/protons/icons';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';
import { PROFESSIONAL_STEP_CONFIG } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';
import { buildProfessionalRoute } from 'myaccount/view/app/NewLayout/ProfessionalRoutes.helpers';

import { $HomeProductsListWrapper } from '../HomePageProfessional.styles';

import useListOwnerProductsQuery from './ClaimsToFinalizePage.hooks';
import messages from './messages';

const ClaimsToFinalizePage: FC = () => {
  const { isLoading } = useListOwnerProductsQuery();
  const theme = useTheme();

  if (isLoading) {
    return <BaseLoader />;
  }

  return (
    <$HomeProductsListWrapper>
      <EmptyInboxIcon
        color={theme.colors.primary}
        secondColor={theme.colors.secondary}
      />
      <SafeFormattedMessageWithoutSpread message={messages.createFile} />
      <ButtonLink
        primary
        icon={<MyAccountIcon icon={ICONS_TYPE.CONSUMABLE_ICON} />}
        href={buildProfessionalRoute({
          route: PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE,
        })}
        small
      >
        <SafeFormattedMessageWithoutSpread
          message={messages.createFileButton}
        />
      </ButtonLink>
    </$HomeProductsListWrapper>
  );
};

ClaimsToFinalizePage.displayName = 'ClaimsToFinalizePage';

export default ClaimsToFinalizePage;
