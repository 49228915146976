import React, { FC } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Input } from '@savgroup-front-common/core/src/atoms/Form';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign';
import { ICONS_TYPE } from '@savgroup-front-common/types';

import { useGetHandlingByClaimGroup } from '../../../../../app/hooks/useGetHandlingByClaimGroup';
import { PageFooterContainer } from '../../../../../app/NewLayout/NewStepLayout';
import { NewStepLayoutProfessional } from '../../../../../app/NewLayout/NewStepLayout/NewStepLayoutProfessional';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from '../../../../../app/NewLayout/ProfessionalRoutes.config';
import { buildProfessionalRoute } from '../../../../../app/NewLayout/ProfessionalRoutes.helpers';
import { ResumeDelivery } from '../../../../../components/ResumeDelivery';
import { ResumeDeposit } from '../../../../../components/ResumeDeposit';
import { ResumeSolution } from '../../../../../components/ResumeSolution';
import { ResumeCard } from '../../../components/ResumeCard';
import { IrshStepValues } from '../../IrshPages.types';
import AgreementCheckbox from '../AgreementCheckbox';
import {
  $AgreementRow,
  $ConfirmationItemWrapper,
  $ConfirmationResultWrapper,
  $ConfirmationWrapper,
  $Heading,
  $HeadingIcon,
  $LeftColumn,
} from '../ClaimGroupConfirmationPage.styles';
import messages from '../messages';

import { useClaimGroupConfirmation } from './ClaimGroupConfirmation.hooks';

interface ClaimGroupConfirmationProps {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
  onPreviousStep: RoutedStepProps<IrshStepValues>['onPreviousStep'];
}

export const ClaimGroupConfirmation: FC<ClaimGroupConfirmationProps> = ({
  onNextStep,
  onPreviousStep,
}) => {
  const claimGroupId = useParams().claimGroupId as string;

  const [openResumeModal, setOpenResumeModal] = React.useState(false);
  const {
    claimGroupConfirmation,
    formContext,
    agreementUrl,
    isLoadingSubmit,
    onSubmit,
  } = useClaimGroupConfirmation({
    onNextStep,
  });

  const { handling } = useGetHandlingByClaimGroup({ claimGroupId });
  const hasDeposit = handling?.hasDeposit;
  const hasDelivery = handling?.hasDelivery;

  const {
    control,
    formState: { errors, isValid },
  } = formContext;

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  return (
    <>
      <Grid fluid>
        <FormProvider {...formContext}>
          <form onSubmit={onSubmit}>
            <NewStepLayoutProfessional
              stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_CONFIRMATION_PAGE}
              customSteps={PROFESSIONAL_STEP_PROGRESS}
            >
              <$ConfirmationWrapper>
                <$LeftColumn>
                  <ResumeSolution
                    claimGroupId={claimGroupId}
                    claimGroupConfirmation={claimGroupConfirmation}
                    customBuildUpdateSolutionUrl={buildProfessionalRoute({
                      route:
                        PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE,
                      payload: { claimGroupId },
                    })}
                  />
                  {hasDeposit && (
                    <ResumeDeposit
                      claimGroupConfirmation={claimGroupConfirmation}
                    />
                  )}
                  {hasDelivery && (
                    <ResumeDelivery
                      claimGroupConfirmation={claimGroupConfirmation}
                    />
                  )}

                  <$ConfirmationItemWrapper>
                    <$Heading>
                      <$HeadingIcon>
                        <MyAccountIcon icon={ICONS_TYPE.SEND_MAIL_ICON} />
                      </$HeadingIcon>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.notificationsTitle}
                      />
                    </$Heading>
                    <Row>
                      <Col sm={6} xs={12}>
                        <Controller
                          control={control}
                          name="email"
                          render={({ field }) => {
                            return (
                              <Input
                                name={field.name}
                                value={field.value}
                                label={messages.mail}
                                disableBrowserAutocomplete
                                errors={errors}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col sm={6} xs={12}>
                        <Controller
                          control={control}
                          name="phone"
                          render={({ field }) => {
                            return (
                              <Input
                                name={field.name}
                                value={field.value}
                                label={messages.phone}
                                disableBrowserAutocomplete
                                errors={errors}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <$AgreementRow>
                      <Col sm={12} xs={12}>
                        <Controller
                          control={control}
                          name="cgv"
                          render={({ field }) => {
                            return (
                              <AgreementCheckbox
                                agreementHref={agreementUrl}
                                isChecked={field.value}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </Col>
                    </$AgreementRow>
                  </$ConfirmationItemWrapper>
                </$LeftColumn>
                <$ConfirmationResultWrapper>
                  <ResumeCard />
                </$ConfirmationResultWrapper>
              </$ConfirmationWrapper>
            </NewStepLayoutProfessional>

            <PageFooterContainer>
              <Button
                type={BUTTON_TYPES.BUTTON}
                secondary
                naked
                onClick={() => {
                  onPreviousStep({});
                }}
                icon={<ArrowNakedLeftIcon />}
                position={SUPPORTED_ICON_POSITIONS.LEFT}
                dataTestId="previousButton"
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.previousButton}
                />
              </Button>

              <Button
                primary
                type={BUTTON_TYPES.BUTTON}
                icon={<ArrowNakedRightIcon />}
                position={SUPPORTED_ICON_POSITIONS.RIGHT}
                disabled={!isValid}
                isLoading={isLoadingSubmit}
                onClick={onSubmit}
                dataTestId="nextButton"
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.nextButton}
                />
              </Button>
            </PageFooterContainer>
          </form>
        </FormProvider>
      </Grid>

      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard />
      </Modal>
    </>
  );
};
