import styled from 'styled-components';

import { ButtonLink } from '../../../button';

export const $ComplexSwitchContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.disabled};
  padding: 2px;
  border-radius: ${({ theme }) => theme.borders.radius};
`;

interface $ComplexSwitchButtonProps {
  $isActive: boolean;
}

export const $ComplexSwitchButton = styled(
  ButtonLink,
)<$ComplexSwitchButtonProps>`
  font-size: ${({ theme }) => theme.fonts.size.small};
  padding: 8px 12px 8px 10px;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};
  border: none;
  box-shadow: ${({ $isActive }) =>
    $isActive ? '0px 0px 8px 0px rgba(0, 0, 0, 0.1)' : null};

  svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.colors.paragraphTextColor};
  }
  height: 2.55rem;
  min-height: 2.55rem;
  &&&:focus {
    box-shadow: none;
    border: none;
    color: ${({ theme }) => theme.colors.mainTextColor};
    background-color: ${({ theme }) => theme.colors.white};

    svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &&&:hover {
    box-shadow: ${({ $isActive }) =>
      $isActive ? '0px 0px 8px 0px rgba(0, 0, 0, 0.1)' : null};
    border: none;
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.mainTextColor : theme.colors.mainTextColor};
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.white : theme.colors.default};

    svg {
      color: ${({ theme, $isActive }) =>
        $isActive ? theme.colors.primary : theme.colors.mainTextColor};
    }
  }
`;
