import { FC, PropsWithChildren } from 'react';

export enum STEPS_ORCHESTRATOR_ACTION_TYPES {
  NEXT_STEP = 'NEXT_STEP',
  PREVIOUS_STEP = 'PREVIOUS_STEP',
  RESET = 'RESET',
  UPDATE_CONTEXT = 'UPDATE_CONTEXT',
  UPDATE_VALUES = 'UPDATE_VALUES',
}

export interface RoutedStep<
  Values = Record<string, unknown>,
  Context = Record<string, unknown>,
> {
  name: string;
  route: string;
  onPreviousStep?: ({
    values,
    context,
  }: {
    values?: Partial<Values>;
    context?: Partial<Context>;
  }) => string;
  onNextStep?: ({
    values,
    context,
  }: {
    values?: Partial<Values>;
    context?: Partial<Context>;
  }) => string;
  Component: FC<RoutedStepProps<Values, Context>>;
  Wrapper?: FC<PropsWithChildren>;
}

export type RoutedStepsOrchestratorConfig<
  Values = Record<string, unknown>,
  Context = Record<string, unknown>,
> = RoutedStep<Values, Context>[];

export type OnSubmit<Values, Context = unknown> = ({
  values,
  context,
}: {
  values: Values;
  context: Context;
}) => void;

export interface RoutedStepProps<
  Values = Record<string, unknown>,
  Context = Record<string, unknown>,
> {
  onNextStep: ({
    newValues,
    newContext,
  }: {
    newValues?: Partial<Values>;
    newContext?: Partial<Context>;
  }) => void;
  onPreviousStep: ({
    newValues,
    newContext,
  }: {
    newValues?: Partial<Values>;
    newContext?: Partial<Context>;
  }) => void;
}
