import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CallbackComponent } from 'redux-oidc';

import { setToLocalStorage } from '@savgroup-front-common/core/src/helpers';
import * as ActionTypes from 'myaccount/domains/Login/loginActions';

function formatOrigin(origin) {
  switch (origin?.toLowerCase()) {
    case 'sharebylink':
      return 'ShareByLink';
    case 'messengercentral':
      return 'MessengerCentral';
    case 'workflownotification':
      return 'WorkflowNotification';
    default:
      return 'Undefined';
  }
}

class Callback extends React.Component {
  constructor(props) {
    super(props);

    const search = new URLSearchParams(window.location.search);

    this.routeParam = search.get('redirectUri');
  }

  successCallback = (user) => {
    const urlBeforeRedirection = user.state.redirectUrl;
    const { actions } = this.props;
    const { signIn } = actions;

    if (!urlBeforeRedirection.startsWith('/')) {
      throw new Error('Invalid redirect URL');
    }

    signIn();

    window.location.href = urlBeforeRedirection;
  };

  errorCallback = (...params) => {
    const { userManager, resetPassword, userManagerConfig } = this.props;

    const search = new URLSearchParams(window.location.search);

    if (params && params.length > 0) {
      if (params[0].message.search('AADB2C90118') === 0) {
        resetPassword();

        window.location.href = `${
          userManagerConfig().metadata.authorization_reset_password_endpoint
        }&client_id=${userManagerConfig().client_id}&redirect_uri=${
          userManagerConfig().redirect_uri
        }&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`;
      } else {
        const origin = formatOrigin(search.get('origin'));
        const ticketId = search.get('ticketId');

        setToLocalStorage({ key: 'LoggedInFrom', value: origin });
        setToLocalStorage({ key: 'LoggedInFromTicketId', value: ticketId });

        userManager.current.signinRedirect({
          data: {
            redirectUrl: this.routeParam || '/',
          },
        });
      }
    }
  };

  render() {
    const { userManager } = this.props;

    return (
      <CallbackComponent
        userManager={userManager.current}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div>Redirecting...</div>
      </CallbackComponent>
    );
  }
}

Callback.propTypes = {
  dispatch: PropTypes.func.isRequired,
  actions: PropTypes.shape({ signIn: PropTypes.func.isRequired }).isRequired,

  userManager: PropTypes.shape({}).isRequired,
  resetPassword: PropTypes.func.isRequired,
  userManagerConfig: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(ActionTypes, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Callback);
