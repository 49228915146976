import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useGetModelById from 'myaccount/hooks/useGetModelById';
import { useGetCustomerFileFullSummaryQuery } from 'myaccount/view/app/hooks/useGetCustomerFileFullSummaryQuery';
import { useGetSolutionTypeQuery } from 'myaccount/view/app/hooks/useGetSolutionTypeQuery';
import { useGetFileStatesQuery } from 'myaccount/view/app/hooks/Workflow/useGetFileStatesQuery';
import { buildFileFollowupUrl } from 'myaccount/view/app/NewLayout/ClassiqueLayout.helpers';

import { OwnerProductSummaryDto } from '../../HomePage.types';

import {
  adaptCustomerFileFullSummary,
  adaptProductsFromFileHomePage,
} from './HomePageFileItem.adapters';

export const useGetProductsDetails = ({
  product,
}: {
  product: OwnerProductSummaryDto;
}) => {
  const navigate = useNavigate();

  const { productsInfo } = useGetCustomerFileFullSummaryQuery({
    fileId: product.fileId,
  });
  const productsInfoAdapted = adaptCustomerFileFullSummary(productsInfo);

  const { model, isLoading: isModelLoading } = useGetModelById({
    modelId: product?.modelId,
  });

  const { solution, isLoading: isLoadingSolution } = useGetSolutionTypeQuery({
    solutionTypeId: productsInfo?.solutionId,
  });

  const mergedData = adaptProductsFromFileHomePage({
    model,
    productsInfoAdapted,
    solution,
  });

  const { histories, isLoading: isLoadingHistories } = useGetFileStatesQuery({
    fileId: product.fileId,
  });

  const handleShowProduct = useCallback(
    ({ fileId }: { fileId?: string }) => {
      if (!fileId) {
        return;
      }

      navigate(buildFileFollowupUrl({ fileId }));
    },
    [navigate],
  );

  return {
    productsDetails: mergedData,
    histories,
    isLoading: isModelLoading || isLoadingSolution || isLoadingHistories,
    handleShowProduct,
  };
};
