import { a } from '@react-spring/web';
import { darken, rgba, setLightness } from 'polished';
import styled from 'styled-components';

import { Z_INDEX_MAX } from '@savgroup-front-common/constants';

import { Button } from '../button';

import { DIRECTION, DRAWER_SIZE } from './SwipeableDrawer';

export const $Backdrop = styled.div<{ $opacity: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme, $opacity }) =>
    rgba(darken(0.47, setLightness(0.5, theme.colors.primary)), $opacity)};
  z-index: 50;
`;

interface SheetProps {
  direction?: DIRECTION;
  size?: DRAWER_SIZE;
  $hasPadding: boolean;
  $hasScroll: boolean;
  $isMobileView: boolean;
  $windowHeight: number;
}

export const $Sheet = styled(a.div)<SheetProps>`
  z-index: ${Z_INDEX_MAX};
  position: fixed;

  top: ${({ direction }) => (direction === DIRECTION.TOP ? 0 : undefined)};
  bottom: ${({ direction }) => (direction === DIRECTION.TOP ? undefined : 0)};
  left: ${({ direction }) => (direction === DIRECTION.TOP ? 0 : undefined)};
  right: ${({ direction }) => (direction === DIRECTION.TOP ? undefined : 0)};

  height: ${({ direction, size, $windowHeight }) => {
    if (size === DRAWER_SIZE.FULL_SCREEN) {
      return `${$windowHeight}px`;
    }

    return direction === DIRECTION.TOP ? 'calc(100vh + 100px)' : '100vh';
  }};
  width: ${({ direction, size }) => {
    if (direction === DIRECTION.TOP) {
      return '100%';
    }

    if (
      size !== DRAWER_SIZE.TINY &&
      size !== DRAWER_SIZE.SMALL &&
      size !== DRAWER_SIZE.MEDIUM &&
      size !== DRAWER_SIZE.LARGE
    ) {
      return 'calc(100vw - 2rem)';
    }

    if (size === DRAWER_SIZE.LARGE) {
      return '75%';
    }

    if (size) {
      return `calc(${size}px + 25px)`;
    }

    return 'calc(100vh + 100px)';
  }};
  border-radius: ${({ direction }) =>
    direction === DIRECTION.TOP ? '12px 12px 0' : '0 0 0 0'};
  background-color: ${({ theme }) => theme.systemColors.defaultBackground};

  & header {
    touch-action: none;
  }

  padding: ${({ $hasPadding }) => {
    return $hasPadding ? '2rem' : '0';
  }};

  overflow: ${({ $hasScroll }) => ($hasScroll ? 'auto' : 'hidden')};
  transition: 0.2s width ease-in-out;
`;

export const $CloseButton = styled(Button)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

export const $SwipeableDrawerSeparator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.newUI.variationColors.light5};
  width: 110%;
  margin: 1rem -25px;
`;
