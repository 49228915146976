import React from 'react';
import { useParams } from 'react-router-dom';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import useGetClientInformation from 'myaccount/view/app/hooks/useGetClientInformation';

import { IrshStepValues } from '../IrshPages.types';

import { ClaimGroupConfirmation } from './ClaimGroupConfirmation/ClaimGroupConfirmation';
import useClaimGroupConfirmationPage from './ClaimGroupConfirmationPage.hooks';

const ClaimGroupConfirmationPage: React.FC<RoutedStepProps<IrshStepValues>> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const claimGroupId = useParams().claimGroupId;
  const { clientInformation } = useGetClientInformation();
  const sellerId = clientInformation?.sellerId;

  const { isLoading, paymentRequired } = useClaimGroupConfirmationPage();

  if (paymentRequired && sellerId) {
    throw new Error(`Payment is not supported yet`);
  }

  if (isLoading || !claimGroupId) {
    return <BaseLoader />;
  }

  return (
    <>
      <ClaimGroupConfirmation
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
      />
    </>
  );
};

export default ClaimGroupConfirmationPage;
