import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Link, LinkButton } from '@savgroup-front-common/core/src/atoms/link';
import { ProductImage } from '@savgroup-front-common/core/src/atoms/ProductImage';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import CheckIcon from '@savgroup-front-common/core/src/protons/icons/Check/Check.icon';
import {
  FileShortSummary,
  OrderDetailsProduct,
} from '@savgroup-front-common/types';

import { FINAL_ROUTES } from '../../../../../app';
import { ClaimGroupReasonPageStepValues } from '../../../NewClaimGroupReasonPage/NewClaimGroupReasonPage.types';
import { ChoiceProductValues } from '../../NewChooseProductPage.types';
import ChooseProductTag from '../ChooseProductTag/ChooseProductTag';

import useChooseProductCard from './ChooseProductCard.hooks';
import {
  $ChooseProductCardCheckboxContainer,
  $ChooseProductCardContainer,
  $ChooseProductCardImageContainer,
  $ChooseProductCardInfoContainer,
  $ColonProductInfo,
  $OpenFile,
  $RowProductInfo,
} from './ChooseProductCard.styles';
import messages from './messages';

interface ChooseProductCardProps {
  product: OrderDetailsProduct;
  openedFiles: FileShortSummary[];
  isDisabled?: boolean;
  isInProgress?: boolean;
  notEligible?: boolean;
  dataTestId?: string;
}

const ChooseProductCard: React.FC<ChooseProductCardProps> = ({
  product,
  openedFiles,
  isDisabled = false,
  isInProgress = false,
  notEligible = false,
  dataTestId,
}) => {
  const navigate = useNavigate();
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();
  const { modelDetail } = useChooseProductCard({ product });
  const formContext = useFormContext<ChoiceProductValues>();

  const { onValuesUpdate } =
    useRoutedStepsOrchestratorContext<ClaimGroupReasonPageStepValues>();

  const { control, watch } = formContext;

  const selectedOwnerProductIds = watch('selectedOwnerProductIds');

  const isChecked = selectedOwnerProductIds?.includes(product.ownerProductId);

  const isOpenedFile = openedFiles.some((file) => {
    return file.fileProducts.some(
      ({ ownerProductId }) => ownerProductId === product.ownerProductId,
    );
  });

  const fileOpenedId = openedFiles.find((file) => {
    return file.fileProducts.filter(
      ({ ownerProductId }) => ownerProductId === product.ownerProductId,
    );
  });

  return (
    <>
      {!isDisabled && (
        <Controller
          control={control}
          name="selectedOwnerProductIds"
          render={({ field }) => {
            return (
              <$ChooseProductCardContainer
                isDisabled={false}
                isChecked={isChecked}
                onClick={() => {
                  if (isChecked) {
                    return field.onChange(
                      selectedOwnerProductIds.filter(
                        (item) => item !== product.ownerProductId,
                      ),
                    );
                  } else {
                    field.onChange([
                      ...selectedOwnerProductIds,
                      product.ownerProductId,
                    ]);
                  }

                  return undefined;
                }}
                data-testid={dataTestId}
              >
                <$RowProductInfo>
                  <$ChooseProductCardCheckboxContainer isChecked={isChecked}>
                    {isChecked && (
                      <CheckIcon color={theme.colors.white} size="16px" />
                    )}
                  </$ChooseProductCardCheckboxContainer>
                  <$ChooseProductCardImageContainer isDisabled={isDisabled}>
                    <ProductImage pictureUrl={modelDetail?.pictureUrl} />
                  </$ChooseProductCardImageContainer>
                  <$ChooseProductCardInfoContainer isDisabled={isDisabled}>
                    <>
                      {isMobileView && (
                        <ChooseProductTag
                          product={product}
                          openedFiles={openedFiles}
                          isSmall={isMobileView}
                        />
                      )}
                      <span>{product.modelDisplayName}</span>
                    </>
                  </$ChooseProductCardInfoContainer>
                  {!isMobileView && (
                    <ChooseProductTag
                      product={product}
                      openedFiles={openedFiles}
                    />
                  )}
                </$RowProductInfo>
              </$ChooseProductCardContainer>
            );
          }}
        />
      )}
      {isDisabled && (
        <$ChooseProductCardContainer
          isDisabled
          isInProgress={isInProgress}
          data-testid={dataTestId}
        >
          <$ColonProductInfo>
            <$RowProductInfo>
              <$ChooseProductCardImageContainer isDisabled={isDisabled}>
                <ProductImage pictureUrl={modelDetail?.pictureUrl} />
              </$ChooseProductCardImageContainer>
              <$ChooseProductCardInfoContainer isDisabled>
                <>
                  {isMobileView && (
                    <ChooseProductTag
                      product={product}
                      openedFiles={openedFiles}
                      isInProgress={isInProgress}
                      notEligible={notEligible}
                      isSmall={isMobileView}
                    />
                  )}

                  <span>{product.modelDisplayName}</span>
                  {isMobileView && isOpenedFile && fileOpenedId?.fileId && (
                    <$OpenFile>
                      <Link
                        href={`/new_design/files/${fileOpenedId?.fileId}`}
                        icon={<ArrowNakedRightIcon color="#000" size="14px" />}
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={messages.fileOpenedLink}
                        />
                      </Link>
                    </$OpenFile>
                  )}
                  {isMobileView && product.claimGroupId && (
                    <$OpenFile>
                      <Link
                        href={`/new_design/claimGroup/${product.claimGroupId}/reason`}
                        icon={<ArrowNakedRightIcon color="#000" size="14px" />}
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={messages.followClaimGroupLink}
                        />
                      </Link>
                    </$OpenFile>
                  )}
                </>
              </$ChooseProductCardInfoContainer>
              {!isMobileView && (
                <div>
                  <ChooseProductTag
                    product={product}
                    openedFiles={openedFiles}
                    isInProgress={isInProgress}
                    notEligible={notEligible}
                  />

                  {isOpenedFile && fileOpenedId?.fileId && (
                    <$OpenFile>
                      <Link
                        href={`/new_design/files/${fileOpenedId?.fileId}`}
                        icon={<ArrowNakedRightIcon color="#000" size="14px" />}
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={messages.fileOpenedLink}
                        />
                      </Link>
                    </$OpenFile>
                  )}
                  {product.claimGroupId && (
                    <$OpenFile>
                      <LinkButton
                        onClick={() => {
                          onValuesUpdate({
                            newValues: {
                              claims: undefined,
                              claimGroupId: product.claimGroupId,
                              diagnosticTree: {
                                diagnosticTreeUniqueName: undefined,
                                isDiagnosticIsAlreadyMade: false,
                                hasRefusedDiagnosticTree: false,
                              },
                            },
                          });

                          navigate(
                            generatePath(FINAL_ROUTES.CLAIM_GROUP_REASON, {
                              claimGroupId: product.claimGroupId,
                            }),
                          );
                        }}
                      >
                        <SafeFormattedMessageWithoutSpread
                          message={messages.followClaimGroupLink}
                        />
                      </LinkButton>
                      <ArrowNakedRightIcon color="#000" size="14px" />
                    </$OpenFile>
                  )}
                </div>
              )}
            </$RowProductInfo>
          </$ColonProductInfo>
        </$ChooseProductCardContainer>
      )}
    </>
  );
};

export default ChooseProductCard;
