import React, { FC, useCallback } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { Skeleton } from '@savgroup-front-common/core/src/atoms/Skeleton';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatStripeAmount } from '@savgroup-front-common/core/src/formatters/intl';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { useConfirmPaymentIntent } from '../hooks/useConfirmPaymentIntent';
import useCreatePaymentIntent from '../hooks/useCreatePaymentIntent';

import messages from './messages';
import { $PaylineWidget, $Total } from './PaymentMonext.styles';
import './widget-min.css';

interface PaymentMonextProps {
  invoice: { id: string; priceWithTax: number; currencyTrigram: CURRENCIES };
  onSubmitSuccess: () => void;
}

export const PaymentMonext: FC<PaymentMonextProps> = ({
  invoice,
  onSubmitSuccess,
}) => {
  const { pushErrors } = useToasts();

  const { paymentIntentClientSecret } = useCreatePaymentIntent({
    invoiceId: invoice.id,
    onInvoiceAlreadyPaid: onSubmitSuccess,
  });
  const { handleConfirmPaymentIntent } = useConfirmPaymentIntent({
    invoiceId: invoice.id,
    onSuccess: () => {
      onSubmitSuccess();
    },
  });

  const handleFinalStateHasBeenReached = useCallback(
    async ({ state }: { state: string }) => {
      if (state === 'PAYMENT_SUCCESS') {
        try {
          await handleConfirmPaymentIntent();
        } catch (error: any) {
          pushErrors(error);

          return undefined;
        }
      }
    },
    [handleConfirmPaymentIntent, pushErrors],
  );

  if (!paymentIntentClientSecret) {
    return <Skeleton width="100%" height="20rem" />;
  }

  return (
    <>
      <$Total>
        <SafeFormattedMessageWithoutSpread message={messages.totalAmount} />:{' '}
        {formatStripeAmount(invoice.priceWithTax, invoice.currencyTrigram)}
      </$Total>

      <$PaylineWidget
        token={paymentIntentClientSecret}
        template="column"
        embeddedRedirectionAllowed={false}
        onFinalStateHasBeenReached={handleFinalStateHasBeenReached as any}
      />
    </>
  );
};
