import React from 'react';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';

import { IrshStepValues } from '../IrshPages.types';

import { ClaimGroupDeposit } from './ClaimGroupDeposit/ClaimGroupDeposit';
import { useGetGroupedCarriers } from './hooks/useGetGroupedCarriers';

const ClaimGroupDepositPage: React.FC<RoutedStepProps<IrshStepValues>> = ({
  onPreviousStep,
  onNextStep,
}) => {
  const { groupedCarriers } = useGetGroupedCarriers();

  if (!groupedCarriers) {
    return <BaseLoader />;
  }

  return (
    <ClaimGroupDeposit
      onNextStep={onNextStep}
      onPreviousStep={onPreviousStep}
    />
  );
};

export default ClaimGroupDepositPage;
