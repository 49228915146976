import { defineMessages } from 'react-intl';

export default defineMessages({
  close: {
    id: 'view.myaccount.error.close',
    defaultMessage: 'Close',
  },
  selfcareTitle: {
    id: 'view.selfcare.title',
    defaultMessage: 'Selfcare',
  },
  b2BPortalTitle: {
    id: 'view.b2bPortal.title',
    defaultMessage: 'B2B Portal',
  },
  youCannotCrateNewFile: {
    id: 'view.selfcare.alert.youCannotCrateNewFile',
    defaultMessage: 'You cannot create new file, please contact the seller.',
  },
  youCannotCrateNewFileTitle: {
    id: 'view.selfcare.alert.youCannotCrateNewFileTitle',
    defaultMessage: 'The platform is disabled',
  },
});
