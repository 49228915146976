import 'array-flat-polyfill';
import { UserManager } from 'oidc-client';
import 'polyfill-object.fromentries';
import 'promise-polyfill/src/polyfill';
import React, { FC, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Store } from 'redux';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ToastContainer } from '@savgroup-front-common/core/src/atoms/Toast';
import { ConfirmationContextProvider } from '@savgroup-front-common/core/src/components/Confirmation/ConfirmationContextProvider';
import { HotjarProvider } from '@savgroup-front-common/core/src/components/HotjarProvider';
import IntlManager from '@savgroup-front-common/core/src/components/IntlManager/IntlManager';
import { NewUiProvider } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import { GlobalStyle } from '@savgroup-front-common/core/src/helpers';
import { useAppendNoIndexMeta } from '@savgroup-front-common/core/src/hooks';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import {
  BannersProvider,
  ToastsConsumer,
  ToastsProvider,
} from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ExternalPortal } from '@savgroup-front-common/core/src/protons/ExternalPortal/ExternalPortal';
import { HOTJAR_CONFIG } from 'myaccount/configuration';
import { MyAccountRootState } from 'myaccount/domains/MyAccountRootState';

import AppRoutes from './AppRoutes';
import Callback from './Callback';
import CheckLogged from './CheckLogged';
import { useInitGoogleMap } from './hooks';
import OidcProvider from './login/OidcProvider';
import ThemeContainer from './ThemeContainer';

interface AppProps {
  userManager: { current: UserManager };
  store: Store<MyAccountRootState>;
  resetPassword: () => void;
  userManagerConfig: () => void;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: FC<AppProps> = ({
  userManager,
  store,
  resetPassword,
  userManagerConfig,
}) => {
  const { isLoading } = useInitGoogleMap();

  useAppendNoIndexMeta({ VITE_ENV: import.meta.env.VITE_ENV as string });

  if (isLoading) {
    return <></>;
  }

  try {
    return (
      <NewUiProvider initialIsNewUiEnabled={true}>
        <HotjarProvider config={HOTJAR_CONFIG}>
          <ToastsProvider>
            <GlobalStyle myAccount />
            <ReduxProvider store={store}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <BannersProvider>
                  <OidcProvider store={store} userManager={userManager}>
                    <ThemeContainer>
                      <IntlManager>
                        <ConfirmationContextProvider>
                          <Suspense fallback={<BaseLoader />}>
                            <ToastsConsumer />
                            <ExternalPortal>
                              <ToastContainer topOffset="6rem" />
                            </ExternalPortal>

                            <BrowserRouter
                              future={{ v7_startTransition: true }}
                            >
                              <Routes>
                                <Route
                                  path="/callback"
                                  element={
                                    <Callback
                                      userManager={userManager}
                                      resetPassword={resetPassword}
                                      userManagerConfig={userManagerConfig}
                                    />
                                  }
                                />

                                <Route
                                  path="*"
                                  element={
                                    <CheckLogged userManager={userManager}>
                                      <AppRoutes />
                                    </CheckLogged>
                                  }
                                />
                              </Routes>
                            </BrowserRouter>
                          </Suspense>
                        </ConfirmationContextProvider>
                      </IntlManager>
                    </ThemeContainer>
                  </OidcProvider>
                </BannersProvider>
              </QueryClientProvider>
            </ReduxProvider>
          </ToastsProvider>
        </HotjarProvider>
      </NewUiProvider>
    );
  } catch (err) {
    logCritical(err);
    throw err;
  }
};

export default App;
